import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {formatDate} from '@angular/common';
import {AccountService} from '../account/account.service';
import {Device} from '../models';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import {SideNavService} from '../sidenav/sidenav.service';


@Component({
  selector: 'app-device-view',
  templateUrl: './device-view.component.html',
  styleUrls: ['./device-view.component.css']
})
export class DeviceViewComponent implements OnInit {
  toDate: any;
  fromDate: any;
  fromString: string;
  toString: string;

  deviceId;

  device: Device;
  error;
  isDataFetched = false;

  xPointsCount = 8;
  graphRangeType;
  currentGraphRangeType = 0;


  graphs;
  properties;

  redrawGraphEvent = new BehaviorSubject(null);
  graphDataObservable = this.redrawGraphEvent.asObservable();


  constructor(private route: ActivatedRoute, private router: Router, private toaster: ToastrService,
              private spinner: NgxSpinnerService, private accountService: AccountService, private sideNavService: SideNavService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.deviceId = params['deviceId'];
    });

    const lastGraphRangeType = parseInt(localStorage.getItem('graphRangeType' + this.deviceId), 10);
    this.graphRangeType = lastGraphRangeType ? lastGraphRangeType : 0;

    const temp = ((window.innerWidth * 0.55) / 80);
    this.xPointsCount = temp - temp % 1;
    this.getKit();
    const today = new Date();
    this.toDate = today;
    this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  }

  getKit() {
    this.spinner.show();
    this.accountService.getKit(this.deviceId)
      .subscribe(response => {
        this.device = response.content;
        this.accountService.deviceName.next(this.device.name);
        this.loadGraph();
      }, error => {
        this.spinner.hide();
      });
  }

  loadGraph() {
    this.currentGraphRangeType = this.graphRangeType;
    localStorage.setItem('graphRangeType' + this.deviceId, '' + this.graphRangeType);
    const today = new Date();

    if (this.graphRangeType === 2) {
      this.toDate = today;
      this.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    } else if (this.graphRangeType === 1) {
      this.toDate = today;
      this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    } else if (this.graphRangeType === 0) {
      this.toDate = today;
      this.fromDate = today;
    }
    this.toString = formatDate(this.toDate, 'yyyy-MM-dd', 'lk');
    this.fromString = formatDate(this.fromDate, 'yyyy-MM-dd', 'lk');
    this.spinner.show();
    this.accountService.getDeviceHistoryAll(this.deviceId, this.fromString, this.toString)
      .subscribe(data => {
        if (data.statusCode === 'E1003') {
          this.error = 'No Data Available';
        }
        this.graphs = data.content || null;
        this.isDataFetched = true;
        this.redrawGraphEvent.next(this.graphs);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getKitProperties() {
    if (!this.properties) {
      const properties = Object.assign([], this.device.properties);
      const battery = this.device.battery;
      if (battery) {
        const batteryProperty = {
          number: properties.length, displayValue: battery.reading + '%', displayName: 'Battery',
          code: '-10', time: '', alert: battery.low > battery.reading, error: false, showThreshold: false
        };
        properties.push(batteryProperty);
      }
      this.properties = properties;
    }
    return this.properties;
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }
}



