import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Divider, Grid, IconButton, Slider, Tooltip, Typography} from "@material-ui/core"
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {sendParameter} from '../api-helper/apiHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: "20px",
  },
  topic: {
    fontSize: "13px",
    color: "rgba(0,0,0,0.5)",
    marginBottom: "5px"
  },
  rangeDiv: {
    width: "98%",
    height: "50px",
    backgroundColor: "#ECEDEF",
    marginRight: "20px",
  },
  editDiv: {
    width: "98%",
    backgroundColor: "#ECEDEF",
    marginRight: "20px",
    padding: '20px'
  },
  percentage: {
    fontSize: "13px",
    color: "black",
    marginLeft: "20px",
    marginTop: "15px"
  },
  editIcon: {
    margin: "5px"
  },
  disableInput: {
    width: "50%",
    maxWidth: "50px",
    marginLeft: "20px",
    marginTop: "10px",
    color: "black"
  }
}));

export function ParameterConfig(props) {

  const classes = useStyles()

  const [show, setShow] = useState(-1)
  const [subTestCases, setSubTestCases] = useState([])
  const [value, setValue] = useState([])

  const openEdit = (i) => {
    setShow(i)
  }

  const cancel = (index, low, up) => {
    setShow(-1)
    props.allData.map((subTest) => {
      let vals = []
      if (subTest.id === props.mainTestId) {
        setSubTestCases(subTest.subTestCases)
        subTest.subTestCases.map((obj) => {
          vals.push([parseInt(obj.minVal), parseInt(obj.maxVal)])
          return vals
        })
        let values = [...value]
        values[index] = [vals[index][0], vals[index][1]]
        setValue(values);
      }
    })
  }

  const handleChange = (event, newValue, index) => {
    let values = [...value]
    values[index] = newValue
    setValue(values);
  };

  const saveData = async (index, subId) => {
    const data = {
      "minVal": (value[index][0]).toString(),
      "maxVal": (value[index][1]).toString()
    }
    const result = await sendParameter(props.batch, props.mainTestId, subId, data)
    if (result === undefined) {
      alert("Something went wrong")
    }
    if (result !== undefined) {
      setShow(-1)
    }
  }

  useEffect(() => {
    props.allData.map((subTest) => {
      let vals = []
      if (subTest.id === props.mainTestId) {
        setSubTestCases(subTest.subTestCases)
        subTest.subTestCases.map((obj) => {
          vals.push([parseInt(obj.minVal), parseInt(obj.maxVal)])
          return vals
        })
        setValue(vals)
      }
    })

  }, [props.mainTestId, props.allData])

  return (
    <div className={classes.root} style={{textAlign: "left"}}>

      {
        subTestCases.map((data, index) => {
          return (
            <div key={index} style={{marginTop: "20px"}}>
              <Typography className={classes.topic}>{data.subTestTitle}</Typography>
              <div className={classes.rangeDiv}>
                <Grid container>
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.percentage}>{
                      data.valueType === "percentage" ? value[index][0] + "% - " + value[index][1] + "%"
                        :
                        value[index][0] + " - " + value[index][1]
                    }
                    </Typography>
                  </Grid>
                  <Grid item xs={7} md={7}></Grid>
                  <Grid item xs={2} md={2} style={{textAlign: "right"}} hidden={show === index ? true : false}>
                    <IconButton className={classes.editIcon} onClick={() => openEdit(index)}>
                      <EditIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>

              {
                show === index ?
                  <div>
                    <Divider style={{width: "98%"}}/>
                    <div className={classes.editDiv}>
                      <Grid container>
                        <Grid item xs={12} md={10}>
                          <NewSlider
                            value={value[index]}
                            min={data.valueType === "percentage" ? 0 : value[index][0] - 100}
                            max={data.valueType === "percentage" ? 100 : value[index][1] + 100}
                            valueLabelDisplay="on"
                            ValueLabelComponent={ValueLabelComponent}
                            onChange={(e, n) => handleChange(e, n, index)}
                            aria-labelledby="range-slider"
                            marks={[
                              {
                                value: data.valueType === "percentage" ? 0 : value[index][0] - 100,
                                label: data.valueType === "percentage" ? 0 : value[index][0] - 100
                              },
                              {
                                value: data.valueType === "percentage" ? 100 : value[index][1] + 100,
                                label: data.valueType === "percentage" ? 100 : value[index][1] + 100
                              }
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <input style={{textAlign: "center"}} className={classes.disableInput}
                                 value={value[index][1] - value[index][0]}
                                 disabled></input>&nbsp; {data.valueType === "percentage" ? "%" : ""}
                        </Grid>
                        <Grid item xs={12} md={9}></Grid>
                        <Grid item xs={6} md={1} style={{marginTop: "20px", textAlign: "right"}}>
                          <Button style={{width: "90%", backgroundColor: "white"}}
                                  onClick={() => cancel(index, value[index][0], value[index][1])}>Cancel</Button>
                        </Grid>
                        <Grid item xs={6} md={1} style={{marginTop: "20px", textAlign: "right"}}>
                          <Button style={{width: "90%", backgroundColor: "#7A869A", color: "white"}}
                                  onClick={() => saveData(index, data.id)}>Save</Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  : <></>
              }
            </div>
          )
        })
      }
    </div>
  )
}

const EditIcon = () => {
  return (
    <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7518 2.1547C18.026 1.96602 18.3516 1.81635 18.71 1.71424C19.0683 1.61213 19.4524
                1.55957 19.8402 1.55957C20.2281 1.55957 20.6122 1.61213 20.9705 1.71424C21.3288 1.81635
                21.6544 1.96602 21.9287 2.1547C22.2029 2.34339 22.4205 2.56738 22.5689 2.81391C22.7174 3.06043
                22.7937 3.32465 22.7937 3.59149C22.7937 3.85832 22.7174 4.12254 22.5689 4.36907C22.4205 4.61559
                22.2029 4.83959 21.9287 5.02827L7.83167 14.7265L2.08844 15.8041L3.65477 11.853L17.7518 2.1547Z"
            stroke="#7A7474" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

function ValueLabelComponent(props) {
  const {children, open, value} = props;

  return (
    <NewTooltip open={open} PopperProps={{
      disablePortal: true,
      popperOptions: {
        positionFixed: false,
      }
    }} enterTouchDelay={0} placement="bottom" title={value} arrow>
      {children}
    </NewTooltip>
  );
}

const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
  }
})(Tooltip);


const NewSlider = withStyles({
  thumb: {
    width: "18px !important",
    height: "18px !important",
    color: "white",
    marginTop: -6,
    marginLeft: -12,
    boxShadow: "1px 1px rgba(124, 142, 150, 0.3)",
  },
  track: {
    height: "8px !important",
    color: "#2A7CED"
  },
  markLabel: {
    marginTop: -35
  },
  rail: {
    height: "8px !important",
    opacity: 1,
    color: "white",
    borderRadius: "12px"
  }
})(Slider);

