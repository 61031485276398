import * as React from 'react';
import {useEffect, useState} from 'react';
import {FormControl, FormHelperText, Grid, InputBase, NativeSelect, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles'
import {primaryGray, searchBarBorderColor} from 'src/components/sub-components/colors/colors';
import Dropzone from 'react-dropzone';
import {UploadFile} from 'src/components/sub-components/UploadFile';
import {environment} from 'src/environments/environment';
import {TextBar} from 'src/components/sub-components/TextBar';
import {textBarColor} from "../../../sub-components/colors/colors";
import BrowseButton from 'src/components/sub-components/BrowseButton';

export function Step1(props) {

  const classes = useStyles()

  const [productTypes, setProductTypes] = useState([])
  const [allProductTypes, setAllProductTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [constCategories, setConstCategories] = useState([])

  const [isMouseOver, setIsMouseOver] = useState(false);


// Function to handle mouse enter event
  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };


  const readtProductType = (e) => {
    props.readProductType(e.target.value)
    let newCategory
    allProductTypes.map((p) => {
      if (p.productType === e.target.value) {
        newCategory = p.deviceCategory
      }
    })
    setCategories(newCategory)
  }

  useEffect(() => {
    fetch(`${environment.host}/core/product/configDetails`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        if (data.content !== undefined) {
          let proTypes = []
          let constCat = []
          data.content.productTypes[0].map((p) => {
            proTypes.push(p.productType)
            p.deviceCategory.map((c) => {
              constCat.push(c)
            })
          })
          setProductTypes(proTypes)
          setAllProductTypes(data.content.productTypes[0])
          setCategories(constCat)
          setConstCategories(constCat)
        }
      })
      .catch((err) => {
        setProductTypes([])
      })
  }, [])

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} md={6} style={{textAlign: "left", marginBottom: "20px"}}>

        <Typography className={classes.label}>Product Type</Typography>
        <FormControl className={classes.input}>
          <NativeSelect
            id="demo-customized-select-native"
            value={props.defaultProductType}
            className={classes.input}
            onChange={readtProductType}
            input={<BootstrapInput/>}
          >
            <option value="" disabled hidden>Select product type</option>
            {
              productTypes.map((productType, i) => (
                <option value={productType} key={i}>{productType}</option>
              ))
            }
          </NativeSelect>
          {props.productTypeError === true &&
            <FormHelperText className={classes.helperText}>Product Type Required *</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item xs={6} md={6} style={{textAlign: "left", marginBottom: "20px"}}>
        <Typography style={{marginLeft: "35px"}} className={classes.label}>Version</Typography>
        <FormControl style={{marginLeft: "35px"}}>
          <TextBar error={{error: false}} defaultValue={props.defaultVersion} className={classes.input}
                   onChange={(e) => props.readVersion(e.target.value)} style={classes.input}></TextBar>
          {props.versionError === true &&
            <FormHelperText className={classes.helperText}>Version Number Required *</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item xs={6} md={6} style={{textAlign: "left", marginBottom: "20px"}}>

        <Typography className={classes.label}>Device Category</Typography>
        <FormControl className={classes.input}>
          <NativeSelect
            id="demo-customized-select-native"
            value={props.defaultCategory}
            className={classes.input}
            onChange={(e) => props.readCategory(e.target.value)}
            input={<BootstrapInput/>}
          >
            <option value="" disabled hidden>Select device category</option>
            {
              categories.map((c, i) => (
                <option value={c} key={i}>{c}</option>
              ))
            }
          </NativeSelect>
          {props.categoryError === true &&
            <FormHelperText className={classes.helperText}>Device Category Required *</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} style={{textAlign: "left", paddingTop: "20px"}}>
        <Typography className={classes.label}>Upload Bin file</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Dropzone onDrop={acceptedFiles => props.readBin(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()} style={{
                border: "1px dashed rgba(117, 117, 117, 0.5)",
                backgroundColor: "#EDEDED",
                borderRadius: "8px",
                height: "150px",
                padding: "40px"
              }}>
                <input {...getInputProps()} />
                <UploadFile/>
                <Typography>
                  <span style={{
                    fontSize: "15px",
                    color: primaryGray
                  }}>{props.binFileName === "" ? "Drag & Drop file here to upload or" : props.binFileName}</span>


                  <BrowseButton
                    isFileSelected={props.binFileName !== ''}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />

                </Typography>

              </div>
            </section>
          )}
        </Dropzone>
        {props.binError === true && <FormHelperText className={classes.helperText}>Bin File Required *</FormHelperText>}
      </Grid>

    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px"
  },
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: textBarColor,
  },
  label: {
    fontSize: "15px",
    color: primaryGray,
    paddingBottom: "5px"
  },
  helperText: {
    color: "red"
  },
  textBarStyle: {
    width: "230px",
    backgroundColor: textBarColor,
    height: "44px",
  },
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: "white",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Poppins',

        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  }),
)(InputBase);
