import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {LoginResponse} from '../../models';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginResponse: LoginResponse;
  loginError: string;
  form: FormGroup;
  isSubmitted;
  public showPassword: boolean;
  isDialog = false;
  rememberMe = false;

  constructor(private authService: AuthService, protected router: Router, private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
  }

  signIn(auth) {
    this.isSubmitted = true;

    if (this.form.valid) {
      this.loginWithUserName(auth, this.rememberMe);
    }
  }

  async loginWithUserName(auth, rememberMe) {
    auth.grant_type = 'password';
    auth.client_id = environment.client_id;
    auth.client_secret = environment.client_secret;
    this.spinner.show();
    await this.authService.loginWithUserName(auth, rememberMe).subscribe(
      (response) => {
        this.loginResponse = response;
        console.log(response);
        AuthService.loadCredential(this.loginResponse);
        // this.projectService.corporateSource.next(null);
        if (response.role < 10) {
          this.router.navigate(['']);
        } else {
          this.loginError = 'You don\'t have permission';
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.loginError = error.error.error_description;
      });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  toggleRememberMe() {
    this.rememberMe = !this.rememberMe;
  }

}
