import * as React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Card, Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "10px",
    padding: "20px",
    borderRadius: "20px"
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: "14px",
      fontWeight: 400
    }
  },
}));


export default function Battery(props) {

  const plugins = [{
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      const chartArea = chart.chartArea;
      ctx.restore();

      ctx.arc(width / 2, (height + chartArea.top) / 2, height / 4, height / 2, 0 * Math.PI, 2 * Math.PI)
      ctx.fillStyle = 'rgba(229, 231, 235,1)';
      ctx.fill();

      var fontSize = (height / 160).toFixed(2);
      ctx.font = "bolder " + fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = Math.round(((data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[3]) / (data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[2] + data.datasets[0].data[3])) * 100) + "%",
        textX = width / 2,
        textY = height / 2.2;
      ctx.textAlign = "center"
      ctx.fillStyle = 'black';
      ctx.fillText(text, textX, textY);

      var fontSize = (height / 320).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text2 = "Battery Range",
        textX = width / 2,
        textY = height / 1.8;
      ctx.textAlign = "center"
      ctx.fillStyle = 'black';
      ctx.fillText(text2, textX, textY);
      ctx.save();
    }
  }]

  const [data, setData] = React.useState({
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: [
          'rgba(0, 0, 0, 1)',
          'rgba(209, 215, 218, 1)',
          'rgba(170, 170, 170, 1)',
          'rgba(0, 0, 0, 0.6)'
        ]
      },
    ],
  });

  const classes = useStyles()

  React.useEffect(() => {
    if (props.batteryData.length > 0) {
      setData(
        {
          datasets: [
            {
              data: props.batteryData,
              backgroundColor: [
                'rgba(0, 0, 0, 1)',
                'rgba(209, 215, 218, 1)',
                'rgba(170, 170, 170, 1)',
                'rgba(0, 0, 0, 0.6)'
              ]
            },
          ],
        }
      )
    }
  }, [props.batteryData])

  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant='h6'>BATTERY DETAILS</Typography>
          </Grid>
          <Grid item xs={12} md={12} xl={6} style={{textAlign: "center", marginTop: "20px"}}>
            <Doughnut data={data} plugins={plugins as any} options={{
              onClick: (event, elements) => {
                if (elements[0] !== undefined) {
                  const chart = elements[0]._chart;
                  const element = chart.getElementAtEvent(event)[0];

                  console.log(element._index);
                  let filter;
                  if (element._index == 0) {
                    filter = "OnDirectPower"
                  }
                  if (element._index == 1) {
                    filter = "OnBattery"
                  }
                  if (element._index == 2) {
                    filter = "BatteryDead"
                  }
                  if (element._index == 3) {
                    filter = "OnSolarPower"
                  }

                  location.href = 'devices/' + props.coperateId + '/' + filter + '/' + false
                }
              },
              maintainAspectRatio: true,
              responsive: true,
              plugins: {
                labels: {
                  render: () => {
                  }
                },
              },
              cutoutPercentage: 70,
              tooltips: {enabled: false}
            }}/>
          </Grid>
          <Grid item xs={12} md={12} style={{textAlign: "left", marginTop: "20px", marginLeft: "30px"}}>
            <Grid container>
              <Grid item xs={1}>
                <FiberManualRecordIcon color="rgba(0, 0, 0, 1)"/>
              </Grid>
              <Grid item xs={11}>
                <a href={'devices/' + props.coperateId + '/OnDirectPower/' + false}
                   style={{textDecoration: "none", color: "black"}}>
                  <Typography>On Direct Power - {data.datasets[0].data[0]}</Typography>
                </a>
              </Grid>


              <Grid item xs={1}>
                <FiberManualRecordIcon color="rgba(209, 215, 218, 1)"/>
              </Grid>
              <Grid item xs={11}>
                <a href={'devices/' + props.coperateId + '/OnBattery/' + false}
                   style={{textDecoration: "none", color: "black"}}>
                  <Typography>On Battery - {data.datasets[0].data[1]}</Typography>
                </a>
              </Grid>

              <Grid item xs={1}>
                <FiberManualRecordIcon color="rgba(170, 170, 170, 1)"/>
              </Grid>
              <Grid item xs={11}>
                <a href={'devices/' + props.coperateId + '/BatteryDead/' + false}
                   style={{textDecoration: "none", color: "black"}}>
                  <Typography>Battery Dead - {data.datasets[0].data[2]}</Typography>
                </a>
              </Grid>

              <Grid item xs={1}>
                <FiberManualRecordIcon color="rgba(0, 0, 0, 0.6)"/>
              </Grid>
              <Grid item xs={11}>
                <a href={'devices/' + props.coperateId + '/OnSolarPower/' + false}
                   style={{textDecoration: "none", color: "black"}}>
                  <Typography>On Solar Power - {data.datasets[0].data[3]}</Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

const FiberManualRecordIcon = (props) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="7.02" cy="7.7841" rx="7.02" ry="7.48411" fill={props.color}/>
    </svg>
  )
}
