import {AfterViewInit, Component, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as React from 'react';
import {SideNavService} from 'src/app/sidenav/sidenav.service';
import * as ReactDOM from 'react-dom';
import {RoutLayout} from './layout';

@Component({
  selector: 'app-my-component',
  template: `<div [id]="rootId" style="transition: 0.6s" [ngClass]="isSideNavVisible()?'side-menu-padding':''"></div>`,
  styleUrls: ['../my-react-component/react.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DecodersComponentWrapper implements OnChanges, OnDestroy, AfterViewInit {
  constructor(private sideNavService: SideNavService) {
  }

  public rootId = "rootId"

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  private render() {
    ReactDOM.render(React.createElement(RoutLayout), document.getElementById(this.rootId))
  }
}
