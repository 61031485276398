import {environment} from "src/environments/environment";

//get All batches
export const getAllBatches = async () => {
  return fetch(`${environment.testHost}/core/deviceTest/batchNumbers`, {
    method: "GET",
    headers: {"Content-Type": "application/json"},
  })
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};

//get Main Test cases
export const getAllMainTestCases = async (batchNo) => {
  return fetch(
    `${environment.testHost}/core/deviceTest/testcase/batch/${batchNo}`,
    {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    }
  )
    .then((response) => response.json())
    .then((data) => {
      let mainTestCases = [];
      data.content.map((cases) => {
        mainTestCases.push(cases.mainTestTitle);
        return mainTestCases;
      });
      return {mainTestCases: mainTestCases, data: data.content} as any;
    })
    .catch((err) => {
      return;
    });
};

//get device test summary
export const getDeviceTestSummery = async (batchNo) => {
  return fetch(
    `${environment.testHost}/core/deviceTest/devicesTestSummary/${batchNo}`,
    {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};

//send parameter config values
export const sendParameter = async (
  batchNo,
  mainTestCaseId,
  subTestCase,
  data
) => {
  return fetch(
    `${environment.testHost}/core/deviceTest/configure/${batchNo}/testcase/${mainTestCaseId}/subTestcase/${subTestCase}`,
    {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};

export const getAllDevices = async () => {
  return fetch(`${environment.testHost}/core/device`, {
    method: "GET",
    headers: {"Content-Type": "application/json"},
  })
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};

export const getOneSubCase = (batch, subId) => {
  return fetch(
    `${environment.testHost}/core/deviceTest/subTestcase/batch/${batch}/${subId}`,
    {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};

export const submitDeviceConfig = (
  batch,
  devices,
  mainTest,
  subTest,
  testCondition,
  startDate,
  endDate
) => {
  const data = {
    devices: devices,
    testConditions: testCondition,
    startDate: startDate,
    endDate: endDate,
    testConfigurations: [
      {
        mainTestTitle: mainTest,
        subTestCases: [
          {
            subTestTitle: subTest.subTestTitle,
            minVal: `${parseInt(subTest.minVal)}`,
            maxVal: `${parseInt(subTest.maxVal)}`,
          },
        ],
      },
    ],
  };
  return fetch(
    `${environment.testHost}/core/deviceTest/configure/deviceConfiguration/${batch}`,
    {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};

export const getTestCondition = () => {
  return fetch(`${environment.testHost}/core/deviceTest/testConditions`, {
    method: "GET",
    headers: {"Content-Type": "application/json"},
  })
    .then((response) => response.json())
    .then((data) => {
      return data.content;
    })
    .catch((err) => {
      return;
    });
};
