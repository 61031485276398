import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, Grid, Typography} from "@material-ui/core"
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Bar} from 'react-chartjs-2';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "20px",
    padding: "20px",
    borderRadius: "20px"
  },
}));

export function TestSummaryReport(props) {
  const classes = useStyles()

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'SUCCESS',
        data: [0, 0],
        backgroundColor: '#2A7CED',
      },
      {
        label: 'OFFLINE',
        data: [0, 0],
        backgroundColor: '#D1D7DA',
      },
    ],
  })

  useEffect(() => {
    const index = props.allData.findIndex(obj => obj.id === props.mainCase)

    if (props.allData[index] !== undefined) {
      const mainTest = props.allData[index].mainTestTitle
      const subTestData = props.summary.numericalResult[mainTest]
      const subTestCases = Object.keys(subTestData)

      let success = []
      let fail = []

      subTestCases.map((val) => {
        if (subTestData[val].SUCCESS) {
          success.push(subTestData[val].SUCCESS)
        } else {
          success.push(0)
        }
        return success
      })
      subTestCases.map((val) => {
        if (subTestData[val].OFFLINE) {
          fail.push(subTestData[val].OFFLINE)
        } else if (subTestData[val].FAILURE) {
          fail.push(subTestData[val].FAILURE)
        } else {
          fail.push(0)
        }
        return fail
      })

      setData(
        {
          labels: subTestCases,
          datasets: [
            {
              label: 'SUCCESS',
              data: success,
              backgroundColor: '#2A7CED',
            },
            {
              label: 'OFFLINE',
              data: fail,
              backgroundColor: '#D1D7DA',
            },
          ],
        }
      )
    }
  }, [props.mainCase, props.summary])

  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant='h6'>TEST SUMMARY REPORT</Typography>
          </Grid>
          <Grid item xs={12} md={12} xl={9} style={{marginTop: "20px", marginBottom: "20px"}}>
            <Bar data={data} options={{
              maintainAspectRatio: true,
              responsive: true,
              legend: {
                display: false
              },
              scales: {
                xAxes: [{
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Test categories '
                  },
                  gridLines: {
                    display: false,
                  },
                }],
                yAxes: [{
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Number of Device'
                  },
                  ticks: {
                    beginAtZero: true,
                    precision: 0
                  },
                }]
              },
              plugins: {
                labels: {
                  render: () => {
                  }
                },
              },
            }}/>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
