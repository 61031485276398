import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SideNavService} from '../sidenav/sidenav.service';
import {Device} from '../models';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../account/account.service';
import {isEmpty} from '../utils';

@Component({
  selector: 'app-wifi-config',
  templateUrl: './wifi-config.component.html',
  styleUrls: ['./wifi-config.component.css']
})
export class WifiConfigComponent implements OnInit {
  devices: Device [];
  selectedDevice: any;

  public formKitModel = new FormGroup({
    kitId: new FormControl('', Validators.required),
    ssid: new FormControl('', Validators.required),
    wifiPassword: new FormControl('', Validators.required),
    routing: new FormControl('dynamic'),
    formKitStaticModel: new FormGroup({
      ip: new FormControl(''),
      subnet: new FormControl(''),
      gateway: new FormControl(''),
      dns: new FormControl('')
    })
  });


  public isStatic = false;

  constructor(private accountService: AccountService, private spinner: NgxSpinnerService, private toaster: ToastrService,
              private sideNavService: SideNavService) {
  }


  ngOnInit() {
    this.getDevices();
  }

  onChange() {
    console.log(this.formKitModel.value.routing, 'check');

    if (this.formKitModel.value.routing === 'static') {
      this.isStatic = true;
      this.formKitModel.get('formKitStaticModel').enable();
    } else {
      this.isStatic = false;
      this.formKitModel.get('formKitStaticModel').disable();
    }
  }

  onKitChange() {
    if (!this.formKitModel.value.kitId || this.formKitModel.value.kitId === '') {
      return;
    }
    this.getConnectivity(this.formKitModel.value.kitId);
  }

  getDevices() {
    this.spinner.show();
    this.accountService.getDevices().subscribe(response => {
      this.devices = response.content;
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }


  getConnectivity(deviceId) {
    this.spinner.show();
    this.accountService.getConnectivity(deviceId).subscribe(response => {
      this.selectedDevice = response.content;

      if (!isEmpty(response.content)) {
        this.formKitModel.patchValue(response.content.WIFI);

        if (response.content.WIFI.status) {
          this.toaster.warning(response.content.WIFI.status, 'Warning!');
        }
      } else {
        this.formKitModel.reset({kitId: deviceId, routing: 'dynamic'});
      }
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  updateKit(kit) {
    if (!this.formKitModel.valid) {
      return;
    }

    if (kit.routing === 'static') {
      kit = {...kit, ...kit.formKitStaticModel};
    }
    delete kit.formKitStaticModel;
    console.log(kit);
    this.spinner.show();
    this.accountService.updateConnectivity(kit.kitId, {WIFI: kit}).subscribe(response => {
      this.spinner.hide();
      this.toaster.success('Kit Updated Successfully', 'Success');
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.toaster.error(error.error.message, 'Error!');
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    }, () => {
      this.spinner.hide();
    });
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

}
