import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {SearchBar} from 'src/components/sub-components/SearchBar';
import {primaryBlue, seconderyTextColor, white} from 'src/components/sub-components/colors/colors';
import axios from 'axios';
import {useDispatch} from 'react-redux'
import {DeleteIcon, EditIcon} from '../../DecodersComponents'
import {Link, useLocation, useParams} from 'react-router-dom'
import {environment} from 'src/environments/environment';
import {ConnectDevice} from '../ConnectDevice';
import {DeleteDecoder} from '../../delete-decoder/DeleteDecoder';
import {toast} from "react-toastify";

export function ViewDecoder(props) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const id = useParams();
  const [devicesData, setDevicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation()
  const [type, setType] = useState('')
  const [editDevice, setEditDevice] = useState(false)
  const [deviceId, setDeviceId] = useState('')
  const [deleteDevice, setDeleteDevice] = useState(false)
  const [devicesArray, setDevicesArray] = useState([])
  const [devices, setDevices] = useState([]);
  const [multiDevices, setMultiDevices] = useState([])
  const [multiDevicesId, setMultiDevicesId] = useState([])
  const [toastOpen, setToastOpen] = useState(false)
  const [reqSuccess, setReqSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [existCodec, setExistCodec] = useState([])
  const [decoderName, setDecoderName] = useState('');
  const [decoderNames, setDecoderNames] = useState([]);
  const [toaster, setToaster] = useState(false);
  const [decoders, setDeoders] = useState([])
  var particularDecoder

  useEffect(() => {
      getParticularDecoder()
      getDeviceData()
      getAllCodec()
    }
    , [])

  const getParticularDecoder = () => {
    let isMounted = true;
    setLoading(true)
    axios.get(
      environment.host + '/core/codec/' + id.codecId
    ).then((res) => {
      setDevicesData(res.data.content)
      setTimeout(() => {
        setToastOpen(false);
      }, 4000);
      setLoading(false)
    }).catch((err) => {
      // if (isMounted) {
      //     console.log(err.message)
      // }
    })
  }

  const getAllCodec = () => {
    // let isMounted = true;
    fetch(
      environment.host + `/core/codec`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then((response) => response.json())
      .then((data) => {
        setDeoders(data.content)
        setLoading(false);
        particularDecoder = data.content.filter(codec => codec.id === id.codecId)
        setDecoderName(particularDecoder[0].codecName);
        const names = data.content.map(decoder => decoder.codecName);
        setDecoderNames(names);
        setTimeout(() => {

          setToaster(false);

        }, 4000);


      })
      .catch((err) => err.message);
    return () => {
      // isMounted = false;
    };
  }

  const getDeviceData = () => {
    setLoading(true)
    axios.get(
      environment.host + '/core/device'
    ).then((res) => {
      setDevices(res.data.content)
      setDevicesArray(res.data.content.filter((dev) => {
        return dev.magmaCodecId === null || !dev.hasOwnProperty('magmaCodecId')
      }))
    }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went Wrong, Please try again later");
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const closePopup = () => {
    setEditDevice(false)
    setDeleteDevice(false)
  }

  const selectMultiDevice = (option) => {
    if (multiDevicesId.includes(option.id)) {
      setMultiDevices(multiDevices.filter((device) => device != option))
      setMultiDevicesId(multiDevicesId.filter((id) => id != option.id))
    } else {
      setMultiDevices([...multiDevices, option])
      setMultiDevicesId([...multiDevicesId, option.id])
    }
  }

  const removeExistDecoder = () => {
    setExistCodec(decoderNames.filter((decoder) => decoder !== decoderName))
  }

  const deSelectDevices = () => {
    setMultiDevices([])
    setMultiDevicesId([])
  }

  return (
    <div>
      <div className={`testing`}>
        {
          <Grid container className={classes.container}>
            <Grid item xs={12} md={12} style={{
              display: "flex",
              height: "40px",
              marginLeft: "10px",
              marginRight: "70px",
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Grid item md={12} xs={12} style={{display: "flex"}}>
                <DecoderIcon/>
                <Link to={'/codec'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                  <Typography style={{color: '#858DAA'}}>Codec</Typography>
                </Link>
                <Arrow/>
                <Typography>Connected Devices</Typography>
              </Grid>
              <Grid item md={12} xs={12}
                    style={{textAlign: "right", display: "flex", justifyContent: "flex-end", marginLeft: '30px'}}>
                <Button
                  variant='contained'
                  onClick={() => {
                    setType('device-connect'), setEditDevice(true)
                  }}
                  className={classes.connectDecoder}
                  style={{textTransform: "none"}}
                >Connect Devices</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{display: "flex", height: "40px", margin: "25px 70px 25px 10px"}}>
              <Typography display="inline" style={{fontSize: "18px"}}>Devices Decoded With
              </Typography>
              <Typography style={{
                fontSize: '18px',
                color: '#1E61E2',
                paddingLeft: '5px',
                fontWeight: 'medium'
              }}>{decoderName}</Typography>
            </Grid>
            <Grid style={{
              textAlign: 'left',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '0px',
              justifyContent: 'flex-end'
            }} item xs={12} md={12}>
              <Grid item xs={12} md={12}
                    style={{display: "flex", height: "40px", marginLeft: "10px", marginRight: "70px"}}>
                <Grid item style={{textAlign: "left"}}>
                  <SearchBar onChange={(e) => setSearch(e.target.value)}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              margin: '5px 70px 0 10px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '16px'
            }}>
              <Checkbox
                checked={multiDevices.length !== 0}
                onClick={() => deSelectDevices()}/>
              <label className={classes.deletButton}>Delete Devices</label>
              <div style={{display: "flex", justifyContent: "flex-end", margin: '0px', textAlign: 'right', flex: 1}}>
                <TablePagination style={{margin: '0px'}}
                                 rowsPerPageOptions={[]}
                                 component="div"
                                 count={devicesData !== undefined && devicesData.filter((dev) => dev.name.toLowerCase().includes(search.trim().toLowerCase()) || dev.id.includes(search.trim())).length}
                                 rowsPerPage={rowsPerPage}
                                 page={page}
                                 onPageChange={handleChangePage}
                                 onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

            </Grid>
            <Grid item xs={12} md={12} style={{marginTop: "20px", marginLeft: "10px", marginRight: '70px'}}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white,
                        width: '20px'
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          width: '20px',
                          paddingRight: '0px'
                        }}></Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: '0px'
                        }}>ID</Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>DEVICE NAME</Typography>
                      </TableCell>

                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white
                      }}>
                        <Typography style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>ACTION</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devicesData !== undefined && devicesData.filter((obj) => {
                      if (search === "") {
                        return obj
                      } else if (obj.name.toLowerCase().includes(search.trim().toLowerCase()) || obj.id.includes(search.trim())) {
                        return obj
                      }
                    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device, i) => (
                      <TableRow key={i} style={{height: '48px'}}>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          width: '20px',
                          paddingRight: '0px'
                        }}>
                          <Checkbox style={{color: '#0000008A'}}
                                    id={device.id}
                                    value={device.name}
                                    checked={multiDevices.includes(device)}
                                    onClick={() => selectMultiDevice(device)}/>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          paddingLeft: '0px'
                        }}>
                          <Typography display='inline' className={classes.tableText}>{device.id}</Typography>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <Typography display='inline' className={classes.tableText}>
                            {device.name}
                          </Typography>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <IconButton style={{padding: "0px"}} onClick={() => {
                            setType('edit-device'), setEditDevice(true), setDeviceId(device.id), removeExistDecoder()
                          }}>
                            <EditIcon/>
                          </IconButton>
                          <IconButton style={{padding: "0px", paddingLeft: '10px'}} onClick={() => {
                            setType('device-delete'), setDeleteDevice(true), setDeviceId(device.id)
                          }}>
                            <DeleteIcon/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        }
      </div>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          severity={reqSuccess === true ? "success" : "warning"}
        >
          {/* <h3 style={{ textAlign: "left" }}>
                        {reqSuccess === true ? "Success" : "Error"}
                    </h3> */}
          {
            errorMessage.length > 0
              ? errorMessage
              : "Something went wrong"
          }
        </Alert>
      </Snackbar>

      <ConnectDevice
        type={type}
        decodersName={existCodec}
        open={editDevice}
        onclose={() => closePopup()}
        decoders={decoders}
        decoderIdView={id.codecId}
        deviceId={deviceId}
        codecName={decoderName}
        setDeviceData={setDevicesData}
        deviceData={devicesData}
        getParticularDecoder={() => getParticularDecoder()}
        getDeviceData={() => getDeviceData()}
        setDevicesArray={setDevicesArray}
        devicesArray={devicesArray}
        setToast={setToastOpen}
        setReqSuccess={setReqSuccess}
        setErrorMessage={setErrorMessage}
      />
      <DeleteDecoder
        type={type}
        open={deleteDevice}
        onClose={() => closePopup()}
        decoderId={id.codecId}
        deviceId={deviceId}
        setDeviceData={setDevicesData}
        deviceData={devicesData}
        getParticularDecoder={() => getParticularDecoder()}
        getDeviceData={() => getDeviceData()}
        setDevicesArray={setDevicesArray}
        devicesArray={devicesArray}
        setToast={setToastOpen}
        setReqSuccess={setReqSuccess}
        setErrorMessage={setErrorMessage}
        setLoading={setLoading}
      />
    </div>
  )
}

export const DecoderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="23"
         viewBox="0 0 12.633 20">
      <g id="green_modern_vegetable_instagram_post_40_40px_"
         data-name="green modern vegetable instagram post (40 × 40px)" transform="translate(-6.203 -0.719)">
        <g id="Group_5057" data-name="Group 5057" transform="translate(6.203 3.718)" clipPath="url(#clip-path)">
          <path id="Path_13327" data-name="Path 13327"
                d="M10.137,18.663a.476.476,0,0,0,0-.952H7.146V6.671H9.973a.476.476,0,0,0,0-.952h-3.3a.474.474,0,0,0-.472.475V18.188a.474.474,0,0,0,.472.475Zm0,0"
                transform="translate(-6.203 -5.19)" fill="#858DAA" stroke="#2A7CED;" strokeWidth="1"/>
        </g>
        <g id="Group_5058" data-name="Group 5058" transform="translate(11.75 0.719)" clipPath="url(#clip-path-2)">
          <path id="Path_13329" data-name="Path 13329"
                d="M15.225,17.1h5.464a.471.471,0,0,0,.472-.467V4.815a.471.471,0,0,0-.472-.467H15.225V1.2a.472.472,0,0,0-.943,0V20.246a.472.472,0,0,0,.943,0Zm0,0"
                transform="translate(-14.074 -0.722)" fill="#858DAA" stroke="#2A7CED;" strokeWidth="1"/>
        </g>
      </g>
    </svg>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px"
  },
  filter: {
    backgroundColor: white,
    color: seconderyTextColor,
    boxShadow: "none",
  },
  addDecoder: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    height: "40px",
    width: "200px",
    '&:hover': {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none"
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#FC4141",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px"
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px"
  },
  connectDecoder: {
    backgroundColor: white,
    color: primaryBlue,
    boxShadow: "none",
    height: "40px",
    width: "200px",
    borderColor: primaryBlue,
    border: "2px solid",
    '&:hover': {
      backgroundColor: white,
      color: primaryBlue,
      boxShadow: "none"
    },
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  statusText: {
    width: "62px",
    borderRadius: "10px",
    fontSize: "14px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  status: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "45px"
  },
  deletButton: {
    width: "94px",
    height: "24px",
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    borderRadius: "19px",
    opacity: 1,
    color: '#3C3C3C',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#707070">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
    </svg>
  )
}
