import {Component, OnInit} from '@angular/core';
import {AccountService} from '../account/account.service';
import {SideNavService} from '../sidenav/sidenav.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ACCESS_TYPE, Device, FORM_TYPE, Kit, KitModel, SECTIONS} from '../models';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';
import {FormBuilder} from '@angular/forms';
import * as _ from 'underscore';

declare function toggleClass(id, classA);

@Component({
  selector: 'app-kitboard',
  templateUrl: './kitboard.component.html',
  styleUrls: ['./kitboard.component.css']
})
export class KitboardComponent implements OnInit {
  error: null;
  kits: Kit[];
  devices: Device[];
  devicesAll: Device[];
  kitModels: KitModel[];
  devicesOfKit: Device[];
  p = 1;
  selectedKit: Kit;
  formType = FORM_TYPE.EDIT;
  showForm = false;
  searchTerm = '';
  isDataFetched = false;
  isCloning = false;
  validity = false;
  ACCESS_TYPE = ACCESS_TYPE;
  SECTION = SECTIONS;


  constructor(private accountService: AccountService, private sideNavService: SideNavService,
              private spinner: NgxSpinnerService, private toaster: ToastrService,
              private formBuilder: FormBuilder, private authService: AuthService) {
  }

  ngOnInit() {
    this.getKits();
    this.getKitModels();
    this.getDevices();
  }

  public pageChange(event: number): void {
    this.p = event;
  }

  getKits() {
    this.spinner.show();
    this.accountService.getKits().subscribe(response => {
      this.kits = response.content;
      this.isDataFetched = true;
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  addKit() {
    this.formType = FORM_TYPE.ADD;
    this.showForm = true;
  }

  editKit(kit: Kit) {
    this.devices = Object.assign([], this.devicesAll);
    this.devicesOfKit = kit.devices.map(device => {
      return _.findWhere(this.devices, {id: device});
    });
    kit['kitModel'] = kit.model.id;
    kit['soilType'] = kit.metaData ? kit.metaData.soilType : '';
    this.selectedKit = kit;
    this.showForm = true;
  }

  hideForm() {
    this.isCloning = false;
    this.showForm = false;
  }


  getDevices() {
    this.spinner.show();
    this.accountService.getDevices().subscribe(response => {
      this.devices = response.content;
      this.devicesAll = Object.assign([], this.devices);
      this.devices.sort((a, b) => {
        if (!a.name || !b.name) {
          return 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  getKitModels() {
    this.spinner.show();
    this.accountService.getKitModels().subscribe(response => {
      this.kitModels = response.content;
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  deleteKit() {
    this.spinner.show();
    this.accountService.deleteKit(this.selectedKit.id).subscribe(response => {
      this.toggleHideById('deleteModal');
      this.kits.splice(this.kits.indexOf(this.selectedKit), 1);
      this.spinner.hide();
      this.toaster.success('Kit Deleted Successfully', 'Success');
    }, error => {
      this.toggleHideById('deleteModal');
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  addDeviceToKit(index: any) {
    this.devicesOfKit.push(this.devices.splice(index, 1)[0]);
  }

  removeDeviceFromKit(index: number) {
    this.devices.push(this.devicesOfKit.splice(index, 1)[0]);
    this.devices.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  getDevicesNames(kit) {
    // console.log("dev",(kit.devices as Array<string>).join(', '));
    return (kit.devices);
  }

  getKitsFiltered() {
    if (!this.kits) {
      return [];
    }
    return this.kits.filter(k => JSON.stringify(Object.values(k)).toLowerCase().includes(this.searchTerm.trim().toLowerCase()));
  }

  toggleHideById(id) {
    document.getElementById(id).classList.toggle('hide');
  }

  kitValidity(id) {
    const kit = this.kits.find((res) => id === res.id);

    if (!kit) {
      // Kit with the provided id not found.
      console.log('Kit not found');
      return;
    }

    const kitModel = this.kitModels.find((kitModel) => kit.model.id === kitModel.id);

    if (!kitModel) {
      // Kit model not found.
      console.log('Kit model not found');
      return;
    }

    // Extract the actuators and sensors from the kit model and devices.
    const kitModelActuators = kitModel.actuators;
    const kitModelSensors = kitModel.sensors;
    let currentDevices = []
    kit.devices.forEach((device) => this.devices.find((dev) => {
        if (device === dev.id) {
          currentDevices.push(dev)
        }
      }
    ))
    let kitMatches = false
    for (const device of currentDevices) {
      const deviceActuatorCodes = device.actuatorCodes;
      const deviceSensorCodes = device.sensorCodes;

      const actuatorsMatch = deviceActuatorCodes.every((actuator) => kitModelActuators.includes(actuator));
      const sensorsMatch = deviceSensorCodes.every((sensor) => kitModelSensors.includes(sensor));

      if (actuatorsMatch && sensorsMatch) {
        kitMatches = true;
        break; // At least one device in the kit matches the kit model.
      }
    }
    return kitMatches;
  }
}
