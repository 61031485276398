import {uploadFile} from 'react-s3';
import {environment} from 'src/environments/environment';


const binConfig = {
  dirName: "product-types/bin-file",
  bucketName: 'senzmate-polar',
  region: 'us-west-2',
  accessKeyId: 'AKIA5XGNU22IOH7IWSV4',
  secretAccessKey: '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',
  s3Url: 's3://senzmate-polar/product-types/bin-file/',
}
const flowChartConfig = {
  dirName: "product-types/flow-chart",
  bucketName: 'senzmate-polar',
  region: 'us-west-2',
  accessKeyId: 'AKIA5XGNU22IOH7IWSV4',
  secretAccessKey: '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',
  s3Url: 's3://senzmate-polar/product-types/flow-chart/',
}

export const submit = async (binFile, flowChart, productType, version, category, parameters, existingFlowChart, deviceIds) => {
  let newParameters = []
  parameters.map((parameter) => {
    newParameters.push(
      {
        "parameterCategory":
          parameter.mainType === "network" ? "Network & Communication" :
            parameter.mainType === "topic" ? "Topic Format & Interval" : "Message Format",
        "parameter": parameter.parameter,
        "id": parameter.id,
        "inputType": parameter.inputType,
        "format": parameter.format,
        "defaultValue": parameter.default,
        "joinParameter": parameter.join
      },
    )
  })
  const finalData = {
    "productType": productType,
    "deviceCategory": category,
    "version": {
      "versionNum": version,
      "remoteConfigurations": newParameters,
      "devices": deviceIds
    }
  }
  return fetch(`${environment.host}/core/product`,
    {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(finalData)
    })
    .then(response => response.json())
    .then(async data => {
      console.log(data)
      let binPath;
      let flowPath;
      if (data.message === "Success") {
        binPath = await uploadBin(binFile, binConfig)
        if (existingFlowChart === "") {
          flowPath = await uploadFlowChart(flowChart, flowChartConfig)
        }
        if (existingFlowChart !== "") {
          flowPath = existingFlowChart
        }
        console.log(binPath)
        console.log(flowPath)
        return updateProductWithFileLinks(
          data.content.id,
          version,
          newParameters,
          binPath,
          flowPath
        )
      } else {
        return {message: data.message}
      }
    })
    .catch((err) => {
      console.log(err.response.message)
      return {message: "failed"}
    })
}

export const uploadBin = async (binFile, binConfig) => {
  return uploadFile(binFile, binConfig)
    .then(data => {
      return data.location
    })
    .catch(err => {
      return err
    })
}

export const uploadFlowChart = (flowFile, flowChartConfig) => {
  return uploadFile(flowFile, flowChartConfig)
    .then(data => {
      return data.location
    })
    .catch(err => {
      return err
    })
}

export const updateProductWithFileLinks = (id, version, reoteConfig, bin, flow) => {
  const object = {
    "version": {
      "versionNum": version,
      "binURL": bin,
      "flowChartURL": flow,
      "remoteConfigurations": reoteConfig
    }
  }
  return fetch(`${environment.host}/core/product/${id}`,
    {
      method: "PUT",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(object)
    })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch((err) => {
      return
    })
}
