export function timeDurationToReadableForm(time) {
  // let startDate = new Date(startTime);
  // let endDate = new Date(endTime);
  let diffMs = (time * 60 * 1000); // milliseconds between now & Christmas
  let diffDays = Math.floor(diffMs / 86400000); // days
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  if (diffDays === 0) {
    if (diffHrs === 0) {
      return (diffMins + ' minutes');
    }
    return (diffHrs + ' hours, ' + diffMins + ' minutes');
  }
  return (diffDays + ' days, ' + diffHrs + ' hours, ' + diffMins + ' minutes');
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function newDateFromGiven(date) {
  const dashSplit = date.toString().split('-')
  const spaceSplit = dashSplit[2].toString().split(' ')
  const colonSplit = spaceSplit[1].toString().split(':')
  return new Date(dashSplit[0], dashSplit[1] - 1, spaceSplit[0], colonSplit[0], colonSplit[1], colonSplit[2])
}

export function timeSince(date) {
  if (!date) {
    return;
  }
  let sinceDate = newDateFromGiven(date).getTime()
  let seconds = Math.floor((new Date().getTime() - sinceDate) / 1000);
  let sinceTime = null
  let min, hours, days
  if (seconds < 60) {
    return sinceTime = "few seconds ago"
  } else if (seconds < 3600) {
    min = Math.floor(seconds / 60)
    return sinceTime = min + " minutes ago"
  } else if (seconds < 86400) {
    hours = Math.floor(seconds / 3600)
    return sinceTime = hours + " hours ago"
  } else if (seconds > 86400) {
    days = Math.floor(seconds / 86400)
    return sinceTime = days + " days ago"
  } else {
    return date
  }
}
