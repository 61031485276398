import * as React from "react";
import {TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {primaryGray, searchBarBorderColor,} from "./colors/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      height: "2px",
      color: primaryGray,
      fontSize: "14px",
      lineHeight: "21px",
      opacity: "1",
    },
  })
);

export function TextBar(props) {
  const classes = useStyles();

  return (
    <CssTextField
      disabled={props.disabled}
      fullWidth
      className={props.style}
      error={props.error.error}
      type={props.type}
      helperText={props.error.errorMsg}
      value={props.defaultValue === null ? "" : props.defaultValue}
      defaultValue={props.value === null ? "" : props.value}
      InputLabelProps={{shrink: false}}
      InputProps={{
        classes: {
          input: classes.inputField,
        },
      }}
      onChange={props.onChange}
      variant="outlined"
    ></CssTextField>
  );
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: searchBarBorderColor,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: searchBarBorderColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: searchBarBorderColor,
        border: "none",
        opacity: "1",
      },
      "&:hover fieldset": {
        borderColor: searchBarBorderColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: searchBarBorderColor,
      },
    },
  },
})(TextField);
