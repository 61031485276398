// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  client_id: 'senzagro-client',
  client_secret: 'senzagro-secret',

  host: 'https://app.dunyahabitats.com/service',
  testHost: 'http://polar.senzmatica.com/service',

  firebase: {
    apiKey: 'AIzaSyA9uKFEIqgW63abTdvYTqb3gS6r-60FRpI',
    authDomain: 'senzagro-c3d33.firebaseapp.com',
    databaseURL: 'https://senzagro-c3d33.firebaseio.com',
    projectId: 'senzagro-c3d33',
    storageBucket: 'senzagro-c3d33.appspot.com',
    messagingSenderId: '283658892245',
    appId: '1:283658892245:web:e206dc51d4a2719e75c35c',
    measurementId: 'G-E5NKHDMS7N'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
