import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputBase,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import {TestDashboard} from "./test-dashboard/testDashboard";
import {ParameterConfig} from "./parameter-configuration/parameterConfig";
import {DeviceConfig} from "./device-configuration/deviceConfig";
import {
  getAllBatches,
  getAllDevices,
  getAllMainTestCases,
  getDeviceTestSummery,
  getTestCondition,
} from "./api-helper/apiHelper";
import {TextBar} from "../sub-components/TextBar";

const useStyles = makeStyles((theme: Theme) => ({
  navButtons: {
    height: "55px",
    paddingTop: "20px",
    fontSize: "14px",
    cursor: "pointer",
  },
  text: {
    fontSize: "14px",
    color: "#5E5873",
    marginBottom: "10px",
  },
  dropdown: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  menuPaper: {
    maxHeight: "200px",
  },
  chip: {
    borderRadius: "4px",
    backgroundColor: "#C7C4C436",
    marginLeft: "5px",
  },
}));

export const TestingAutomationComponent: FunctionComponent = () => {
  const classes = useStyles();
  const [step, setStep] = useState("test");
  const [deivces, setDevices] = useState([]);
  const [constDeivces, setConstDevices] = useState([]);
  const [batches, setBatches] = useState(["No Batches Found"]);
  const [mainTestCases, setMainTestCases] = useState([]);
  const [allMainTestData, setAllMainTestData] = useState([]);
  const [allSubTestCases, setAllSubTestCases] = useState([]);
  const [testSummary, setTestSummary] = useState();
  const [allTestConditions, setAllTestConditions] = useState([]);

  const [selectedBatch, setSelectedBatch] = useState("Select The Batch");
  const [selectedMainTestCase, setSelectedMainTestCase] = useState(
    "Select The Test Case"
  );
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedSubTestCase, setSelectedSubTestCase] = useState();
  const [selectedTestCondition, setSelectedTestCondition] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);

  const cancel = () => {
    setSelectedDevice([]);
    setSelectedTestCondition([]);
  };
  const selectBatch = async (e) => {
    e.persist();
    setSelectedBatch(e.target.value);
    setMainTestCases([]);
    setAllMainTestData([]);
    setAllSubTestCases([]);
    setSelectedDevice([]);
    setLoading(true);
    let newDevices = [];

    constDeivces.map((device) => {
      if (device.batchNumber == e.target.value) {
        newDevices.push(device);
      }
    });
    setDevices(newDevices);
    const mainTestCasesData = await getAllMainTestCases(e.target.value);
    const testSummery = await getDeviceTestSummery(e.target.value);
    if (mainTestCasesData !== undefined) {
      setSelectedMainTestCase(mainTestCasesData.data[0].id);
      setMainTestCases(mainTestCasesData.mainTestCases);
      setAllMainTestData(mainTestCasesData.data);
      setAllSubTestCases(mainTestCasesData.data[0].subTestCases);
      setSelectedSubTestCase(mainTestCasesData.data[0].subTestCases[0].id);
    }
    if (testSummery !== undefined) {
      setTestSummary(testSummery);
    }
  };
  const selectMainTestCase = (e) => {
    setSelectedMainTestCase(e.target.value);
    const index = allMainTestData.findIndex((obj) => obj.id === e.target.value);
    setAllSubTestCases(allMainTestData[index].subTestCases);
    setSelectedSubTestCase(allMainTestData[index].subTestCases[0].id);
  };
  const selectDevice = (e) => {
    setSelectedDevice(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const selectSubTestCase = (e) => {
    setSelectedSubTestCase(e.target.value);
  };
  const selectTestCondition = (e) => {
    setSelectedTestCondition(e.target.value);
  };

  const removeDevice = (device) => {
    let newSelectedDevices = [];
    selectedDevice.map((d) => {
      if (d !== device) {
        newSelectedDevices.push(d);
      }
    });
    setSelectedDevice(newSelectedDevices);
  };
  const removeTestCondition = (condition) => {
    let newTestConditions = [];
    selectedTestCondition.map((c) => {
      if (c !== condition) {
        newTestConditions.push(c);
      }
    });
    setSelectedTestCondition(newTestConditions);
  };

  useEffect(() => {
    let allbatches;
    const allBatches = async () => {
      allbatches = await getAllBatches();
      if (allbatches !== undefined) {
        setBatches(allbatches);
        setSelectedBatch(allbatches[0]);
        const mainTestCasesData = await getAllMainTestCases(allbatches[0]);
        if (mainTestCasesData !== undefined) {
          setMainTestCases(mainTestCasesData.mainTestCases);
          setAllMainTestData(mainTestCasesData.data);
          setSelectedMainTestCase(mainTestCasesData.data[0].id);
          setAllSubTestCases(mainTestCasesData.data[0].subTestCases);
          setSelectedSubTestCase(mainTestCasesData.data[0].subTestCases[0].id);
        }
        const testSummery = await getDeviceTestSummery(allbatches[0]);
        if (testSummery !== undefined) {
          setTestSummary(testSummery);
        }
      }
    };
    const allDevices = async () => {
      const devices = await getAllDevices();
      let newDevices = [];
      if (devices !== undefined) {
        devices.map((d) => {
          if (d.batchNumber == allbatches[0]) {
            newDevices.push(d);
          }
        });
        setDevices(newDevices);
        setConstDevices(devices);
      }
    };
    const allTest = async () => {
      const allTestCondition = await getTestCondition();
      if (allTestCondition !== undefined) {
        setAllTestConditions(allTestCondition);
      }
    };

    allBatches();
    allDevices();
    allTest();
  }, []);


  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    if (newStartDate > endDate) {
      setEndDate("");
    }
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate < startDate) {
      setStartDate("");
    }
    setEndDate(newEndDate);
  };

  return (
    <div className={`testing`}>
      {loading && (
        <div
          style={{
            position: "fixed", // Change from "absolute" to "fixed"
            top: 0,
            left: 0,
            width: "100vw", // Use viewport width
            height: "100vh", // Use viewport height
            backgroundColor: "#000000",
            opacity: 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress size={80}/>
        </div>
      )}
      <Grid container>
        <Grid
          item
          xs={4}
          md={4}
          onClick={() => setStep("test")}
          className={classes.navButtons}
          style={{backgroundColor: step === "test" ? "#CACFD7" : "#ECEDEF"}}
        >
          Test Dashboard
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          onClick={() => setStep("parameter")}
          className={classes.navButtons}
          style={{
            backgroundColor: step === "parameter" ? "#CACFD7" : "#ECEDEF",
          }}
        >
          Parameter Configuration
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          onClick={() => setStep("device")}
          className={classes.navButtons}
          style={{
            backgroundColor: step === "device" ? "#CACFD7" : "#ECEDEF",
          }}
        >
          Device Configuration
        </Grid>

        <Grid item xs={12} md={3} style={{textAlign: "left", margin: "20px"}}>
          <Typography className={classes.text}>Select the batch</Typography>
          <FormControl className={classes.dropdown}>
            <NativeSelect
              id="demo-customized-select-native"
              value={selectedBatch}
              className={classes.dropdown}
              onChange={selectBatch}
              input={<BootstrapInput/>}
            >
              {batches.map((batch, index) => {
                return (
                  <option key={index} value={batch}>
                    {batch}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Grid>
        {step === "test" || step === "parameter" ? (
          <Grid
            item
            xs={12}
            md={3}
            style={{textAlign: "left", margin: "20px"}}
          >
            <Typography className={classes.text}>Select Test Cases</Typography>
            <FormControl className={classes.dropdown}>
              <NativeSelect
                id="demo-customized-select-native"
                value={selectedMainTestCase}
                className={classes.dropdown}
                onChange={selectMainTestCase}
                input={<BootstrapInput/>}
              >
                {allMainTestData.map((mainCase, index) => {
                  return (
                    <option key={index} value={mainCase.id}>
                      {mainCase.mainTestTitle}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              md={selectedDevice.length > 2 ? 12 : 3}
              style={{textAlign: "left", margin: "20px"}}
            >
              <Typography className={classes.text}>
                Current device configuration
              </Typography>
              <FormControl className={classes.dropdown}>
                <Select
                  id="demo-customized-select-native"
                  value={selectedDevice}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: {paper: classes.menuPaper},
                  }}
                  className={classes.dropdown}
                  onChange={selectDevice}
                  multiple
                  renderValue={(selected: string[]) => (
                    <div>
                      {selected.map((value, i) => (
                        <Chip
                          key={i}
                          label={value}
                          className={classes.chip}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => removeDevice(value)}
                        />
                      ))}
                    </div>
                  )}
                  input={<BootstrapInput/>}
                >
                  {deivces !== undefined &&
                    deivces.map((device, index) => {
                      return (
                        <MenuItem key={index} value={device.id}>
                          <Checkbox
                            checked={selectedDevice.includes(device.id)}
                            style={{color: "#8F8F8F", display: "inline"}}
                          />
                          <ListItemText primary={device.name}/>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={selectedDevice.length > 2 ? 1 : 3}></Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{textAlign: "left", margin: "20px"}}
            >
              <Typography className={classes.text}>
                Configure The Main Test Case
              </Typography>
              <FormControl className={classes.dropdown}>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={selectedMainTestCase}
                  className={classes.dropdown}
                  onChange={selectMainTestCase}
                  input={<BootstrapInput/>}
                >
                  {allMainTestData.map((mainCase, index) => {
                    return (
                      <option key={index} value={mainCase.id}>
                        {mainCase.mainTestTitle}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{textAlign: "left", margin: "20px"}}
            >
              <Typography className={classes.text}>
                Configure a Sub Test Case
              </Typography>
              <FormControl className={classes.dropdown}>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={selectedSubTestCase}
                  className={classes.dropdown}
                  onChange={selectSubTestCase}
                  input={<BootstrapInput/>}
                >
                  {allSubTestCases.map((sub, index) => {
                    return (
                      <option key={index} value={sub.id}>
                        {sub.subTestTitle}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={selectedTestCondition.length > 1 ? 12 : 3}
              style={{textAlign: "left", margin: "20px"}}
            >
              <Typography className={classes.text}>
                Configure a Test Condition
              </Typography>
              <FormControl className={classes.dropdown}>
                <Select
                  id="demo-customized-select-native"
                  value={selectedTestCondition}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: {paper: classes.menuPaper},
                  }}
                  className={classes.dropdown}
                  onChange={selectTestCondition}
                  multiple
                  renderValue={(selected: string[]) => (
                    <div>
                      {selected.map((value, i) => (
                        <Chip
                          key={i}
                          label={value}
                          className={classes.chip}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => removeTestCondition(value)}
                        />
                      ))}
                    </div>
                  )}
                  input={<BootstrapInput/>}
                >
                  {allTestConditions !== undefined &&
                    allTestConditions.map((test, index) => {
                      return (
                        <MenuItem key={index} value={test}>
                          <Checkbox
                            checked={selectedTestCondition.includes(test)}
                            style={{color: "#8F8F8F", display: "inline"}}
                          />
                          <ListItemText primary={test}/>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{textAlign: "left", margin: "20px"}}
            >
              <Typography className={classes.text}>Start Time</Typography>
              <TextBar
                onChange={handleStartDateChange}
                error={{error: false, errorMsg: ""}}
                id="datetime-local"
                label="Next appointment"
                type="datetime-local"
                defaultValue={startDate}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{textAlign: "left", margin: "20px"}}
            >
              <Typography className={classes.text}>End Time</Typography>
              <TextBar
                onChange={handleEndDateChange}
                error={{error: false, errorMsg: ""}}
                id="datetime-local"
                label="Next appointment"
                type="datetime-local"
                defaultValue={endDate}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        )}
        <Grid item md={7}></Grid>

        <Grid item xs={12} md={12}>
          {step === "test" ? (
            <div>
              {allMainTestData.length > 0 ? (
                <TestDashboard
                  mainTestCase={selectedMainTestCase}
                  allMainTestCases={mainTestCases}
                  allData={allMainTestData}
                  testSummaryData={testSummary}
                  loadingClose={() => {
                    setLoading(false);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ) : step === "parameter" ? (
            <div>
              <ParameterConfig
                batch={selectedBatch}
                mainTestId={selectedMainTestCase}
                allData={allMainTestData}
              />
            </div>
          ) : (
            <div style={{margin: "20px"}}>
              {allMainTestData.length > 0 ? (
                <DeviceConfig
                  onClick={() => cancel()}
                  startDate={startDate}
                  endDate={endDate}
                  testCondition={selectedTestCondition}
                  batch={selectedBatch}
                  devicesData={selectedDevice}
                  mainTestCase={selectedMainTestCase}
                  subTestCase={selectedSubTestCase}
                  allData={allMainTestData}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

//Bootstrap like dropdown component
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#F4F5F7",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);
