import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../custom-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../../account/account.service';
import {SideNavService} from '../../sidenav/sidenav.service';
import packageJson from 'package.json';

declare function toggleClass(id, className): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  error;
  username;
  version;

  constructor(private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private toaster: ToastrService, private accountService: AccountService, private sideNavService: SideNavService) {
  }

  ngOnInit() {
    this.username = AuthService.getUsername();
    this.version = packageJson.version;
    this.form = this.formBuilder.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
      validator: CustomValidators.passwordMatchValidator
    });
  }

  logout() {
    AuthService.signOut();
    this.router.navigate(['/login']);
  }

  changePassword(value: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    this.spinner.show();

    value.username = AuthService.getUsername();
    this.accountService.changePassword(value).subscribe(response => {
      this.spinner.hide();
      toggleClass('changePassword', 'hide');
      this.toaster.success('Password changed successfully', 'Success');
    }, error => {
      console.log(error);
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
    this.form.reset();
  }

  menuStateChange() {
    let state;
    if (localStorage.getItem('MENU_STATE')) {
      state = localStorage.getItem('MENU_STATE');
    } else {
      state = 'OPEN';
    }

    if (state === 'OPEN') {
      localStorage.setItem('MENU_STATE', 'OPEN');
    } else {
      localStorage.setItem('MENU_STATE', 'CLOSE');
    }
  }

  toggleSideNav() {
    this.sideNavService.toggleSideNav();
    // document.getElementById("addKitModel")
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  onClearError() {
    this.error = null;
  }
}
