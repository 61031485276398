import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as c3 from 'c3';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../../account/account.service';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import * as _ from 'underscore';
import {FormBuilder} from '@angular/forms';
import {SideNavService} from '../../sidenav/sidenav.service';

@Component({
  selector: 'app-graph-comparable',
  templateUrl: './graph-comparable.component.html',
  styleUrls: ['./graph-comparable.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphComparableComponent implements OnInit, AfterViewInit {
  @Input() graphDataObservable;
  @Input() properties;
  @Input() xPointsCount;
  @Input() customY1Name;
  @Input() customY2Name;

  graphData;
  selectedGraphKeys = [];
  currentSelectedTab = 0;
  deviceId;
  c3Chart;
  showForm = false;
  dropdownSettings;
  selectedItems = [];
  temp = [];

  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService, private accountService: AccountService, private formBuilder: FormBuilder,
              private sideNavService: SideNavService) {
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'itemName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      closeDropDownOnSelection: false,
    };

    this.properties.forEach((sensor, index) => {

      this.temp.push({'id': index, 'itemName': sensor.displayName});
    });


    this.route.params.subscribe((params) => {
      this.deviceId = params['deviceId'];
    });
    this.graphDataObservable.subscribe((graphs) => {
      this.selectedGraphKeys = [];
      this.graphData = graphs;
      let isNoData = true;
      if (this.graphData !== null) {
        let i = 0;
        while (i < this.graphData.length) {
          if (this.selectedGraphKeys.length === 0 && this.isPropertyHasData(this.graphData[i])) {
            this.selectedGraphKeys = [i];
          }
          i++;
        }
        isNoData = this.drawGraph();
      }
      document.getElementById('chart').style.display = isNoData ? 'none' : 'block';
      document.getElementById('noDataDiv').style.display = isNoData ? 'block' : 'none';
    });
  }

  ngAfterViewInit() {
    if (document.getElementsByTagName('svg').item(0)) {
      document.getElementsByTagName('svg').item(0).removeAttribute('style');
    }
  }

  drawGraph() {
    this.spinner.show();
    if (this.selectedGraphKeys.length === 0) {
      if (this.c3Chart) {
        this.c3Chart.load({unload: true});
      }
      this.spinner.hide();
      return true;
    }

    const isTwoGraph = this.selectedGraphKeys.length === 2;


    const graph1 = this.graphData[this.selectedGraphKeys[0]];
    if (graph1 === {}) {
      if (this.c3Chart) {
        this.c3Chart.load({unload: true});
      }
      this.spinner.hide();
      return true;
    }
    const graph1Y = graph1.valueY;
    const graph1X = graph1.valueX;
    const displayName1 = this.properties[this.selectedGraphKeys[0]].displayName;


    const linesY = this.getThresholdLevel();
    if (isTwoGraph) {
      const graph2 = this.graphData[this.selectedGraphKeys[1]];
      if (graph2 === {}) {
        if (this.c3Chart) {
          this.c3Chart.load({unload: true});
        }
        this.spinner.hide();
        return true;
      }
      const graph2Y = graph2.valueY;
      const graph2X = graph2.valueX;
      let displayName2 = this.properties[this.selectedGraphKeys[1]].displayName;

      if (displayName1 === displayName2) {
        displayName2 = displayName1 + ' 2';
      }

      const xs = {};
      xs[displayName1] = 'x1';
      xs[displayName2] = 'x2';
      const axes = {};
      axes[displayName1] = 'y';
      axes[displayName2] = 'y2';
      this.c3Chart = c3.generate({
        bindto: '#chart',
        data: {
          xs: xs,
          columns: [
            ['x1'].concat(graph1X),
            ['x2'].concat(graph2X),
            [displayName1].concat(graph1Y),
            [displayName2].concat(graph2Y)
          ],
          xFormat: '%Y-%m-%d %H:%M',
          axes: axes
        },
        grid: {
          y: {
            lines: linesY,
          }
        },
        subchart: {
          show: true
        },
        axis: {
          x: {
            show: true,
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },

            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed
              fit: true,
              culling: {
                max: 10,
              },
              count: this.xPointsCount,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: this.customY1Name ? this.customY1Name : displayName1,
              position: 'outer-middle'
            },
          },
          y2: {
            show: true,
            label: {
              text: this.customY2Name ? this.customY2Name : displayName2,
              position: 'outer-middle'
            },
          }
        }
      });
    } else {
      this.c3Chart = c3.generate({
        bindto: '#chart',
        data: {
          x: 'x1',
          xFormat: '%Y-%m-%d %H:%M',
          columns: [['x1'].concat(graph1X),
            [displayName1].concat(graph1Y)
          ],
        },
        grid: {
          y: {
            lines: linesY,
          }
        },
        subchart: {
          show: true
        },
        axis: {
          x: {
            show: true,
            label: {
              text: 'Date Time',
              position: 'outer-center',
            },

            type: 'timeseries',
            tick: {
              format: '%Y-%m-%d %H:%M', // how the date is displayed
              fit: true,
              culling: {
                max: 10,
              },
              count: this.xPointsCount,
              // rotate: 10
              multiline: true,
            }
          },
          y: {
            label: {
              text: this.customY1Name ? this.customY1Name : displayName1,
              position: 'outer-middle'
            },
          }
        }
      });
    }

    this.spinner.hide();
    return false;
  }

  selectTab(index) {
    if (!this.isPropertyHasData(this.graphData[index])) {
      return;
    }
    console.log('Tabs' + index);
    const keys = this.selectedGraphKeys;
    console.log('Keys ++++++' + keys);
    if (keys.length === 1 && !keys.includes(index)) {
      this.selectedGraphKeys.push(index);
    } else if (keys.length === 2 && !keys.includes(index)) {
      this.selectedGraphKeys = [index];
      this.currentSelectedTab = index;
    } else if (keys.length === 2 && keys.includes(index)) {
      this.selectedGraphKeys.splice(this.selectedGraphKeys.indexOf(index), 1);
      this.currentSelectedTab = this.selectedGraphKeys[0];
    }
    const isNoData = this.drawGraph();
    document.getElementById('chart').style.display = isNoData ? 'none' : 'block';
    document.getElementById('noDataDiv').style.display = isNoData ? 'block' : 'none';
  }

  getThresholdLevel() {
    // const factor = this.device.factors[this.factorsArray[this.currentSelectedTab]];
    if (this.customY1Name || this.customY2Name) {
      return;
    }
    const propName1 = this.properties[this.selectedGraphKeys[0]].displayName;
    let propName2;
    if (this.selectedGraphKeys.length === 2) {
      propName2 = this.properties[this.selectedGraphKeys[1]].displayName;
    }
    return [
      {
        value: this.graphData[this.selectedGraphKeys[0]]['alertLimit'][0].high,
        axis: 'y',
        text: 'Max Threshold: ' + propName1,
        position: 'middle'
      },
      {
        value: this.graphData[this.selectedGraphKeys[0]]['alertLimit'][0].low,
        axis: 'y',
        text: 'Min Threshold: ' + propName1,
        position: 'middle'
      }
    ].concat(this.selectedGraphKeys.length === 2 ? [
      {
        value: this.graphData[this.selectedGraphKeys[1]]['alertLimit'][0].high,
        axis: 'y2',
        text: 'Max Threshold: ' + propName2,
        position: 'middle'
      },
      {
        value: this.graphData[this.selectedGraphKeys[1]]['alertLimit'][0].low,
        axis: 'y2',
        text: 'Min Threshold: ' + propName2,
        position: 'middle'
      }
    ] : []);
  }


  exportFunction() {
    // download the file using old school javascript method
    // this.exportAsService.save(this.exportAsConfig, 'My File Name');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    const propertyName = this.properties[this.selectedGraphKeys[0]].displayName;
    const graphData = this.graphData[this.selectedGraphKeys[0]].valueY.map((data, index) => {
      return {
        time: this.graphData[this.selectedGraphKeys[0]].valueX[index],
        displayName: propertyName,
        value: data
      };
    });
    const modifiedSet = _.map(graphData, e => {
      return _.pick(e, ['time', 'value']);
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: ['Date', this.properties[this.selectedGraphKeys[0]].displayName],
      nullToEmptyString: true,
    };


    const csv = new Angular5Csv(modifiedSet,
      this.deviceId + '-' + propertyName + '-Report', opt);
  }

  // getSensorsSelected() {
  //   const temp = [];
  //   this.properties.forEach((sensor, index) => {
  //     console.log('Name :' + sensor.displayName);
  //     temp.push({'id': index, 'itemName': sensor.displayName});
  //   });
  //   return temp;
  //   // return Array.from({length: this.sensorsOfKitModel.length}, (v, k) => String(k));
  // }


  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  exportFunctionForAll() {
    this.showForm = false;
    const gdatas = [];
    const headerNames = [];
    headerNames[0] = 'Date';
    this.selectedItems.forEach(element => {
      console.log('Element id' + element.id);
      if (!this.graphData[element.id] || !this.graphData[element.id].valueY) {
        return;
      }
      const graphData = this.graphData[element.id].valueY.map((data, index) => {
        return {
          time: this.graphData[element.id].valueX[index],
          displayName: element.itemName,
          value: data
        };
      });
      headerNames.push(element.itemName);
      gdatas.push(graphData);
    });

    const modifiedSets = [];

    gdatas.forEach(gdata => {
      gdata.forEach(e => {
        let row = _.findWhere(modifiedSets, {time: e.time});
        if (row) {
          row[e.displayName] = e.value;
        } else {
          row = {};
          row['time'] = e.time;
          row[e.displayName] = e.value;
          modifiedSets.push(row);
        }
      });
    });


    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: headerNames,
      nullToEmptyString: true,
    };


    const csv = new Angular5Csv(modifiedSets,
      this.deviceId, opt);

    this.selectedItems = [];
  }


  isPropertyHasData(graph) {
    return Object.keys(graph).length !== 0;
  }

  hideForm() {
    if (this.showForm) {
      this.showForm = false;
      this.selectedItems = [];
    } else {
      this.showForm = true;
    }
  }

  isSideNavVisible() {
    console.log('Side nav');
    return this.sideNavService.isSideNavVisible;
  }
}
