import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {environment} from 'src/environments/environment';
import {primaryBlue, seconderyTextColor, white} from '../sub-components/colors/colors';
import {SearchBar} from '../sub-components/SearchBar';
import {AddNewDecoder} from './add-new-decoder/NewDecoder';
import 'react-toastify/dist/ReactToastify.css';
import {DeleteDecoder} from './delete-decoder/DeleteDecoder';
import {ConnectDevice} from './connect-devices/ConnectDevice';
import {Alert} from "@material-ui/lab";
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import axios from "axios";
import {toast} from "react-toastify";

export function DecodersComponents(props) {
  const classes = useStyles();
  const [decoders, setDecoders] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newDecoder, setNewDecoder] = useState(false);
  const [decoderId, setDecoderId] = useState('');
  const [deleteDecoder, setDeleteDecoder] = useState(false);
  const [type, setType] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [conentDevice, setConnectDevice] = useState(false);
  const [decoderNames, setDecoderNames] = useState([]);
  const [connectDecoderWithDevice, setConnectDecoderWithDevice] = useState([]);
  const [toaster, setToaster] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reqSuccess, setReqSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDecoderFileName, setCurrentDecoderFileName] = useState('');
  const [decoderScript, setDecoderScript] = useState()
  const [currentEncoderFileName, setCurrentEncoderFileName] = useState('');
  const [encoderScript, setEncoderScript] = useState()
  const [currentCodecDetails, setCurrentCodecDetails] = useState();
  const [codecName, setCodecName] = useState('')
  const history = useHistory();
  const dispatch = useDispatch();
  const [devices, setDevices] = useState([])
  const [devicesArrayAll, setDevicesArrayAll] = useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setID = (decoder) => {
    setDecoderId(decoder.id);
    setEncoderScript(decoder.encoderFileContent)
    setCurrentEncoderFileName(decoder.encoderFileName)
    setDecoderScript(decoder.decoderFileContent)
    setCurrentDecoderFileName(decoder.decoderFileName)
    setCodecName(decoder.codecName)

  }
  const closePopup = () => {
    setNewDecoder(false);
    setDeleteDecoder(false);
    setConnectDevice(false);
  }
  useEffect(() => {
    getAllCodec()
    getDeviceDataAll()
  }, [])

  const getAllCodec = () => {
    // let isMounted = true;
    fetch(
      environment.host + `/core/codec`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then((response) => response.json())
      .then((data) => {

        setLoading(false);
        setDecoders(data.content);
        const names = data.content.map(decoder => decoder.codecName);
        setDecoderNames(names);
        setTimeout(() => {

          setToaster(false);

        }, 4000);


      })
      .catch((err) => err.message);
    return () => {
      // isMounted = false;
    };
  }

  const getDeviceDataAll = () => {
    setLoading(true)
    axios.get(
      environment.host + '/core/device'
    ).then((res) => {
      setDevices(res.data.content)
      const devicesFiler = (res.data.content.filter((dev) => {
        return dev.magmaCodecId === null || !dev.hasOwnProperty('magmaCodecId')
      }))
      setDevicesArrayAll(devicesFiler)
    }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went Wrong, Please try again later");
      }
    }).finally(() => {
      setLoading(false)
    })

  }


  const updateDecoders = (decoderId, newName, updateStatus) => {
    const updatedDecoders = decoders.map(decoder => {
      if (decoder.id === decoderId) {
        return {...decoder, name: newName, staus: updateStatus};
      }
      return decoder;
    });
    setDecoders(updatedDecoders);
  };
  // const gotoViewDecoder = (id) => {
  //     setDecoderId(id);
  //     console.log(id)
  //     history.push("/" + id + "/decoder")
  // }
  // const selectDecoder = (id) => {
  //     // const decode = useSelector(state => state)
  //     decoders.map(decoder => {
  //         if (decoder.id === id) {
  //             console.log(decoder)
  //             return decoder
  //         }
  //     })
  // }

  return (
    <div>
      <div className={`testing`}>
        {
          <Grid container className={classes.container}>
            <Grid item xs={12} md={12} style={{display: "flex", height: "40px"}}>
              <Grid item style={{textAlign: "left", width: "240px"}}>
                <SearchBar onChange={(e) => setSearch(e.target.value)}/>
              </Grid>
              <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                <Grid item style={{textAlign: "right"}}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setConnectDevice(true), setType('connect')
                    }}
                    className={classes.connectDecoder}
                    style={{textTransform: "none"}}
                  >Connect Devices</Button>
                </Grid>
                <Grid item style={{textAlign: "right", paddingLeft: "10px"}}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setNewDecoder(true), setType("addNew")
                    }}
                    className={classes.addDecoder}
                    style={{textTransform: "none"}}
                  >Create Codec</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{
              textAlign: 'left',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '0px',
              justifyContent: 'flex-end'
            }} item xs={12} md={12}>

              <TablePagination style={{marginRight: '8px', marginTop: '10px'}}
                               rowsPerPageOptions={[]}
                               component="div"
                               count={decoders !== undefined && decoders.filter((dec) => dec.codecName.toLowerCase().includes(search.trim().toLowerCase())).length}
                               rowsPerPage={rowsPerPage}
                               page={page}
                               onPageChange={handleChangePage}
                               onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{marginTop: "20px"}}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                        width: '200px'
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>ENCODER/DECODER</Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white"
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>STATUS</Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white"
                      }}>
                        <Typography style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>ACTIONS</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {decoders !== undefined && decoders.filter((obj) => {
                      if (search === "") {
                        return obj
                      } else if (obj.codecName.toLowerCase().includes(search.trim().toLowerCase())) {
                        return obj
                      }
                    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((decoder, i) => (

                      <TableRow key={i}>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          width: '200px'
                        }}>
                          <Typography display='inline' className={classes.tableText}
                                      style={{paddingLeft: "5px"}}>{decoder.codecName}</Typography>
                        </TableCell>
                        <TableCell className={classes.status} style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <Typography className={classes.statusText} style={{
                            backgroundColor: decoder.status ? '#DFF2EE' : '#FBE9E8',
                            color: decoder.status ? '#59C3A1' : '#DB4A42'
                          }}>
                            {decoder.status ? 'Valid' : 'Invalid'}
                          </Typography>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "center",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <Link to={{
                            pathname: `/${decoder.id}/codec`,
                            state: {
                              data: decoder,
                              decoderName: decoderNames,
                              decoders: decoders
                            }
                          }
                          }
                          >
                            <IconButton style={{padding: "0px"}} onClick={() => setType('view')}>
                              <ViewIcon/>
                            </IconButton>
                          </Link>
                          <IconButton style={{padding: "0px", paddingLeft: "10px"}} onClick={() => {
                            setType('update'), setNewDecoder(true), setID(decoder), setCurrentCodecDetails(decoder)

                          }}>
                            <EditIcon/>
                          </IconButton>
                          <IconButton style={{padding: "0px", paddingLeft: "10px"}} onClick={() => {
                            setID(decoder), setDeleteDecoder(true)
                          }}>
                            <DeleteIcon/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        }
      </div>
      <AddNewDecoder
        open={newDecoder}
        onClose={() => closePopup()}
        setDecoders={setDecoders}
        type={type}
        decoderId={decoderId}
        updateDecoders={updateDecoders}
        setDecoderName={setDecoderNames}
        decoderNames={decoderNames}
        getAllCodec={() => getAllCodec()}
        setLoading={setLoading}
        setToast={setToaster}
        setReqSuccess={setReqSuccess}
        setErrorMessage={setErrorMessage}
        codecName={codecName}
        currentEncoderFileName={currentEncoderFileName}
        currentDecoderFileName={currentDecoderFileName}
        decoderScript={decoderScript}
        encoderScript={encoderScript}
        decoders={decoders}

      />
      <DeleteDecoder open={deleteDecoder} onClose={() => closePopup()} decoderId={decoderId} decoders={decoders}
                     setDecoders={setDecoders} setDecoderName={setDecoderNames} setLoading={setLoading}
                     getAllCodec={() => getAllCodec()} setToast={setToaster}
                     setReqSuccess={setReqSuccess}
                     setErrorMessage={setErrorMessage}
                     getDeviceDataAll={() => getDeviceDataAll()}
      />
      <ConnectDevice open={conentDevice} onclose={() => closePopup()} decoderId={decoderId} decoders={decoders}
                     setDecoders={setDecoders} decodersName={decoderNames}
                     setConnectDecoderWithDevice={setConnectDecoderWithDevice} type={type} setLoading={setLoading}
                     setToast={setToaster}
                     setReqSuccess={setReqSuccess}
                     setErrorMessage={setErrorMessage}
                     getAllCodec={() => getAllCodec()}
                     devicesArrayAll={devicesArrayAll}
                     getDeviceDataAll={() => getDeviceDataAll()}/>
      <Snackbar
        open={toaster}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          severity={reqSuccess === true ? "success" : "warning"}
        >
          {/* <h3 style={{ textAlign: "left" }}>
                        {reqSuccess === true ? "Success" : "Error"}
                    </h3> */}
          {
            errorMessage.length > 0
              ? errorMessage
              : "Something went wrong"
          }
        </Alert>
      </Snackbar>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
    </div>

    // </Provider>

    // </BrowserRouter>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
    paddingRight: "50px",
    paddingLeft: "50px",
  },
  filter: {
    backgroundColor: white,
    color: seconderyTextColor,
    boxShadow: "none",
  },
  addDecoder: {
    backgroundColor: '#427AE5',
    color: white,
    boxShadow: "none",
    fontFamily: "poppins",
    height: "40px",
    width: "200px",
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#427AE5',
      color: white,
      boxShadow: "none"
    },
    // [theme.breakpoints.down('md')]:{
    //     margin:"10px",
    // }
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#FC4141",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px"
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px"
  },
  connectDecoder: {
    backgroundColor: white,
    color: primaryBlue,
    boxShadow: "none",
    height: "40px",
    width: "200px",
    borderColor: primaryBlue,
    border: "2px solid",
    '&:hover': {
      backgroundColor: white,
      color: primaryBlue,
      boxShadow: "none"
    },
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  statusText: {
    width: "62px",
    borderRadius: "10px",
    fontSize: "14px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  status: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "45px"
  }
}));
export const OutlinedStar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={props.color}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path
        d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
    </svg>
  )
}
export const ContainedStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EFD494">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>
  )
}
export const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.75" height="11.75" viewBox="0 0 11.75 11.75">
      <g id="info_2_" data-name="info (2)" transform="translate(-1.625 -1.625)">
        <circle id="Ellipse_753" data-name="Ellipse 753" cx="5.5" cy="5.5" r="5.5" transform="translate(2 2)"
                fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        <line id="Line_570" data-name="Line 570" y1="3" transform="translate(7.5 6.444)" fill="none" stroke="#707070"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        <line id="Line_571" data-name="Line 571" transform="translate(7.5 4.75)" fill="none" stroke="#707070"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
      </g>
    </svg>
  )
}
export const Refresh = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0000008A">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
    </svg>
  )
}
export const MenuDots = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0000008A">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
    </svg>
  )
}
export const ViewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="15" viewBox="0 0 20.25 15">
      <g id="eye_1_" data-name="eye (1)" transform="translate(0.5 0.5)">
        <path id="Path_13136" data-name="Path 13136" d="M1,11s3.5-7,9.625-7,9.625,7,9.625,7-3.5,7-9.625,7S1,11,1,11Z"
              transform="translate(-1 -4)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1"/>
        <circle id="Ellipse_366" data-name="Ellipse 366" cx="3" cy="3" r="3" transform="translate(6.661 4)" fill="none"
                stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </svg>
  )
}
export const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15.121" viewBox="0 0 15 15.121">
      <g id="edit" transform="translate(0.5 0.5)">
        <path id="Path_1997" data-name="Path 1997"
              d="M8.5,4H3.444A1.444,1.444,0,0,0,2,5.444V15.556A1.444,1.444,0,0,0,3.444,17H13.556A1.444,1.444,0,0,0,15,15.556V10.5"
              transform="translate(-2 -2.879)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1"/>
        <path id="Path_1998" data-name="Path 1998"
              d="M15.436,2.319A1.5,1.5,0,1,1,17.56,4.443l-6.727,6.727L8,11.879l.708-2.833Z"
              transform="translate(-4 -1.879)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1"/>
      </g>
    </svg>
  )
}
export const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
      <path id="material-symbols_delete-outline-rounded"
            d="M7,21a2.006,2.006,0,0,1-2-2V6A.982.982,0,0,1,4,5,.982.982,0,0,1,5,4H9a.982.982,0,0,1,1-1h4a.982.982,0,0,1,1,1h4a.982.982,0,0,1,1,1,.982.982,0,0,1-1,1V19a2.006,2.006,0,0,1-2,2ZM7,6V19H17V6ZM9,16a1,1,0,1,0,2,0V9A1,1,0,1,0,9,9Zm4,0a1,1,0,1,0,2,0V9a1,1,0,1,0-2,0Z"
            transform="translate(-4 -3)" fill="#f06a6a"/>
    </svg>
  )
}
export const TOGGLE = {
  DECODE: 'decode',
  DEVICE: 'device'
};
