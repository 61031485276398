import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../sidenav/sidenav.service';
import {AccountService} from '../account/account.service';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../authentication/auth.service';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  selector: 'app-device-connectivity',
  templateUrl: './device-connectivity.component.html',
  styleUrls: ['./device-connectivity.component.css'],

})
export class DeviceConnectivityComponent implements OnInit {
  formConnectivity: FormGroup;
  searchTerm = '';
  index = '';
  isToggleOn: boolean = true;
  isAddNew: boolean = false;
  filterBtnEnable: boolean = false;
  isDropdownVisibleProtocol = false;
  isDropdownVisibleClientId = false;
  generateClick = false;
  selectedOption = '';
  accessKey: string = '';
  clientId = '';
  deviceData: any[] = [];
  viewToggle = false;
  userName = '';
  password = '';
  publishAcl: Array<object> = [];
  subscribeAcl: Array<object> = [];
  filterRows = [];
  isSubmitted = false;
  p = 1;
  protocol = '';
  connectedProtocol = '';
  textCopy;
  deviceCount;
  isGenerate = false;
  isClientIdExists = false;
  clientIdArray = [];
  connectivityDetails;
  subscribe_acl;
  publish_acl;
  currentId;
  error;
  deviceId;
  editView = false;
  currentUsername;
  currentProtocol;
  deleteView = false;
  selectedGrantType;
  grantTypeDropDown = false;
  authoritiesArray = []
  editMqtt = false;
  editHttp = false;
  editHttpPassword = false
  currentGrantType;
  clientSecret;
  scope;
  authorities;
  selectprotocol;
  httpConnectivityLength
  mqttLength;
  mqttView = false;
  httpView = false
  httpPasswordView = false;
  authorityString;
  currentStatus;


  constructor(private sideNavService: SideNavService, private accountService: AccountService,
              private toaster: ToastrService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder,
              private clipboardService: ClipboardService) {
  }

  ngOnInit() {
    this.formConnectivity = this.formBuilder.group({
      username: [null],
      client_id: [null, Validators.required],
      password: [null],
      connectivity: [null, Validators.required],
      publish_acl: [null],
      subscribe_acl: [null],
      client_secret: [null],
    });
    this.formConnectivity.get('connectivity').valueChanges.subscribe((connectivity) => {
      this.updateValidation(connectivity)
    })
    this.spinner.show()
    this.getClientsWithProtocolAndDeviceCount()
    this.deviceCountEachConnectivity()
  }

  updateValidation(connectivity: string) {
    const userNameControl = this.formConnectivity.get('username');
    const passwordControl = this.formConnectivity.get('password');
    const publishControl = this.formConnectivity.get('publish_acl');
    const subscribeAclControl = this.formConnectivity.get('subscribe_acl');
    const clientSecretControl = this.formConnectivity.get('client_secret');
  
    // Clear validators for all controls initially
    [userNameControl, passwordControl, publishControl, subscribeAclControl, clientSecretControl].forEach(control => {
      control.clearValidators();
      control.updateValueAndValidity();
    });
  
    if (connectivity == 'MQTT') {
      userNameControl.setValidators([Validators.required]);
      passwordControl.setValidators([Validators.required]);
      publishControl.setValidators([jsonValidator()]);
      subscribeAclControl.setValidators([jsonValidator()]);
    } else {
      clientSecretControl.setValidators([Validators.required]);
    }
  
    // Trigger revalidation of the form
    this.formConnectivity.updateValueAndValidity();
  }
  

  protocols: string[] = ["MQTT", "HTTP"];
  newProtocols: string[] = ["MQTT", "HTTP", "HTTPS", "LoRa"]
  grantType: string[] = ["client_credentials", "Password"]

  toggleButton(row: any) {
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  addNewCredential() {
    this.isAddNew = true;
    this.isSubmitted = false;
  }

  showProtocol() {
    this.filterBtnEnable = !this.filterBtnEnable;
  }

  isToggleDropDown() {
    this.isDropdownVisibleProtocol = !this.isDropdownVisibleProtocol;
  }

  isGrantTypeDropDown() {
    this.grantTypeDropDown = !this.grantTypeDropDown
  }

  isToggleClient() {
    this.isDropdownVisibleClientId = !this.isDropdownVisibleClientId;
  }

  updateProtocol(protocol: string): void {
    this.filterBtnEnable = true;
    this.protocol = protocol;
  }

  getConnectivity(data: string) {
    this.isDropdownVisibleProtocol = true
    this.selectedOption = data;
    this.isSubmitted = false
  }

  selectGrantType(type) {
    console.log(type)
    this.grantTypeDropDown = true
    this.isSubmitted = false
    this.selectedGrantType = type
    this.currentGrantType = type
  }

  createVmqAcl(data) {
    let connectivityArray = {};
    this.isSubmitted = true;
    this.updateValidation(data.connectivity)
    if (this.formConnectivity.invalid || (!AuthService.hasNewUserAccess) || this.checkClientId(data.client_id)) {
      return;
    }
    this.spinner.show()
    this.checkClientId(data.client_id)
    const publishValue = JSON.parse(data.publish_acl)
    const subscribeValue = JSON.parse(data.subscribe_acl)
    this.publishAcl.push(publishValue)
    this.subscribeAcl.push(subscribeValue)
    if (data.connectivity == "MQTT") {
      connectivityArray = {
        username: data.username,
        client_id: data.client_id,
        password: data.password,
        publish_acl: publishValue,
        subscribe_acl: subscribeValue
      }
      this.accountService.createVmqAcl(connectivityArray).subscribe((response) => {
        this.clientId = response.content.client_id;
        this.spinner.hide()
        this.formConnectivity.reset();
        this.isAddNew = false;
        this.toaster.success(response.message);
        this.getClientsWithProtocolAndDeviceCount()
        this.deviceCountEachConnectivity()
      }, error => {
        this.spinner.hide()
        this.toaster.error('Something went wrong')
      }, () => {
        this.spinner.hide()
      })
    } else {
      connectivityArray = {
        client_secret: data.client_secret,
        scope: 'read,write,trust',
        grant_type: 'client_credentials',
        authorities: [],
        client_id: data.client_id
      }
      this.accountService.createHttpAclAuth(connectivityArray).subscribe((response) => {
        this.clientId = response.content.client_id;
        this.spinner.hide()
        this.formConnectivity.reset();
        this.isAddNew = false;
        this.toaster.success(response.message);
        this.getClientsWithProtocolAndDeviceCount()
        this.deviceCountEachConnectivity()
      }, error => {
        this.spinner.hide()
        this.toaster.error('Something went wrong')
      }, () => {
        this.spinner.hide()
      })
    }
  }

  generateToggle() {
    if (this.selectedOption != "MQTT") {
      this.generateClick = true;
    } else {
      this.generateClick = true;

    }
  }

  getClientId(id) {
    this.accountService.clientId = id;
  }

  isToggleView(connectivity) {
    this.viewToggle = !this.viewToggle;
    if (connectivity.protocol == 'MQTT') {
      this.mqttView = true
    } else {
      this.httpView = true
    }
  }

  viewConnectionDetail(id) {
    this.currentId = id;
    this.connectivityDetails.forEach((connectivity) => {
      if (id == connectivity.data.id) {
        this.clientId = connectivity.clientId;
        this.selectprotocol = connectivity.protocol;
        if (this.selectprotocol == 'HTTP') {
          this.clientSecret = connectivity.data.client_secret
        } else if (this.selectprotocol == 'MQTT') {
          this.userName = connectivity.data.username
          this.password = connectivity.data.password
          this.publish_acl = JSON.stringify(connectivity.data.publish_acl)
          this.subscribe_acl = JSON.stringify(connectivity.data.subscribe_acl)
        }
      }
    })
  }

  showFilterDevice() {
    if (this.searchTerm === '' && (this.protocol === '' || this.protocol === 'ALL')) {
      return this.connectivityDetails;
    } else if (this.protocol !== '' && this.searchTerm === '') {
      return this.filterRows = this.connectivityDetails.filter(row =>
        row.protocol.toLowerCase().includes(this.protocol.toLowerCase()))
    } else if (this.searchTerm !== '' && (this.protocol === '' || this.protocol === 'ALL')) {
      return this.filterRows = this.connectivityDetails.filter(row =>
        row.clientId.toLowerCase().includes(this.searchTerm.toLowerCase()))
    } else {
      return this.filterRows = this.connectivityDetails.filter(row =>
        row.clientId.toLowerCase().includes(this.searchTerm.toLowerCase()) && row.protocol.toLowerCase().includes(this.protocol.toLowerCase()))
    }
  }

  public pageChange(event: number): void {
    this.p = event;
  }

  copyText(input: any) {
    const inputFieldValue = document.getElementById(input) as HTMLInputElement
    inputFieldValue.select();

    try {
      this.clipboardService.copyFromContent(inputFieldValue.value);
      this.toaster.success('Text copied')
    } catch (err) {
      this.toaster.error(err)
    }
  }

  getClientsWithProtocolAndDeviceCount() {
    this.accountService.getClientsWithProtocolAndDeviceCount().subscribe((res) => {
      this.connectivityDetails = res.content;
      this.clientIdArray = res.content.map((client) => client.clientId);
      this.spinner.hide()
    })
  }

  deviceCountEachConnectivity() {
    this.accountService.getAllVmqAcl().subscribe((res) => {
      this.mqttLength = res.content.length
    })

    this.accountService.getAllHttpAcl().subscribe((response: any) => {
      this.httpConnectivityLength = response.content.length
    })
  }

  checkClientId(clientId) {
    if (this.editMqtt == true || this.editHttp == true) {
      this.isClientIdExists = clientId !== this.clientId && this.clientIdArray.some((id) => id === clientId);
    } else {
      this.isClientIdExists = this.clientIdArray.some((id) => id === clientId);
    }
    return this.isClientIdExists
  }

  updateValue(data, id) {
    this.isSubmitted = true;
    let connectivityArray
    if (data.connectivity == 'MQTT'){
      this.editMqtt = true
    }else{
      this.editHttp = true
    }
    if (!this.formConnectivity.valid || (!AuthService.hasNewUserAccess) || this.checkClientId(data.client_id)) {
      return;
    }
    this.spinner.show()
    if (data.connectivity == 'MQTT') {
      const publishValue = JSON.parse(data.publish_acl)
      const subscribeValue = JSON.parse(data.subscribe_acl)
      this.publishAcl.push(publishValue)
      this.subscribeAcl.push(subscribeValue)
      connectivityArray = {
        username: data.username,
        client_id: data.client_id,
        password: data.password,
        publish_acl: publishValue,
        subscribe_acl: subscribeValue
      }
      console.log(connectivityArray)
      this.accountService.updatedVernemq(id, connectivityArray).subscribe((result) => {
        this.spinner.hide()
        this.toaster.success('Succesfully updated');
        this.getClientsWithProtocolAndDeviceCount()
        this.deviceCountEachConnectivity();
        this.editMqtt = false
      }, error => {
        console.log('Error updating object details:', error);
        this.toaster.error(error.message)
      }, () => {
        this.spinner.hide()
      })
    } else {
      connectivityArray = {
        client_secret: data.client_secret,
        scope: 'read,write,trust',
        grant_type: 'client_credentials',
        authorities: [],
        client_id: data.client_id
      }

      this.accountService.updateHttpClient(connectivityArray, id).subscribe((res) => {
        this.spinner.hide()
        this.toaster.success('Succesfully updated');
        this.getClientsWithProtocolAndDeviceCount()
        this.deviceCountEachConnectivity();
        this.editHttp = false
        this.editHttpPassword = false
        this.isSubmitted = false
      }, error => {
        console.log('Error updating object details:', error);
        this.toaster.error(error.message)
      }, () => {
        this.spinner.hide()
      })
    }

  }


  deleteClientDetails() {
    this.spinner.show()
    if (this.selectprotocol == 'MQTT') {
      this.accountService.deleteVmqAcl(this.currentId).subscribe((response) => {
          this.getClientsWithProtocolAndDeviceCount()
          this.spinner.hide()
          this.toaster.success('Device successfully deleted')
          this.deleteView = false
          this.deviceCountEachConnectivity()
        },
        error => {
          this.deleteView = false
          console.error('Error removing object:', error);
          this.toaster.error(error.message)
        }
      );
    } else if (this.selectprotocol == 'HTTP') {
      this.accountService.deleteHttpClient(this.currentId).subscribe((response) => {
          this.getClientsWithProtocolAndDeviceCount()
          this.spinner.hide()
          this.toaster.success('Device successfully deleted')
          this.deleteView = false
          this.deviceCountEachConnectivity()
        },
        error => {
          this.deleteView = false
          console.error('Error removing object:', error);
          this.toaster.error(error.message)
        }
      );
    }

  }

  updateActionkey(id, status: boolean) {
    let connectivityProtocol
    let clientId
    this.connectivityDetails.forEach((connectivity) => {
      if (id == connectivity.data.id) {
        connectivityProtocol = connectivity.protocol
        clientId = connectivity.clientId
      }
    })
    if (connectivityProtocol == 'MQTT') {
      this.accountService.updateActionkey(clientId, status).subscribe((res) => {
      })
    } else {
      this.accountService.updateActionkeyHttp(id, status).subscribe((res) => {
        }
      )
    }
  }

}


function jsonValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.value;
    try {
      const jsonObjects = JSON.parse(input);
      return null; // All objects are valid JSON
    } catch (error) {
      return {invalidJson: true}; // Invalid JSON or syntax error
    }
  };
}
