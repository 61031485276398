import {FormControl, makeStyles, MenuItem, Select, Theme} from '@material-ui/core';
import * as React from 'react';

export function IconDropDown(props) {
  const classes = useStyles()

  return (
    <FormControl>
      <Select value=""
              onChange={(e) => props.onChange(e)}
              style={{width: "25px", height: "20px"}}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                }
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                classes: {paper: classes.menuPaper},
                getContentAnchorEl: null
              }}
              disableUnderline>
        {
          props.data !== undefined && props.data.map((val, i) => (
            <MenuItem value={val} key={i}>{val}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: 'black'
  },
  root: {
    backgroundColor: "white",
    '&:focus': {
      backgroundColor: "white",
    },
  },
  menuPaper: {
    maxHeight: "200px"
  }
}));
