import * as React from "react";
import {useEffect, useState} from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {primaryBlue, primaryGray, primaryRed} from 'src/components/sub-components/colors/colors';
import Dropzone from 'react-dropzone';
import {DragDrop} from 'src/components/sub-components/DragAndDrop';
import 'react-toastify/dist/ReactToastify.css';
import {environment} from "src/environments/environment";

export function AddNewDecoder(props) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [decoderScript, setDecoderScript] = useState(props.encoderScript || '');
  const [encoderScript, setEncoderScript] = useState('');
  const [nameError, setNameError] = useState(false);
  const [encoderFileError, setEncoderFileError] = useState(false);
  const [decoderFileError, setDecoderFileError] = useState(false);
  const [selectedDecoderScript, setSelectedDecoderScript] = useState();
  const [selectedEncoderScript, setSelectedEncoderScript] = useState();
  const [codecNameExistError, setCodecNameExistError] = useState(false)
  let existCodecName = props.codecName
  const closePopup = () => {
    if (props.type === 'update') {
      props.onClose();
      setName('');
    }
    props.onClose();
    setNameError(false);
    setEncoderFileError(false)
    setDecoderFileError(false)
    setCodecNameExistError(false)
    setName('');
    setDecoderScript('');
    setEncoderScript('')
    setSelectedDecoderScript(null)
    setSelectedEncoderScript(null)
  }

  const readDecoderScript = (decoderFile) => {
    setDecoderScript(decoderFile[0].name)
    setSelectedDecoderScript(decoderFile[0])
    setDecoderFileError(false)
  }

  const readEncoderScript = (encoderFile) => {
    setEncoderScript(encoderFile[0].name)
    setSelectedEncoderScript(encoderFile[0])
    setEncoderFileError(false)
  }

  const changeFile = (fileContent, name) => {
    var fileContent = fileContent
    var fileName = name
    var blob = new Blob([fileContent], {
      type: ""
    });
    var file = new File([blob], fileName, {type: ""})
    return file
  }


  const createDecoder = () => {
    props.setLoading(true)
    const formData = new FormData();
    formData.append('decoderfile', selectedDecoderScript);
    formData.append('encoderfile', selectedEncoderScript)
    formData.append('codecname', name);

    fetch(
      `${environment.host}/core/codec`, {
        method: "POST",
        body: formData
      }).then(
      response => response.json()
    ).then(
      data => {

        props.setToast(true)
        if (data.message == 'Success') {
          props.setLoading(false)
          props.getAllCodec()
          props.setReqSuccess(true)
          props.setErrorMessage('Codec Added Successfully')
          props.onClose(true)
          closePopup()

        } else {
          props.setLoading(false)
          props.setReqSuccess(false)
          props.setErrorMessage(data.message)
          setTimeout(() => {

            props.setToast(false);

          }, 4000);
        }


      })
  }

  const updateDecoder = () => {
    props.setLoading(true)
    const formData = new FormData();
    formData.append('decoderfile', props.type === 'update' && selectedDecoderScript == null ? changeFile(props.decoderScript, props.currentDecoderFileName) : selectedDecoderScript);
    formData.append('encoderfile', props.type === 'update' && selectedEncoderScript == null ? changeFile(props.encoderScript, props.currentEncoderFileName) : selectedEncoderScript)
    formData.append('codecname', props.type === 'update' && name == '' ? props.codecName : name);
    fetch(
      environment.host + `/core/codec/` + props.decoderId,
      {
        method: "PUT",
        body: formData
      }).then(
      response => response.json()
    ).then(
      data => {
        const updatedDecoder = data.content
        props.setToast(true)
        if (data.message == 'Success') {
          props.setLoading(false)
          props.getAllCodec()
          props.setReqSuccess(true)
          props.setErrorMessage('Codec Updated Successfully')
          props.onClose(true)
          closePopup()

        } else {
          props.setLoading(false)
          props.setReqSuccess(false)
          props.setErrorMessage(data.message)
          setTimeout(() => {

            props.setToast(false);

          }, 4000);
        }
      })
  }

  const readParameters = () => {

    let error;
    if ((props.type != 'update' && (decoderScript === "" || encoderScript === "" || name == '')) || (props.codecName != name && props.decoderNames.includes(name))) {
      error = true
      if ((props.type != "update" && name === '')) {
        setNameError(true)
      }
      if ((props.type != "update") && (encoderScript === "")) {

        setEncoderFileError(true)
      }
      if ((props.type != "update") && (decoderScript === "")) {
        setDecoderFileError(true)
      }
      if (props.codecName != name && props.decoderNames.includes(name)) {
        setCodecNameExistError(true)
      }
      if (error === true) {
        return
      }
    }

    if (props.type == "update") {
      updateDecoder()
    } else {
      createDecoder()
    }
  }

  useEffect(() => {
  }, [props.type])

  return (
    <Dialog open={props.open} className={classes.newDecoder} maxWidth={"xl"}
            classes={{paper: classes.customDialogPaper}}>
      <Grid container className={classes.header}>
        <Grid item xs={10} md={10}>
          <DialogTitle>{props.type === 'update' ? 'Edit Codec' : 'Create New Codec'}</DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{textAlign: "right"}}>
          <IconButton onClick={() => {
            closePopup()
          }}
                      style={{paddingTop: "20px"}}>
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>

      {/* <Divider /> */}
      {
        <DialogContent style={{margin: "0"}}>
          <Grid container>
            <Grid style={{textAlign: "left", paddingBottom: "20px", margin: "0"}}>
              <Typography className={classes.label}>Codec Name</Typography>
              <input
                type={'text'}
                className={classes.input}
                style={{
                  borderColor: nameError === false ? "#C7C4C4" : primaryRed,
                  textIndent: "10px",
                  border: '0.5px solid'
                }}
                // value={props.type === 'update'?props.codecName:name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError(false);
                  setCodecNameExistError(false);
                }}

                defaultValue={props.type === 'update' ? props.codecName : name}
                placeholder={props.type === 'update' ? '' : "Enter codec name"}
                readOnly={false}
              />
              {nameError === true && <FormHelperText className={classes.helperText}>Name Required *</FormHelperText>}
              {codecNameExistError === true &&
                <FormHelperText className={classes.helperText}>Name Already Exist *</FormHelperText>}
            </Grid>
          </Grid>
          <Grid container>
            <Grid style={{textAlign: "left", paddingBottom: "20px", margin: "0"}}>
              <Typography className={classes.label}>Encoder</Typography>
              <Grid item xs={12} md={12}>
                <Dropzone onDrop={file => readEncoderScript(file)}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}
                           style={{borderColor: encoderFileError === false ? "#427AE5" : primaryRed, padding: "32px"}}
                           className={classes.dragAndDrop}>
                        <input {...getInputProps()} accept=".java"/>
                        <DragDrop/><br/>
                        <Typography style={{textAlign: "center"}}>
                          <span style={{
                            fontSize: "20px",
                            color: "#707070",
                            fontWeight: "500"
                          }}>{props.type == 'update' && selectedEncoderScript == null ? props.currentEncoderFileName : encoderScript === "" ? "Drag & Drop the Script" : encoderScript}</span><br/>
                          <span style={{
                            fontSize: "15px",
                            color: primaryGray
                          }}>{props.type == 'update' || encoderScript != "" ? "" : "or Select the file from device"}</span>
                        </Typography>

                      </div>
                    </section>
                  )}
                </Dropzone>
                {encoderFileError === true &&
                  <FormHelperText className={classes.helperText}>File Required *</FormHelperText>}
              </Grid>

            </Grid>
          </Grid>
          <Grid container>
            <Grid style={{textAlign: "left", paddingBottom: "20px", margin: "0"}}>
              <Typography className={classes.label}>Decoder</Typography>
              <Grid item xs={12} md={12}>
                <Dropzone onDrop={file => readDecoderScript(file)}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}
                           style={{borderColor: decoderFileError === false ? "#427AE5" : primaryRed, padding: "32px"}}
                           className={classes.dragAndDrop}>
                        <input {...getInputProps()} accept=".java"/>
                        <DragDrop/><br/>
                        <Typography style={{textAlign: "center"}}>
                          <span style={{
                            fontSize: "20px",
                            color: "#707070",
                            fontWeight: "500"
                          }}>{props.type == 'update' && selectedDecoderScript == null ? props.currentDecoderFileName : decoderScript === "" ? "Drag & Drop the Script" : decoderScript}</span><br/>
                          <span style={{
                            fontSize: "15px",
                            color: primaryGray
                          }}>{props.type == 'update' || decoderScript != "" ? "" : "or Select the file from device"}</span>
                        </Typography>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {decoderFileError === true &&
                  <FormHelperText className={classes.helperText}>File Required *</FormHelperText>}
              </Grid>

            </Grid>
          </Grid>
        </DialogContent>

      }
      {
        <DialogActions style={{width: "100%"}} hidden>
          <Button variant='contained' className={classes.cancel} onClick={() => closePopup()}>
            Cancel
          </Button>
          <Button variant='contained' className={classes.add}
                  onClick={() => readParameters()}
          >
            {props.type === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      }
      {props.loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
    </Dialog>
  )
}

export default AddNewDecoder

export const Circle = (props) => {
  return (
    <div>
      <div style={{
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
        textAlign: "center",
        paddingTop: "10px",
        boxShadow: "0px 4px 8px #0000001F"
      }}>
        <h5 style={{color: props.active ? primaryBlue : primaryGray}}>{props.number}</h5>
      </div>
    </div>
  )
}

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#666D73">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
    </svg>
  )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    add: {
      backgroundColor: primaryBlue,
      color: "white",
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    cancel: {
      backgroundColor: "white",
      color: primaryGray,
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    helperText: {
      color: "red"
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px"
    },
    input: {
      top: "219px",
      left: "361px",
      width: "717px",
      height: "44px",
      border: "0.5px solid #C7C4C4",
      borderRadius: "4px",
      opacity: 1,
      fontSize: "16px",
      letterSpacing: "0px",
      color: "#464646"
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px"
    },
    newDecoder: {
      top: "106px",
      left: "337px",
      width: "766px",
      height: "749px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      opacity: 1,
    },
    dragAndDrop: {
      top: "316px",
      left: "361px",
      width: "717px",
      height: "200px",
      /* UI Properties */
      background: "#F8F8F8 0% 0% no-repeat padding-box",
      border: "2px dashed #427AE5",
      borderRadius: "5px",
      opacity: 1
    },
    customDialogPaper: {
      margin: "0px"
    },
    header: {
      width: "766px",
      height: "60px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 4px 30px #00000008",
      opacity: 1,
      font: "normal normal medium 18px/27px Poppins",
      letterSpacing: "0px",
      color: "#111111"
    }
  }),
);
