import {Card, Grid, Paper, Typography} from '@material-ui/core';
import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "10px",
  },
  image: {
    width: "50px",
    height: "50px",
    margin: "10px",
    backgroundColor: "#DADADA",
    padding: "10px"
  }
}));

export default function SensorCard(props) {

  const classes = useStyles();

  return (
    <Card elevation={3} className={classes.card}>
      <Grid container>
        <Grid item xs={4} md={4}>
          <Paper elevation={0} className={classes.image}>
            {
              props.name === "Sensor Failure" ? <SensorFailure/>
                :
                props.name === "Active Sensors" ? <ActiveSensors/>
                  :
                  props.name === "Wrong Valued" && <WrongValued/>
            }
          </Paper>
        </Grid>
        <Grid item xs={8} md={8}>
          <Grid container>
            <Grid item xs={12} md={12} style={{textAlign: "left"}}>
              <Typography style={{
                fontWeight: 500,
                fontSize: "12px",
                marginTop: "10px",
                marginLeft: "5px"
              }}>{props.name}</Typography>
            </Grid>
            <Grid item xs={12} md={12} style={{textAlign: "left"}}>
              <Typography style={{
                fontWeight: 700,
                fontSize: "18px",
                marginTop: "5px",
                marginLeft: "5px"
              }}>{props.value}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}


export const SensorFailure = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.14002 8.96C7.05001 9.29 7.00001 9.64 7.00001 10C7.00001 11.1 7.45002 12.1 8.17001 12.83L6.75001 14.25C5.67001 13.16 5.00001 11.66 5.00001 10C5.00001 9.07 5.21001 8.2 5.58001 7.41L4.11001 5.94C3.40001 7.13 3.00001 8.52 3.00001 10C3.00001 12.21 3.90001 14.21 5.35001 15.65L3.93001 17.07C2.12001 15.26 1.00001 12.76 1.00001 10C1.00001 7.96 1.61001 6.07 2.66001 4.49L0.390015 2.22L1.80001 0.809998L20.18 19.19L18.77 20.6L7.14002 8.96ZM16.42 12.59C16.79 11.8 17 10.93 17 10C17 8.34 16.33 6.84 15.24 5.76L13.82 7.18C14.55 7.9 15 8.9 15 10C15 10.36 14.95 10.71 14.86 11.04L16.42 12.59ZM19 10C19 11.48 18.6 12.87 17.89 14.06L19.34 15.51C20.39 13.93 21 12.04 21 10C21 7.24 19.88 4.74 18.07 2.93L16.65 4.35C18.1 5.79 19 7.79 19 10Z"
        fill="black"/>
    </svg>
  )
}

export const ActiveSensors = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 39 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.55 20.42C9.50625 18.53 8.25 15.905 8.25 13C8.25 10.095 9.50625 7.47 11.55 5.58L14.2125 8.065C12.8438 9.325 12 11.075 12 13C12 14.925 12.8438 16.675 14.1938 17.9525L11.55 20.42ZM27.45 20.42C29.4937 18.53 30.75 15.905 30.75 13C30.75 10.095 29.4937 7.47 27.45 5.58L24.7875 8.065C26.1562 9.325 27 11.075 27 13C27 14.925 26.1562 16.675 24.8062 17.9525L27.45 20.42ZM19.5 9.5C17.4375 9.5 15.75 11.075 15.75 13C15.75 14.925 17.4375 16.5 19.5 16.5C21.5625 16.5 23.25 14.925 23.25 13C23.25 11.075 21.5625 9.5 19.5 9.5ZM34.5 13C34.5 16.8675 32.8125 20.3675 30.0938 22.8875L32.7562 25.3725C36.15 22.205 38.25 17.83 38.25 13C38.25 8.17 36.15 3.795 32.7562 0.627502L30.0938 3.1125C32.8125 5.6325 34.5 9.1325 34.5 13ZM8.90625 3.1125L6.24375 0.627502C2.85 3.795 0.75 8.17 0.75 13C0.75 17.83 2.85 22.205 6.24375 25.3725L8.90625 22.8875C6.1875 20.3675 4.5 16.8675 4.5 13C4.5 9.1325 6.1875 5.6325 8.90625 3.1125Z"
        fill="black"/>
    </svg>
  )
}

export const WrongValued = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5513 26.7963C9.55459 24.8105 8.32727 22.0523 8.32727 19C8.32727 15.9477 9.55459 13.1895 11.5513 11.2037L14.1525 13.8147C12.8152 15.1386 11.9909 16.9774 11.9909 19C11.9909 21.0226 12.8152 22.8614 14.1341 24.2037L11.5513 26.7963ZM27.0851 26.7963C29.0818 24.8105 30.3091 22.0523 30.3091 19C30.3091 15.9477 29.0818 13.1895 27.0851 11.2037L24.4839 13.8147C25.8211 15.1386 26.6455 16.9774 26.6455 19C26.6455 21.0226 25.8211 22.8614 24.5022 24.2037L27.0851 26.7963ZM33.9727 19C33.9727 23.0637 32.3241 26.7412 29.668 29.389L32.2691 32C35.5847 28.6719 37.6364 24.075 37.6364 19C37.6364 13.925 35.5847 9.32815 32.2691 6L29.668 8.61103C32.3241 11.2588 33.9727 14.9364 33.9727 19ZM8.96841 8.61103L6.36723 6C3.05164 9.32815 1 13.925 1 19C1 24.075 3.05164 28.6719 6.36723 32L8.96841 29.389C6.31227 26.7412 4.66364 23.0637 4.66364 19C4.66364 14.9364 6.31227 11.2588 8.96841 8.61103Z"
        fill="black"/>
      <path
        d="M21.0909 17.1125L20.6148 16.6364L18.7273 18.5239L16.8398 16.6364L16.3636 17.1125L18.2512 19L16.3636 20.8875L16.8398 21.3636L18.7273 19.4761L20.6148 21.3636L21.0909 20.8875L19.2034 19L21.0909 17.1125Z"
        fill="black" stroke="black"/>
    </svg>

  )
}
