import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HeaderComponent} from './layouts/header/header.component';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {LoginComponent} from './authentication/login/login.component';
import {SignUpComponent} from './authentication/sign-up/sign-up.component';
import {ForgetPasswordComponent} from './authentication/forget-password/forget-password.component';
import {ResetPasswordComponent} from './authentication/reset-password/reset-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {SideMenuComponent} from './layouts/side-menu/side-menu.component';
import {KitboardComponent} from './kitboard/kitboard.component';
import {KitmodelboardComponent} from './kitmodelboard/kitmodelboard.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import {KitFormComponent} from './forms/kit-form/kit-form.component';
import {KitModelFormComponent} from './forms/kit-model-form/kit-model-form.component';
import {DeviceViewComponent} from './device-view/device-view.component';
import {registerLocaleData} from '@angular/common';
import localeLk from '@angular/common/locales/ta-LK';
import {DeviceDataComponent} from './device-data/device-data.component';
import {GraphComparableComponent} from './components/graph-comparable/graph-comparable.component';
import {AuthInterceptor} from './authentication/authInterceptor';
import {KitHistoryComponent} from './kit-history/kit-history.component';
import {PermissionDirective} from './permission.directive';
import {WifiConfigComponent} from './wifi-config/wifi-config.component';
import {MyComponentWrapperComponent} from '../components/my-react-component/MyReactComponentWrapper';
import {TestingAutomationComponentWrapper} from '../components/testing-automation/TestingAutomationComponentWrapper'
import {ProductTypComponentWrapper} from 'src/components/product-types/ProductTypesComponentWrapper';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OTAManagerComponentWrapper} from './otamanager/OTAManagerComponentWrapper';
import {RemoteManagerComponentWrapper} from './remotemanager/RemoteManagerComponentWrapper';
import {DeviceConnectivityComponent} from './device-connectivity/device-connectivity.component';
import {AssignedDeviceComponent} from './assigned-device/assigned-device.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {environment} from '../environments/environment';
import {ClipboardModule} from 'ngx-clipboard';
import {DecodersComponentWrapper} from 'src/components/decoders/DecodersComponentWrapper';
import {DeviceTroubleshotComponent} from './device-troubleshot/device-troubleshot.component';

registerLocaleData(localeLk, 'lk');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    SignUpComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    SideMenuComponent,
    KitboardComponent,
    KitmodelboardComponent,
    KitFormComponent,
    KitModelFormComponent,
    DeviceViewComponent,
    DeviceDataComponent,
    GraphComparableComponent,
    KitHistoryComponent,
    PermissionDirective,
    WifiConfigComponent,
    MyComponentWrapperComponent,
    TestingAutomationComponentWrapper,
    ProductTypComponentWrapper,
    OTAManagerComponentWrapper,
    RemoteManagerComponentWrapper,
    DeviceConnectivityComponent,
    AssignedDeviceComponent,
    DecodersComponentWrapper,
    DeviceTroubleshotComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({timeOut: 1000, preventDuplicates: true, positionClass: 'toast-bottom-right'}),
    NgxPaginationModule,
    DateRangePickerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    ClipboardModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
