import {
  Button,
  Dialog,
  DialogActions,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useRef, useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {CloseIcon} from "../CloseButton";
import {primaryBlue, primaryGray, textBarColor,} from "../colors/colors";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DropDown from "../DropDown";
import {ExpandIcon} from "../../product-types/add-new-firmware/steps/Step2";
import {environment} from "src/environments/environment";
import {makeStyles,} from "@material-ui/core/styles";
import {TextBar} from "../TextBar";
import DeviceSettingUpdatePopup from "../EditConfigureDevice/DeviceSettingUpdatePopup";
import {BootstrapInput} from "src/components/sub-components/DropDown";
// import { setPreviousOrParentTNode } from "@angular/core/src/render3/state";

export default function ConfigureDevice(props) {
  const [openIcon1, setOpenIcon1] = useState(false);
  const [openIcon2, setOpenIcon2] = useState(false);
  const [openIcon3, setOpenIcon3] = useState(false);

  // Configure device Variables
  const [productType, setProductType] = useState("");
  const [version, setVersion] = useState("");
  const [deviceCategory, setDeviceCategory] = useState("");
  const [deviceId, setDeviceId] = useState("");

  // ErrorMessage
  const [productTypeError, setProdctTypeError] = useState(false);
  const [versionError, setVersionError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [deviceIdsError, setDeviceIdsError] = useState(false);

  const [updateMsgOpen, setUpdateMsgOpen] = useState(false);
  const contentContainerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const parameterOrder = ["host", "password", "apn", "port", "username"];
  const [deviceIds, setDeviceIds] = useState([]);
  const [detailsArray, setDetailsArray] = useState([]);
  const [deviceData, setDeviceData] = useState([]);

  const [parametersToEdit, setParametersToEdit] = useState([]);
  const [showValidationAlert, setShowValidationAlert] = useState(false);

  const readDeviceData = (devices) => {
    if (devices.length === 0) {
      setOpen(true);
      setDeviceData([]);
      setProductType("");
      setDeviceCategory("");
      setVersion("");
      setDeviceIds([]);
      setDetailsArray([]);

      setTimeout(() => {
        setOpen(false);
      }, 6000);

      return;
    }
    setDeviceData(devices);
    const modifiedMatchingDevices = [...devices];
    const firstDevice = modifiedMatchingDevices[0];

    const modifiedRemoteConfigurations =
      firstDevice.deviceParameterConfiguration.remoteConfigurations.map(
        (remoteConfiguration) => {
          const modifiedDefaultValues =
            remoteConfiguration.defaultValues !== null
              ? [
                ...remoteConfiguration.defaultValues,
                remoteConfiguration.defaultValue,
              ]
              : null;

          return {
            ...remoteConfiguration,
            defaultValues: modifiedDefaultValues,
            defaultValue: " ",
          };
        }
      );

    const sortedDetailsArray = modifiedRemoteConfigurations
      .sort((a, b) => a.id - b.id);


    setDetailsArray(sortedDetailsArray);
  };

  const handleProductType = (productType) => {
    setProductType(productType);
  };
  const handleVersion = (version) => {
    setVersion(version);
  };
  const handleDeviceCategory = (deviceCategory) => {
    setDeviceCategory(deviceCategory);
  };
  const handleDeviceId = (id) => {
    setDeviceId(id);
  };

  const handleSaveClick = () => {
    const updatedDevices = [];
    const networkAndCommunicationParams = {};
    const topicFormatParams = {};
    const messageFormatParams = {};

    const updatedFieldsArray = [];

    detailsArray.forEach((item) => {
      if (item.checkUpdate === true) {
        switch (item.parameterCategory) {
          case "Network & Communication":
            networkAndCommunicationParams[item.parameter] = item.defaultValue;
            break;
          case "Topic Format & Interval":
            topicFormatParams[item.parameter] = item.defaultValue;
            break;
          case "Message Format":
            messageFormatParams[item.parameter] = item.defaultValue;
            break;
          default:
            break;
        }
        const updatedFields = {};
        for (const field of Object.keys(item)) {
          if (field !== "checkUpdate") {
            if (field === "defaultValues" && item.defaultValues) {
              updatedFields["defaultValues"] = item.defaultValues.filter(
                (value) => value !== item.defaultValue
              );
            } else {
              updatedFields[field] = item[field];
            }
          }
        }
        updatedFieldsArray.push(updatedFields);
      }
    });

    const updateHistory = {
      actionBy: localStorage.getItem("USERNAME"),
      networkAndCommiunication: networkAndCommunicationParams,
      topicFormat: topicFormatParams,
      messageFormat: messageFormatParams,
    };

    deviceIds.forEach((id) => {
      const filteredDevice = deviceData.find((device) => device.id === id);

      if (filteredDevice) {
        const updatedRemoteConfigurations =
          filteredDevice.deviceParameterConfiguration.remoteConfigurations.map(
            (remoteConfig) => {
              const correspondingDetail = updatedFieldsArray.find(
                (detail) => detail.parameter === remoteConfig.parameter
              );

              if (correspondingDetail) {
                return {
                  ...remoteConfig,
                  ...correspondingDetail,
                };
              } else {
                return remoteConfig;
              }
            }
          );

        updatedDevices.push({
          device: filteredDevice.id,
          productType,
          versionNum: version,
          deviceCategory,
          remoteConfigurations: updatedRemoteConfigurations,
          updateHistory: [updateHistory],
        });
      }
    });

    fetch(`${environment.host}/core/device/deviceParameterConfiguration/bulk`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
      },
      body: JSON.stringify(updatedDevices),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (
          (Object.keys(networkAndCommunicationParams).length > 0 ||
            Object.keys(topicFormatParams).length > 0 ||
            Object.keys(messageFormatParams).length > 0) &&
          responseData.message === "Success"
        ) {
          setUpdateMsgOpen(true);
        }

      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateDetailsArray = (prevState, index, value) => {
    const parameterToUpdate = prevState[index].parameter;
    const updatedParamsToEdit = parametersToEdit.filter(
      (param) => param !== parameterToUpdate
    );

    setParametersToEdit(updatedParamsToEdit);

    return prevState.map((detail, i) =>
      i === index
        ? {...detail, defaultValue: value, checkUpdate: true}
        : detail
    );
  };

  const updateParamsToAdd = (detailsArray) => {
    return detailsArray
      .filter((item) => item.checkUpdate)
      .reduce((accumulator, item) => {
        const params = item.joinParameter
          .map((param) => {
            const matchingDetail = detailsArray.find((detail) => detail.id === param);
            return matchingDetail && !matchingDetail.checkUpdate ? matchingDetail.parameter : null;
          })
          .filter((param) => param !== null);
        return accumulator.concat(params);
      }, []);
  };

  const handleInputChange = (event, index) => {
    const {value} = event.target;
    setDetailsArray((prevState) => {
      const updatedDetailsArray = updateDetailsArray(prevState, index, value);
      const updatedParamsToAdd = updateParamsToAdd(updatedDetailsArray);
      setParametersToEdit((prevParams) => [
        ...new Set([...prevParams, ...updatedParamsToAdd]),
      ]);

      const parameterWithId8 = updatedDetailsArray.find((item) => item.id == 8);
      const parameterWithId7 = updatedDetailsArray.find((item) => item.id == 7);


      if (
        parameterWithId8 &&
        parameterWithId7 &&
        parameterWithId7.defaultValue !== 0
      ) {
        const isMultiple =
          parameterWithId8.defaultValue % parameterWithId7.defaultValue === 0;

        if (!isMultiple) {
          setShowValidationAlert(true);
        } else {
          setShowValidationAlert(false);
        }
      }
      return updatedDetailsArray;
    });
  };

  const handleSelectChange = (event, index) => {
    const {value} = event.target;
    setDetailsArray((prevState) => {
      const updatedDetailsArray = prevState.map((detail, i) => {
        if (i === index) {
          const updatedDefaultValues = [...detail.defaultValues];

          return {
            ...detail,
            defaultValue: value,
            defaultValues: updatedDefaultValues,
            checkUpdate: true,
          };
        } else {
          return detail;
        }
      });
      const updatedParamsToAdd = updateParamsToAdd(updatedDetailsArray);
      setParametersToEdit((prevParams) => [
        ...new Set([...prevParams, ...updatedParamsToAdd]),
      ]);
      return updatedDetailsArray;
    });
  };


  const readDevices = (devices) => {
    setDeviceIds(devices);
  };

  const removeDevice = (value) => {
    setDeviceIds(deviceIds.filter((d) => d !== value));
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: "20px",
    },
    label: {
      color: primaryGray,
      fontSize: "14px",
      lineHeight: "21px",
      opacity: "1",
      width: "90px",
      height: "44px",
      display: "flex",
      alignItems: "center",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 2,
      width: "100%",
    },
    textBarStyle: {
      width: "252px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    textBarMessageStyle: {
      width: "219px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    labelMessageStyle: {
      fontSize: "14px",
      color: primaryGray,
      paddingBottom: "5px",
      width: "121px",
    },
    select: {
      width: "252px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: textBarColor,
      height: "44px",
      padding: "4px",
      fontSize: "14px",
    },
    menuItem: {
      fontSize: "14px",
      color: primaryGray,
      opacity: "1",
    },
    input: {
      width: "252px",
      height: "40px",
      backgroundColor: "#F4F5F7",
      color: primaryGray,
    }
  }));


  const classes = useStyles();

  const handleClose = () => {
    setProductType("");
    setVersion("");
    setDeviceIds([]);
    setDeviceCategory("");
    setUpdateMsgOpen(false);
    setOpenIcon1(false);
    setOpenIcon2(false);
    setVersionError(false);
    setProdctTypeError(false);
    setCategoryError(false);
    setDeviceIdsError(false);
  };

  useEffect(() => {
    const isProductTypeEmpty = productType === "";
    const isCategoryEmpty = productType && deviceCategory === "";
    const isVersionEmpty = productType && deviceCategory && version === "";
    const isDeviceIdsEmpty =
      productType && deviceCategory && version && deviceIds.length === 0;

    setProdctTypeError(isProductTypeEmpty);
    setCategoryError(isCategoryEmpty);
    setVersionError(isVersionEmpty);
    setDeviceIdsError(isDeviceIdsEmpty);
  }, [productType, deviceCategory, version, deviceIds]);

  useEffect(() => {
    if (updateMsgOpen) {
      contentContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [updateMsgOpen]);

  return (
    <Dialog
      fullWidth={true}
      open={props.open}
      onClose={props.onClose}
      maxWidth={"md"}
      style={{width: "766px", height: "591px"}}
    >
      <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          opacity: "1",
          boxShadow: "0px 4px 30px #00000008",
        }}
      >
        <Grid item xs={10} md={10}>
          <DialogTitle
            style={{
              fontFamily: "sans-serif",
              fontSize: "16px",
              lineHeight: "25px",
            }}
          >
            Configure Device
          </DialogTitle>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          style={{display: "flex", justifyContent: "center"}}
        >
          <IconButton
            onClick={() => {
              handleClose();
              props.onClose();
            }}
          >
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Divider/>
      <DialogContent style={{width: "100%"}}>
        <Grid container>
          {!updateMsgOpen && (
            <DropDown
              handleProductType={(productType) =>
                handleProductType(productType)
              }
              handleVersion={(version) => handleVersion(version)}
              handleDeviceCategory={(category) =>
                handleDeviceCategory(category)
              }
              handleDeviceId={(id) => handleDeviceId(id)}
              defaultProductType={productType}
              defaultDeviceCategory={deviceCategory}
              defaultDeviceId={deviceId}
              defaultVersion={version}
              readDevices={(devices) => readDevices(devices)}
              removeDevice={(device) => removeDevice(device)}
              defaultDevices={deviceIds}
              productTypeError={productTypeError}
              categoryError={categoryError}
              versionError={versionError}
              deviceIdsError={deviceIdsError}
              readDeviceData={(array) => readDeviceData(array)}
            />
          )}
          {updateMsgOpen && (
            <div ref={contentContainerRef}>
              <DeviceSettingUpdatePopup
                ids={deviceIds}
                productType={productType}
                detailsArray={detailsArray}
                onClose={() => {
                  setUpdateMsgOpen(false);
                }}
              />
            </div>
          )}
          <Grid
            container
            className={classes.container}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Grid item xs={4} md={4}>
              <FormLabel
                style={{
                  color: primaryGray,
                  opacity: "1",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Network & Communication
              </FormLabel>
            </Grid>
            <Grid item xs={7} md={7}>
              <Divider className={classes.divider}/>
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  backgroundColor: "ButtonShadow",
                  borderRadius: "80%",
                  boxShadow: "0px 3px 6px #00000029",
                  padding: "3px",
                }}
                onClick={() => setOpenIcon1(!openIcon1)}
              >
                <ExpandIcon rotation={openIcon1}/>
              </IconButton>
            </Grid>
          </Grid>
          {/* /////////////////////////////////////////////////////////////////// */}

          {openIcon1 === true && (
            <Grid
              container
              className={classes.container}
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {detailsArray.map((item, id) => {
                if (item.parameterCategory === "Network & Communication") {
                  let combinedValues = [];

                  if (item.defaultValues && item.defaultValues.length > 0) {
                    combinedValues = item.defaultValues;
                  }
                  combinedValues = combinedValues.filter(
                    (value) => value !== " "
                  );

                  return (
                    <Grid
                      key={id}
                      item
                      xs={6}
                      md={6}
                      style={{
                        textAlign: "left",
                        marginBottom: "22px",
                        paddingRight: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "89px",
                          height: "44px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.parameter.charAt(0).toUpperCase() +
                          item.parameter.slice(1)}
                      </Typography>
                      {item.defaultValues !== null &&
                      item.defaultValues.length > 0 ? (
                        <Select
                          id="demo-customized-select-native"
                          className={classes.input}
                          value={item.defaultValue}
                          onChange={(event) => handleSelectChange(event, id)}
                          input={<BootstrapInput/>}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem className={classes.menuItem} value=" ">
                            Select {item.parameter}
                          </MenuItem>

                          {combinedValues.map((value, index) => (
                            <MenuItem
                              className={classes.menuItem}
                              key={index}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextBar
                          style={classes.textBarStyle}
                          defaultValue={item.defaultValue}
                          error={{error: false}}
                          onChange={(event) => handleInputChange(event, id)}
                        />
                      )}
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}

          <Grid
            container
            className={classes.container}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Grid item xs={4} md={4}>
              <FormLabel
                style={{
                  color: primaryGray,
                  opacity: "1",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Topic Format & Interval
              </FormLabel>
            </Grid>
            <Grid item xs={7} md={7}>
              <Divider className={classes.divider}/>
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  backgroundColor: "ButtonShadow",
                  borderRadius: "80%",
                  boxShadow: "0px 3px 6px #00000029",
                  padding: "3px",
                }}
                onClick={() => setOpenIcon2(!openIcon2)}
              >
                <ExpandIcon rotation={openIcon2}/>
              </IconButton>
            </Grid>
          </Grid>
          {/* //////////////////////////////////////////////////// */}

          {openIcon2 === true && (
            <Grid container className={classes.container}>
              {detailsArray.map((item, id) => {
                if (item.parameterCategory === "Topic Format & Interval") {
                  let combinedValues = [];
                  if (item.defaultValues && item.defaultValues.length > 0) {
                    combinedValues = [item.defaultValue, ...item.defaultValues];
                  }

                  return (
                    <Grid
                      key={id}
                      item
                      xs={6}
                      md={6}
                      style={{
                        textAlign: "left",
                        marginBottom: "22px",
                        paddingRight: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "89px",
                          height: "44px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.parameter.charAt(0).toUpperCase() +
                          item.parameter.slice(1)}
                      </Typography>
                      {item.defaultValues !== null &&
                      item.defaultValues.length > 0 ? (
                        <Select
                          id="demo-customized-select-native"
                          className={classes.input}
                          value={item.defaultValue}
                          onChange={(event) => handleSelectChange(event, id)}
                          input={<BootstrapInput/>}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem className={classes.menuItem} value=" ">
                            Select {item.parameter}
                          </MenuItem>

                          {combinedValues.map((value, index) => (
                            <MenuItem
                              className={classes.menuItem}
                              key={index}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextBar
                          style={classes.textBarStyle}
                          defaultValue={item.defaultValue}
                          error={{error: false}}
                          onChange={(event) => handleInputChange(event, id)}
                        />
                      )}
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}

          <Grid
            container
            className={classes.container}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Grid item xs={4} md={4}>
              <FormLabel
                style={{
                  color: primaryGray,
                  opacity: "1",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Message Format
              </FormLabel>
            </Grid>
            <Grid item xs={7} md={7}>
              <Divider className={classes.divider}/>
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  backgroundColor: "ButtonShadow",
                  borderRadius: "80%",
                  boxShadow: "0px 3px 6px #00000029",
                  padding: "3px",
                }}
                onClick={() => setOpenIcon3(!openIcon3)}
              >
                <ExpandIcon rotation={openIcon3}/>
              </IconButton>
            </Grid>
          </Grid>
          {/* //////////////////////////////////////////////////// */}

          {openIcon3 === true && (
            <Grid container className={classes.container}>
              {detailsArray.map((item, id) => {
                if (item.parameterCategory === "Message Format") {
                  let combinedValues = [];
                  if (item.defaultValues && item.defaultValues.length > 0) {
                    combinedValues = [item.defaultValue, ...item.defaultValues];
                  }

                  return (
                    <Grid
                      key={id}
                      item
                      xs={6}
                      md={6}
                      style={{
                        textAlign: "left",
                        marginBottom: "22px",
                        paddingRight: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: primaryGray,
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: "1",
                          width: "89px",
                          height: "44px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.parameter.charAt(0).toUpperCase() +
                          item.parameter.slice(1)}
                      </Typography>
                      {item.defaultValues !== null &&
                      item.defaultValues.length > 0 ? (
                        <Select
                          id="demo-customized-select-native"
                          className={classes.input}
                          value={item.defaultValue}
                          onChange={(event) => handleSelectChange(event, id)}
                          input={<BootstrapInput/>}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem className={classes.menuItem} value=" ">
                            Select {item.parameter}
                          </MenuItem>

                          {combinedValues.map((value, index) => (
                            <MenuItem
                              className={classes.menuItem}
                              key={index}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextBar
                          style={classes.textBarStyle}
                          defaultValue={item.defaultValue}
                          error={{error: false}}
                          onChange={(event) => handleInputChange(event, id)}
                        />
                      )}
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
          <DialogContent
            className={classes.container}
            style={{
              width: "100%",
              paddingBottom: "20px",
              display: "flex",
            }}
          >
            <Grid item xs={12} md={12}>
              <DialogActions
                style={{
                  justifyContent: "end",
                  display: "flex  ",
                }}
              >
                <Button
                  onClick={() => {
                    handleClose();
                    props.onClose();
                  }}
                  style={{
                    marginRight: "10px",
                    width: "30%",
                    border: "none",
                    color: primaryGray,
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: primaryBlue,
                    width: "30%",
                    color: "white",
                  }}
                  disabled={showValidationAlert}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              </DialogActions>
            </Grid>
          </DialogContent>
        </Grid>
      </DialogContent>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}

      >
        <Alert severity="error">
          <h3 style={{textAlign: "left"}}>Error</h3>
          No Matching Devices Found
        </Alert>
      </Snackbar>
      <div>
        {showValidationAlert && (
          <Snackbar
            key="validationAlert"
            open={true}
            autoHideDuration={6000}


          >
            <Alert severity="error">
              {detailsArray.find((item) => item.id == 8).parameter} is not a multiple
              of {detailsArray.find((item) => item.id == 7).parameter}
            </Alert>
          </Snackbar>
        )}

        {parametersToEdit.length > 0 && (
          <Snackbar
            key="parameterEditAlert"
            open={true}
            autoHideDuration={6000}
            onClose={() => setParametersToEdit([])}

          >
            <Alert severity="warning" onClose={() => setParametersToEdit([])}>
              You need to edit the following parameters: {parametersToEdit.join(",")}
            </Alert>
          </Snackbar>
        )}
      </div>
    </Dialog>
  );
}
