import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  isSideNavVisible;

  constructor() {
    this.isSideNavVisible = localStorage.getItem('IS_SIDE_NAV_VISIBLE') === 'true';
  }

  toggleSideNav() {
    this.isSideNavVisible = !this.isSideNavVisible;
    localStorage.setItem('IS_SIDE_NAV_VISIBLE', this.isSideNavVisible);
  }

  hideSideNav() {
    this.isSideNavVisible = false;
    localStorage.setItem('IS_SIDE_NAV_VISIBLE', this.isSideNavVisible);
  }
}

