import React from "react";
import {DialogContent, DialogContentText, Grid, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface DeviceSettingUpdatePopupProps {
  id?: string;
  ids?: string[];
  productType: string;
  detailsArray: {
    parameter: string;
    defaultValue: string;
    checkUpdate: boolean;
  }[];
  onClose: () => void;
}

const DeviceSettingUpdatePopup: React.FC<DeviceSettingUpdatePopupProps> = ({
                                                                             ids,
                                                                             id,
                                                                             productType,
                                                                             detailsArray,
                                                                             onClose,
                                                                           }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "16px", //
            }}
          >
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon/>
            </IconButton>
          </div>
          <DialogContentText
            style={{
              color: "#03C69F",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {id && (
              <p>
                Device ID: {id} -&gt; {productType} updated Successfully with the following new parameters:
              </p>
            )}
            {ids && (
              <p>
                Device IDs: {ids.join(', ')} -&gt; {productType} updated Successfully with the following new parameters:
              </p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          {detailsArray.map((item, index) => {
            if (item.checkUpdate) {
              return (
                <DialogContentText key={index}>
                  {item.parameter} : {item.defaultValue}
                </DialogContentText>
              );
            }
            return null;
          })}
        </DialogContent>
      </Grid>
    </Grid>
  );
};

export default DeviceSettingUpdatePopup;
