import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import {FormControl, Grid, Typography} from "@material-ui/core"
import {createStyles, createTheme, makeStyles, Theme, ThemeProvider, withStyles,} from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';
import {Devices} from './devices/devices';
import {Sensors} from './sensors/sensors';
import {Communication} from './communication/communication';
import {environment} from 'src/environments/environment';
import Battery from './battery/battery';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  text: {
    marginTop: "8px",
    fontStyle: "normal",
    fontSize: "16px"
  }
}));

export const MyReactComponent: FunctionComponent = (props) => {

  const [coperate, setCoperate] = useState([])
  const [selectedCoperate, setSelectedCoperate] = useState("")
  const [batteyData, setBatteryData] = useState([])
  const [communicationData, setCommunicationData] = useState({})
  const classes = useStyles()

  const changeCoperate = (e) => {
    setSelectedCoperate(e.target.value)
    const userId = localStorage.getItem('USER_ID')
    fetch(`${environment.host}/user/${userId}/corporate/${e.target.value}/deviceSummary?favouriteFilter=${false}`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      }).then(response => response.json())
      .then(data => {
        setBatteryData([
          data.content.directPowerDeviceCount,
          data.content.onBatteryDeviceCount,
          data.content.batteryDeadDeviceCount,
          data.content.onSolarPowerDeviceCount
        ])
        setCommunicationData(
          {
            offline: data.content.offlineDeviceCount,
            online: data.content.onlineDeviceCount
          }
        )
      })
      .catch((err) => {
      });
  }

  React.useEffect(() => {
    const userId = localStorage.getItem('USER_ID')
    fetch(`${environment.host}/user/${userId}/corporates`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      }).then(response => response.json())
      .then(data => {
        setSelectedCoperate(data.content[0].id)
        setCoperate(data.content)
        return data
      })
      .then((data) => {
        fetch(`${environment.host}/user/${userId}/corporate/${data.content[0].id}/deviceSummary?favouriteFilter=${false}`,
          {
            method: "GET",
            headers: {'Content-Type': 'application/json'}
          }).then(response => response.json())
          .then(data => {
            setBatteryData([
              data.content.directPowerDeviceCount,
              data.content.onBatteryDeviceCount,
              data.content.batteryDeadDeviceCount,
              data.content.onSolarPowerDeviceCount
            ])
            setCommunicationData(
              {
                offline: data.content.offlineDeviceCount,
                online: data.content.onlineDeviceCount
              }
            )
          })
          .catch((err) => {
          });
      })
      .catch((err) => {
      });
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className={`my-graph-component`}>
        <Grid container>
          <Grid item xs={1} md={4}>
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid container>
              <Grid item xs={6} md={5}>
                <Typography className={classes.text}>
                  Coperate Selection
                </Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                <FormControl className={classes.input}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={selectedCoperate}
                    className={classes.input}
                    onChange={changeCoperate}
                    input={<BootstrapInput/>}
                  >
                    {
                      coperate !== undefined && coperate.map((cop, index) => {
                        return (
                          <option value={cop.id} key={index}>{cop.name}</option>
                        )
                      })
                    }
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={4}>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={5}>
            <Devices coperateId={selectedCoperate}/>
          </Grid>
          <Grid item xs={12} md={5}>
            <Sensors coperateId={selectedCoperate}/>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={5}>
            <Communication comminucationData={communicationData} coperateId={selectedCoperate}/>
          </Grid>
          <Grid item xs={12} md={5}>
            <Battery batteryData={batteyData} coperateId={selectedCoperate}/>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </div>
    </ThemeProvider>
  )
};

//Bootstrap like dropdown component
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: "#F4F5F7",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Poppins',

        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

//Theme
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F4F5F7",
    },
    primary: {
      main: "#2A7CED"
    }
  },
  typography: {
    fontFamily: "Poppins', sans-serif"
  }
});

// refs

// https://thalava.com/how-to-use-react-web-components-in-angular
// https://medium.com/@zacky_14189/embedding-react-components-in-angular-the-easy-way-60f796b68aef
