import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {CloseIcon} from "../CloseButton";
import {ViewIcon} from "src/components/product-types/add-new-firmware/steps/Step2";
import {filterTitle, primaryBlue, seconderyTextColor, white,} from "../colors/colors";

const HistoryDevice = (props) => {
  const [device, setDevice] = useState(props.device.device);
  const [history, setHistory] = useState(
    props.device.device.deviceParameterConfiguration.updateHistory
  );

  console.log("History : ", history);
  useEffect(() => {
  }, []);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    container: {
      paddingTop: "20px",
      paddingRight: "9px",
    },
    versionSelected: {
      backgroundColor: theme.palette.grey[50],
      border: `2px solid #2A7CED`,
      color: "#2A7CED",
      margin: "6",
    },
    version: {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid #707170`,
      color: "#707170",
      margin: "6",
    },
    filter: {
      backgroundColor: white,
      color: seconderyTextColor,
      boxShadow: "none",
    },
    addFirmware: {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: primaryBlue,
        color: white,
        boxShadow: "none",
      },
      [theme.breakpoints.down("md")]: {
        margin: "10px",
      },
    },
    tableText: {
      fontSize: "14px",
      color: "#3C3C3C",
    },
    versionCircleText: {
      fontSize: "10px",
    },
    versionText: {
      fontSize: "14px",
      color: "#2A7CED",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={"md"}
      style={{width: "60%", height: "70%"}}
      BackdropProps={{
        style: {
          backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
          opacity: "0.4",
        },
      }}
      PaperProps={{
        // className: classes.paper,
        style: {
          boxShadow: "inherit",
        },
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          boxShadow: "5px",
        }}
      >
        <Grid
          item
          style={{paddingLeft: "12px", fontSize: "18px"}}
          xs={10}
          md={10}
        >
          <h3
            style={{
              fontWeight: "bold",
              color: filterTitle,
              fontSize: "16px",
              lineHeight: "25px",
            }}
          >
            {device.id} -{" >"}
            {device.deviceParameterConfiguration.productType}
          </h3>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          style={{display: "flex", justifyContent: "end"}}
        >
          <IconButton onClick={props.onClose}>
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Divider/>

      <DialogContent style={{width: "100%"}}>
        <Grid container>
          <Grid item xs={12} md={12} style={{paddingTop: "10px"}}>
            <TableContainer>
              <Table
                size="medium"
                style={{
                  boxShadow: "initial",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{display: "inline-flex"}}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          DATE
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{display: "inline-flex"}}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          ACTION
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{display: "inline-flex"}}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          ACTION BY
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{display: "inline-flex"}}>
                        <Typography
                          display="inline"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          ACTION
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((item, i) => {
                    //todo:implement view history
                    const concatenateArrayElements = (array) => {
                      let result = "";
                      for (let index = 0; index < array.length; index++) {
                        const element = array[index];
                        if (index === array.length - 1) {
                          result += element;
                        } else if (index === array.length - 2) {
                          result += element + " & ";
                        } else {
                          result += element + ", ";
                        }
                      }
                      return result;
                    };


                    const combinedArray = [
                      ...item.networkAndCommiunication,
                      ...item.topicFormat,
                      ...item.messageFormat,
                    ];

                    let formattedString = concatenateArrayElements(combinedArray);
                    console.log(formattedString);

                    return (
                      <TableRow key={i + 1}>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor:
                              i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                          }}
                        >
                          <Typography className={classes.tableText}>
                            {item.updatedDate.split(" ")[0]}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor:
                              i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                          }}
                        >
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className={classes.tableText}
                          >
                            updated-{formattedString}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor:
                              i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                          }}
                        >
                          <Typography className={classes.tableText}>
                            {item.actionBy}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            textAlign: "center",
                            backgroundColor:
                              i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                          }}
                        >
                          <IconButton>
                            <ViewIcon/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                    formattedString = "";
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryDevice;
