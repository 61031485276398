import {
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {environment} from 'src/environments/environment';
import {primaryBlue, seconderyTextColor, white} from '../sub-components/colors/colors';
import {IconDropDown} from '../sub-components/IconDropDown';
import {SearchBar} from '../sub-components/SearchBar';
import {AddNewFirmWare} from './add-new-firmware/AddNewFirmWare';
import {EditIcon, ViewIcon} from './add-new-firmware/steps/Step2';
import {EditProduct} from './edit-product/EditProduct';

export function ProductTypComponent(props) {
  const classes = useStyles()

  const [userId, setUserId] = useState("")
  const [filterFavouriteDevices, setFilterFavouriteDevices] = useState(false)
  const [favoriteDevices, setFavouriteDevices] = useState([])

  const [addNewOpen, setAddNewOpen] = useState(false);
  const [type, setType] = useState("")
  const [edit, setEdit] = useState(false)
  const [editProductName, setEditProductName] = useState("")
  const [editProductId, setEditProductId] = useState("")
  const [search, setSearch] = useState("")

  const [products, setProducts] = useState([])
  const [constProducts, setConstProducts] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [allVersions, setAllVersions] = useState([])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openEdit = (product, id, type) => {
    setType(type)
    setEditProductName(product)
    setEditProductId(id)
    setEdit(true)
  }
  const closeEdit = () => {
    setEdit(false)
  }

  const closeAddNew = () => {
    setAddNewOpen(false)
  }

  //filters
  const allProductFilter = (e) => {
    const productFilter = constProducts.filter((obj) => obj.productType === e.target.value)
    setProducts(productFilter)
    setFilterFavouriteDevices(false)
  }
  const allCategoryFilter = (e) => {
    const categoryFilter = constProducts.filter((obj) => obj.deviceCategory === e.target.value)
    setProducts(categoryFilter)
    setFilterFavouriteDevices(false)
  }
  const allVersionFilter = (e) => {
    let versionsFilter = []
    constProducts.map((obj) => {
      obj.versions.map((v) => {
        if (v.versionNum === e.target.value) {
          versionsFilter.push(obj)
        }
      })
    })
    setProducts(versionsFilter)
    setFilterFavouriteDevices(false)
  }

  const getAllProducts = () => {
    fetch(`http://localhost:9030/core/products`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        setConstProducts(data.content)
        console.log(data.content, "+++++++++++++++++++++++++++")
        setProducts(data.content)
      })
      .catch((err) => {
        setConstProducts([])
        setProducts([])
      })
  }
  const getFavourites = (id) => {
    fetch(`${environment.host}/core/product/${id}/favourite`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        setFavouriteDevices(data.content)
      })
      .catch((err) => {
        setFavouriteDevices([])
      })
  }

  const filterFavourite = () => {
    setFilterFavouriteDevices(!filterFavouriteDevices)
    if (filterFavouriteDevices === false) {
      setProducts(products.filter((obj) => favoriteDevices.includes(obj.id)))
    }
    if (filterFavouriteDevices === true) {
      getAllProducts()
    }
  }

  const addOrRemoveFavourite = (favourite, id) => {
    if (favourite === false) {
      fetch(`${environment.host}/core/product/${userId}/favourite`,
        {
          method: "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify([id])
        })
        .then(response => response.json())
        .then(data => {
          getFavourites(userId)
        })
        .catch((err) => {
          getFavourites(userId)
        })
    }
    if (favourite === true) {
      fetch(`${environment.host}/core/product/${userId}/favourite`,
        {
          method: "DELETE",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify([id])
        })
        .then(response => response.json())
        .then(data => {
          getFavourites(userId)
        })
        .catch((err) => {
          getFavourites(userId)
        })
    }
  }

  useEffect(() => {
    const uId = localStorage.getItem("USER_ID")
    setUserId(uId)
    getAllProducts()

    fetch(`${environment.host}/core/product/configDetails`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        let allProducts = []
        let allCategories = []
        data.content.productTypes[0].map((p) => {
          allProducts.push(p.productType)
          p.deviceCategory.map((c) => {
            allCategories.push(c)
          })
        })
        setAllProducts(allProducts)
        setAllCategories(allCategories)
        setAllVersions(data.content["available versions"][0])
      })
      .catch((err) => {
        setAllProducts([])
        setAllCategories([])
        setAllVersions([])
      })
    getFavourites(uId)
  }, [])
  return (
    <div className={`testing`}>
      {
        edit === false ?
          <Grid container className={classes.container}>
            <Grid item xs={6} md={3}>
              <SearchBar onChange={(e) => setSearch(e.target.value)}/>
            </Grid>
            <Grid item md={7}></Grid>
            <Grid item xs={6} md={2} style={{textAlign: "left", paddingLeft: "10px"}}>
              <Button
                variant='contained'
                onClick={() => setAddNewOpen(true)}
                className={classes.addFirmware}
                style={{textTransform: "none"}}
              >Add Firmware</Button>
            </Grid>
            <Grid container style={{backgroundColor: "white", marginTop: "20px", marginLeft: "10px"}}>
              <Grid item xs={6} md={6} style={{textAlign: "left", paddingLeft: '10px', paddingTop: "5px"}}>
                <Checkbox disabled/>
                <IconButton style={{padding: "0px"}} onClick={filterFavourite}>
                  {filterFavouriteDevices === false ? <OutlinedStar color="#0000008A"/> : <ContainedStar/>}
                </IconButton>
                <IconButton style={{padding: "0px"}} onClick={() => {
                  setProducts([])
                  getAllProducts()
                }}>
                  <Refresh/>
                </IconButton>
                <IconButton style={{padding: "0px"}}>
                  <MenuDots/>
                </IconButton>
              </Grid>
              <Grid item xs={6} md={6}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={products !== undefined && products.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{marginTop: "20px", marginLeft: "10px"}}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{
                        textAlign: "center",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white"
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>PRODUCT NAME</Typography>
                        <IconDropDown onChange={(e) => allProductFilter(e)} data={allProducts}/>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white"
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>DEVICE/NODE CATEGORY</Typography>
                        <IconDropDown onChange={(e) => allCategoryFilter(e)} data={allCategories}/>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white"
                      }}>
                        <Typography display="inline" style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>AVAILABLE VERSIONS</Typography>
                        <IconDropDown onChange={(e) => allVersionFilter(e)} data={allVersions}/>
                        <NewTooltip
                          arrow
                          title={
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={3} md={3} style={{textAlign: "center"}}>
                                  <Chip label="" className={classes.redIndicator}></Chip>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                  <Typography display="inline" style={{fontSize: "12px", color: "#63738C"}}>Indicates
                                    that version is available but not approved, so you can edit any
                                    modifications</Typography>
                                </Grid>
                                <Grid item xs={3} md={3} style={{textAlign: "center", paddingTop: "10px"}}>
                                  <Chip label="" className={classes.greenIndicator}></Chip>
                                </Grid>
                                <Grid item xs={9} md={9} style={{paddingTop: "10px"}}>
                                  <Typography display="inline" style={{fontSize: "12px", color: "#63738C"}}>Indicates
                                    that version is available & released, so you can't edit any
                                    modifications</Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        >
                          <IconButton style={{padding: "0px"}}>
                            <Info/>
                          </IconButton>
                        </NewTooltip>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white"
                      }}>
                        <Typography style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>ACTIONS</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      products !== undefined && products.filter((obj) => {
                        if (search === "") {
                          return obj
                        } else if (obj.productType.toLowerCase().includes(search.trim().toLowerCase())) {
                          return obj
                        }
                      }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, i) => (
                        <TableRow key={i}>
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Checkbox disabled/>
                            <IconButton style={{padding: "0px"}}
                                        onClick={() => addOrRemoveFavourite(favoriteDevices !== undefined && favoriteDevices.includes(product.id), product.id)}>
                              {
                                favoriteDevices !== undefined && favoriteDevices.includes(product.id) ?
                                  <ContainedStar/>
                                  : <OutlinedStar color="#00000029"/>
                              }
                            </IconButton>
                            <Typography display='inline' className={classes.tableText}
                                        style={{paddingLeft: "5px"}}>{product.productType}</Typography>
                          </TableCell>
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography className={classes.tableText}>{product.deviceCategory}</Typography>
                          </TableCell>
                          <TableCell style={{
                            textAlign: "left",
                            width: "40%",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            {
                              product.versions.map((version, j) => (
                                <Chip key={j}
                                      className={version.status === "NOT_APPROVED" ? classes.redChip : classes.greenChip}
                                      label={version.versionNum}/>
                              ))
                            }
                          </TableCell>
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <IconButton style={{padding: "0px"}}
                                        onClick={() => openEdit(product.productType, product.id, "view")}>
                              <ViewIcon/>
                            </IconButton>
                            {
                              product.versions.filter((ver) => ver.status === "NOT_APPROVED").length > 0 &&
                              // Hide edit icon if all versions are approved.
                              <IconButton style={{padding: "0px", paddingLeft: "10px"}}
                                          onClick={() => openEdit(product.productType, product.id, "edit")}>
                                <EditIcon/>
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          : <EditProduct onClose={() => closeEdit()} setEditProductName={editProductName} editProductId={editProductId}
                         type={type}/>
      }


      <AddNewFirmWare open={addNewOpen} onClose={() => closeAddNew()} refresh={() => getAllProducts()}/>
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px"
  },
  filter: {
    backgroundColor: white,
    color: seconderyTextColor,
    boxShadow: "none",
  },
  addFirmware: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    '&:hover': {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none"
    },
    [theme.breakpoints.down('md')]: {
      margin: "10px",
    }
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#FC4141",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px"
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px"
  },

}));

const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px"
  }
})(Tooltip);

export const OutlinedStar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={props.color}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path
        d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
    </svg>
  )
}

export const ContainedStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EFD494">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>
  )
}

export const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.75" height="11.75" viewBox="0 0 11.75 11.75">
      <g id="info_2_" data-name="info (2)" transform="translate(-1.625 -1.625)">
        <circle id="Ellipse_753" data-name="Ellipse 753" cx="5.5" cy="5.5" r="5.5" transform="translate(2 2)"
                fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        <line id="Line_570" data-name="Line 570" y1="3" transform="translate(7.5 6.444)" fill="none" stroke="#707070"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        <line id="Line_571" data-name="Line 571" transform="translate(7.5 4.75)" fill="none" stroke="#707070"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
      </g>
    </svg>
  )
}

export const Refresh = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0000008A">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
    </svg>
  )
}

export const MenuDots = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0000008A">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
    </svg>
  )
}
