import {Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@material-ui/core';
import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Bar} from 'react-chartjs-2';
import {environment} from 'src/environments/environment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "50px",
    padding: "20px",
    borderRadius: "20px"
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: "14px",
      fontWeight: 400
    }
  },
}));

const labels = ['Active', 'Non Active'];


export function Devices(props) {

  const classes = useStyles()
  const [type, setType] = React.useState("favorite")
  const [graphData, setGraphData] = React.useState(
    {
      labels,
      datasets: [
        {
          label: 'Old',
          data: [0, 0],
          backgroundColor: '#ABABAB',
        },
        {
          label: 'New',
          data: [0, 0],
          backgroundColor: '#000000',
        },
      ],
    }
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value);
  };

  React.useEffect(() => {
    const userId = localStorage.getItem('USER_ID')
    let deviceType;
    if (type === "favorite") {
      deviceType = true
    }
    if (type === "all") {
      deviceType = false
    }
    if (props.coperateId !== "") {
      fetch(`${environment.host}/user/${userId}/corporate/${props.coperateId}/deviceSummary?favouriteFilter=${deviceType}`,
        {
          method: "GET",
          headers: {'Content-Type': 'application/json'}
        }).then(response => response.json())
        .then(data => {
          if (data.content) {
            setGraphData(
              {
                labels,
                datasets: [
                  {
                    label: 'Old',
                    data: [data.content.oldActiveDeviceCount, data.content.oldNonActiveDeviceCount],
                    backgroundColor: '#ABABAB',
                  },
                  {
                    label: 'New',
                    data: [data.content.newActiveDeviceCount, data.content.newNonActiveDeviceCount],
                    backgroundColor: '#000000',
                  },
                ],
              }
            )
          } else {
            setGraphData(
              {
                labels,
                datasets: [
                  {
                    label: 'Old',
                    data: [0, 0],
                    backgroundColor: '#ABABAB',
                  },
                  {
                    label: 'New',
                    data: [0, 0],
                    backgroundColor: '#000000',
                  },
                ],
              }
            )
          }
        })
        .catch((err) => {
          alert(err)
        });
    }
  }, [props.coperateId, type])

  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant='h6'>DEVICES</Typography>
          </Grid>
          <Grid item xs={4} md={4} style={{textAlign: "left", marginTop: "20px"}}>
            <Typography style={{fontWeight: 400, fontSize: "14px", marginTop: "10px"}}>Select Device List</Typography>
          </Grid>
          <Grid item xs={4} md={4} style={{textAlign: "left", marginTop: "20px"}}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="favorite" name="favorite" value={type} onChange={handleChange}>
                <FormControlLabel value="favorite" control={<Radio color='primary'/>} className={classes.label}
                                  label="Favorite Devices"/>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={4} style={{textAlign: "left", marginTop: "20px"}}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="all" name="all" value={type} onChange={handleChange}>
                <FormControlLabel value="all" control={<Radio color='primary'/>} className={classes.label}
                                  label="All Devices"/>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} style={{marginTop: "40px"}}>
            <div style={{width: "90%", height: "100%", transition: "0.6"}}>
              <Bar data={graphData} height={350} options={
                {
                  onClick: (event, elements) => {
                    if (elements[0] !== undefined) {
                      const chart = elements[0]._chart;
                      const element = chart.getElementAtEvent(event)[0];
                      const dataset = chart.data.datasets[element._datasetIndex];
                      const xLabel = chart.data.labels[element._index];
                      const value = dataset.data[element._index];
                      const activeStatus = xLabel.replace(/\s/g, '');
                      let favourite;
                      if (type === "favourite") {
                        favourite = true
                      } else {
                        favourite = false
                      }
                      console.log(dataset.label + activeStatus + favourite);
                      location.href = 'devices/' + props.coperateId + '/' + dataset.label + '&' + activeStatus + '/' + favourite
                    }
                  },
                  maintainAspectRatio: false,
                  plugins: {
                    labels: {
                      render: () => {
                      }
                    },
                  },
                  responsive: false,
                  scales: {
                    xAxes: [{
                      gridLines: {
                        display: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Devices'
                      }
                    }],
                    yAxes: [{
                      gridLines: {
                        display: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'No of Devices'
                      },
                      ticks: {
                        beginAtZero: true,
                      },
                    }]
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
