import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../sidenav/sidenav.service';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../account/account.service'

@Component({
  selector: 'app-device-troubleshot',
  templateUrl: './device-troubleshot.component.html',
  styleUrls: ['./device-troubleshot.component.css']
})
export class DeviceTroubleshotComponent implements OnInit {

  constructor(private sideNavService: SideNavService, private accountService: AccountService,
              private router: ActivatedRoute, private spinner: NgxSpinnerService) {
  }

  deviceId;
  error
  deviceArray = []
  rowData
  conectedSensor = []
  connectedActuator = []
  isRawDataError = false
  validity = false
  inputRaw = ''
  inputValidation = false

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.deviceId = params['deviceId'];
    });
    this.spinner.show();

    this.getDeviceData()
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }


  getDeviceData() {
    this.accountService.getDevice(this.deviceId)
      .subscribe(res => {
        this.deviceArray = res.content
        this.rowData = res.content.lastRawData
        this.conectedSensor = res.content.sensorCodes.slice().sort()
        this.connectedActuator = res.content.actuatorCodes.slice().sort()
        this.spinner.hide()
        this.validity = this.deviceRowData(this.rowData)
      }, (e) => {
        if (e.error.errorCode === 'E1011') {
          this.error = 'Device Not Found';
        }
        this.spinner.hide();
      })

  }

  deviceRowData(data) {

    var splitData
    let validation = false
    var splittedSensorArray = []
    var splittedActuatorArray = []
    var finalArray = []
    if (data == undefined) {
      this.isRawDataError = true
    } else {
      console.log(data)
      if (data.includes('*')) {
        splitData = data.split('*')
        splittedSensorArray = splitData[0]
        splittedActuatorArray = splitData[1]
        const sensor = this.splitArray(splittedSensorArray)
        const actuator = this.splitArray(splittedActuatorArray)
        if (splittedSensorArray.includes('*')) {
          validation = true
        } else if (this.conectedSensor.length === sensor.length && JSON.stringify(sensor) === JSON.stringify(this.conectedSensor)
          && this.connectedActuator.length === actuator.length && JSON.stringify(actuator) === JSON.stringify(this.conectedSensor)) {
          validation = true
        } else {
          validation = false
        }
      } else {
        if ((data.startsWith('DT'))) {
          splitData = data.split('|')
          if (splitData[1].endsWith(';')) {
            splitData[1] = splitData[1].slice(0, -1); // Remove the last character
          }
          finalArray = this.splitArray(splitData[1])
          console.log(splitData[1])
        } else {
          if (data.endsWith(';')) {
            data = data.slice(0, -1);
            finalArray = this.splitArray(data) // Remove the last character
          } else {
            finalArray = this.splitArray(data)
          }

        }

      }


      if (this.conectedSensor.length === finalArray.length && JSON.stringify(finalArray) === JSON.stringify(this.conectedSensor)) {
        console.log(finalArray, this.conectedSensor)
        validation = true
      } else {
        validation = false
      }
    }
    return validation
  }

  splitArray(data) {
    var arraySplit = data.split(';')
    console.log(arraySplit)
    const innerSplit = arraySplit.map(item => item.split(/[-:]/))
    console.log(innerSplit)
    return innerSplit.map(item => {
      return item[1]
    }).slice().sort()

  }

  updateInputRaw() {
    this.inputRaw = (document.getElementById('inpurRawData') as HTMLInputElement).value
    // console.log(document.getElementById('inpurRawData').value)
    this.inputValidation = this.deviceRowData(this.inputRaw)
  }


}
