import {
  Button,
  createStyles,
  Dialog,
  FormControl,
  IconButton,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {CloseIcon} from "./CloseButton";
import {filterTitle, primaryBlue, primaryGray, searchBarBorderColor, white,} from "./colors/colors";
import Grid from "@material-ui/core/Grid/Grid";
import Divider from "@material-ui/core/Divider";

export default function FilterBar(props) {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: "20px",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    helperText: {
      color: "red",
    },
  }));
  const classes = useStyles();
  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {
          marginTop: theme.spacing(3),
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        color: primaryGray,
        backgroundColor: "white",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Poppins font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Poppins",

          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: 4,
          borderColor: searchBarBorderColor,
          backgroundColor: "white",
        },
      },
    })
  )(InputBase);

  const SelectSection = (props) => {
    return (
      <Grid
        item
        xs={6}
        md={6}
        style={{
          textAlign: "left",
          marginBottom: "20px",
          backgroundColor: "transparent",
          paddingLeft: "10px",
        }}
      >
        <Typography className={classes.label}>{props.title}</Typography>
        <FormControl className={classes.input}>
          <Select
            id="demo-customized-select-native"
            className={classes.input}
            input={<BootstrapInput/>}
            onChange={(e) => {
              props.onChangeHandle(e.target.value);
            }}
            value={props.value}
            MenuProps={{
              anchorOrigin: {vertical: "bottom", horizontal: "left"},
              transformOrigin: {vertical: "top", horizontal: "left"},
              getContentAnchorEl: null,
            }}
          >
            <MenuItem
              value="ALL"
              style={{color: primaryGray}}
              selected={true}
            >
              Select {props.title}
            </MenuItem>
            {props.array.map((productType, i) => (
              <MenuItem
                value={productType}
                key={i}
                style={{color: primaryGray}}
              >
                {productType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open}
      onClose={handleClose}
    >
      <Grid container>
        <Grid
          item
          xs={10}
          md={10}
          style={{display: "flex", justifyContent: "start"}}
        >
          <DialogTitle
            style={{
              color: filterTitle,
              fontFamily: "sans-serif",
            }}
          >
            Filter
          </DialogTitle>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          style={{display: "flex", justifyContent: "end"}}
        >
          <IconButton onClick={props.onClose}>
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Divider/>
      <DialogContent style={{width: "100%"}}>
        <Grid container style={{display: "flex", justifyContent: "center"}}>
          <SelectSection
            title="Product Type"
            array={props.productOptions}
            value={props.productType}
            onChangeHandle={props.setProductType}
          />
          <SelectSection
            title="Update Status"
            array={props.updateOptions}
            value={props.update}
            onChangeHandle={props.setUpdate}
          />
        </Grid>
      </DialogContent>
      <DialogContent style={{width: "100%"}}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "right"}}>
            <Button
              style={{
                marginRight: "10px",
                width: "30%",
                border: "none",
                color: primaryGray,
              }}
              variant="outlined"
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: primaryBlue,
                color: "white",
                width: "30%",
              }}
              onClick={() => {
                props.handleFilter(props.productType, props.update);
                props.onClose();
              }}
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
