import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../../account/account.service';
import {ToastrService} from 'ngx-toastr';
import {Device, FORM_TYPE, FormKitRadioFields, Kit, KitModel, MetaData} from '../../models';
import {AuthService} from '../../authentication/auth.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-kit-form',
  templateUrl: './kit-form.component.html',
  styleUrls: ['./kit-form.component.css']
})
export class KitFormComponent implements OnInit {
  @Input() kit: Kit;
  @Input() formType;
  @Input() isCloning;
  @Output() getKitTrigger = new EventEmitter();
  @Output() hideFormTrigger = new EventEmitter();

  isSubmitted: boolean;
  error;
  formKit: FormGroup;
  formKitRadioFields: FormKitRadioFields = {maintain: false, persistence: true};
  devicesOfKit: Device[] = [];
  devices: Device [];
  devicesAll: Device [];
  kitModels: KitModel[];
  showKitModelForm = false;
  selectedKitModel;
  propertiesOfKitModel: string [];
  sensors = [];
  formTypeVar = FORM_TYPE.EDIT;
  isCloningVar = false;
  metaData: MetaData;
  searchTerm = '';
  kitModelLocalSelected;
  selectedPropIndex: any;
  propertyObj = {};
  objectOfProperty: any;
  propertyCalMethod = ['ADD', 'SUB', 'MUL', 'DIV'];

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private accountService: AccountService, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.formKit = this.formBuilder.group({
      id: [null, [Validators.required, Validators.pattern('^[^.$\\[\\]#]*$')]],
      name: [null, Validators.required],
      kitModel: [null, Validators.required],
      soilType: [null],
      alertLevel: [null, [Validators.required, Validators.pattern('^[1-9]{1}[0-9]*$')]],
      interval: [null, [Validators.pattern('^[1-9]{1}[0-9]*$')]],
      persistence: [null],
      maintain: [null],
      shiftMapArray: this.formBuilder.array([]),
      inputMethod: [null]
    });

    this.getDevices();
    this.getKitModels();
    this.getMetaData();
    this.isSubmitted = false;
    if (this.kit && this.kit.metaData && this.kit.metaData.propertyNameMap) {
      this.objectOfProperty = JSON.parse(this.kit.metaData.propertyNameMap);
    } else {
      this.objectOfProperty = {};
    }
  }


  getSelectedKitModelProperty() {
    this.propertiesOfKitModel = [];
    this.propertiesOfKitModel = this.kit && this.kit.model && this.kit.model.properties ? this.kit.model.properties.map(val => {
      return this.sensors.filter(s => s.code === val, 1)[0].name;
    }) : [];
  }

  getPropertyMappingName(property, index) {
    return this.kit.metaData ? this.kit.metaData.propertyNameMap ? JSON.parse(this.kit.metaData.propertyNameMap)[index] ? JSON.parse(this.kit.metaData.propertyNameMap)[index] : '' : '' : '';
  }

  enableEditPropertyName(index) {
    this.selectedPropIndex = index;
  }

  editPropertyName(index, value) {
    this.selectedPropIndex = null;
    if (this.kit.metaData && this.kit.metaData.propertyNameMap) {
      this.objectOfProperty[index] = value.trim();
    } else {
      this.propertyObj[index] = value.trim();

    }
  }


  getMetaData() {
    this.spinner.show();
    this.accountService.getMetaData().subscribe((data) => {
      this.metaData = data.content;
      console.log('metaData ###' + data);

      this.sensors = this.metaData.sensors.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });

    }, () => {
      this.spinner.hide();
    }, () => {
      this.getSelectedKitModelProperty();
      this.spinner.hide();
    });
  }

  editKitModel(kitModelLocal) {
    this.getKitModels();
    this.formTypeVar = FORM_TYPE.EDIT;

    this.selectedKitModel = (this.getKitModelsFiltered(kitModelLocal))[0];
    this.isSubmitted = false;
    console.log('kit model', kitModelLocal);
    console.log('values', this.selectedKitModel);

    console.log('kit model filtered', (this.getKitModelsFiltered(kitModelLocal)));
    this.showKitModelForm = true;
  }

  getKitModelsFiltered(searchTerm) {
    console.log('getting filtered');
    return (this.kitModels.filter(km => JSON.stringify(Object.values(km)).toLowerCase().includes(searchTerm.trim().toLowerCase()))).filter(k => (k['id'].length == searchTerm.length));

  }

  updateKit(kit) {
    kit.persistence = this.formKitRadioFields.persistence;
    kit.maintain = this.formKitRadioFields.maintain;

    Object.keys(this.formKit.controls).forEach((key) => {
        if (typeof kit[key] === 'string') {
          kit[key] = kit[key].trim();
        }
      }
    );

    this.isSubmitted = true;
    if (!AuthService.hasNewUserAccess && !this.isSuperUser) {
      this.error = 'You Don\'t Have Permission ';
      return;
    }
    if (!this.formKit.valid) {
      return;
    }
    if (!this.devicesOfKit.length) {
      return;
    }
    this.error = null;


    kit.metaData = {};

    if (this.kit && this.kit.metaData && this.kit.metaData.propertyNameMap) {
      kit.metaData.propertyNameMap = JSON.stringify(this.objectOfProperty);

    } else {
      kit.metaData.propertyNameMap = JSON.stringify(this.propertyObj);
    }
    kit.metaData.soilType = kit.soilType;

    if (this.formType === FORM_TYPE.ADD || this.isCloning) {
      kit.devices = this.devicesOfKit.map(device => {
        return device.id;
      });
      this.spinner.show();
      this.propertiesOfKitModel = [];
      this.accountService.createKit(kit).subscribe(response => {
        this.spinner.hide();
        this.hideFormTrigger.emit(true);
        this.getKitTrigger.emit(true);
        this.toaster.success('Kit Created Successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      }, () => {
        this.spinner.hide();
      });

    } else {
      kit.devices = this.devicesOfKit.map(device => {
        if (!device) {
          this.error = 'Choose valid device';
          return;
        }
        return device.id;
      });

      if (this.error) {
        return;
      }
      kit.id = this.kit.id;
      kit.kitModelId = kit.kitModel;

      const shiftMap = {};
      kit.shiftMapArray.forEach((p) => {
        if (p.operation) {
          shiftMap[p.propertyNumber] = {};
          shiftMap[p.propertyNumber].meta = {pivot: p.type};
          shiftMap[p.propertyNumber].operation = p.operation;
        }
      });

      kit.shiftMap = shiftMap;
      this.spinner.show();
      this.accountService.updateKit(this.kit.id, kit).subscribe(response => {
        this.spinner.hide();
        this.hideFormTrigger.emit(true);
        this.getKitTrigger.emit(true);
        this.toaster.success('Kit Updated Successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      }, () => {
        this.spinner.hide();
      });
    }
    this.isSubmitted = false;
  }

  getDevices() {
    this.spinner.show();
    this.accountService.getDevices().subscribe(response => {
      this.devices = response.content;
      this.devicesAll = Object.assign([], this.devices);
      this.getFormReady();
      console.log(this.devices);
      this.devices.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        } else {
          return a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1;
        }
      });
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  getKitModels() {
    this.spinner.show();
    this.accountService.getKitModels().subscribe(response => {
      this.kitModels = response.content;
      console.log('kit models from getkitmodel', response.content);
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  addDeviceToKit(index: any) {
    this.devicesOfKit.push(this.devices.splice(index, 1)[0]);
  }

  removeDeviceFromKit(index: number) {
    this.devices.push(this.devicesOfKit.splice(index, 1)[0]);
    this.devices.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }

  hideForm() {
    this.kitModelLocalSelected = null;
    this.hideFormTrigger.emit(true);
    this.error = null;
  }


  hideFormInner(event) {
    console.log('here event', event);
    this.showKitModelForm = false;
    this.selectedKitModel = null;
  }

  getFormReady() {
    if (this.formType === FORM_TYPE.ADD) {
      this.formKit.reset();
      this.formKitRadioFields.persistence = true;
      this.formKitRadioFields.maintain = false;
      this.devicesOfKit = [];
    } else if (this.formType === FORM_TYPE.EDIT) {
      this.formKit.reset();
      this.formKit.patchValue(this.kit);
      this.formKitRadioFields.persistence = this.kit.persistence;
      this.formKitRadioFields.maintain = this.kit.maintain;

      this.devicesOfKit = this.kit.devices.map(device => {
        return _.findWhere(this.devices, {id: device});
      });
      if (!this.isCloning) {
        this.formKit.controls['id'].disable();
      }
      this.error = null;

      this.kit.model.properties.forEach((p, index) => {
        this.addOperationsFormGroup(index);
      });
    }
  }

  addOperationsFormGroup(index) {
    const shiftMapArray = this.formKit.get('shiftMapArray') as FormArray;
    shiftMapArray.push(this.formBuilder.group({
      propertyNumber: [index, Validators.required],
      operation: [this.kit.shiftMap[index] ? this.kit.shiftMap[index].operation : null],
      type: [this.kit.shiftMap[index] ? this.kit.shiftMap[index].meta.pivot : null],
    }));
  }

  getPropertyCalMethod() {
    return this.propertyCalMethod;
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  getWindowWidth() {
    return window.innerWidth;
  }
}
