import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {isPasswordMatches} from '../../validator';

declare function sFunction8(): any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  hash;
  email;
  error;
  isDialog = false;

  constructor(private formBuilder: FormBuilder, private toaster: ToastrService,
              private route: ActivatedRoute, private spinner: NgxSpinnerService, protected router: Router) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }

    this.route.queryParams.subscribe(params => {
      this.hash = params['hash'];
      this.email = params['email'];
    });

    // this.verifyForgotPassword();

    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    }, {validator: isPasswordMatches('password', 'confirmPassword')});
  }

  verifyForgotPassword() {
    // this.projectService.verifyForgotPassword({forgotPasswordHash: this.hash}).subscribe(response => {
    //   this.spinner.hide();
    // }, error => {
    //   this.spinner.hide();
    //   if (error.status === 422) {
    //     this.error = error.error.message;
    //   } else {
    //     this.toaster.error('Oops... Something went wrong', 'Error!');
    //   }
    // });
  }


  register(entity: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    entity.key = this.hash;
    entity.email = this.email;
    this.spinner.show();
    // this.projectService.register(entity).subscribe(response => {
    //   this.spinner.hide();
    //   this.toaster.success('Account Created successfully', 'Success');
    //   this.router.navigate(['/login']);
    // }, error => {
    //   this.spinner.hide();
    //   if (error.status === 422) {
    //     this.error = error.error.message;
    //   } else {
    //     this.toaster.error('Oops... Something went wrong', 'Error!');
    //   }
    // });
  }
}
