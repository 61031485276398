import {combineReducers} from 'redux';
import decoderListReducer from "./service"

const reducers = combineReducers({
  decoder: decoderListReducer,


});

export default reducers;
