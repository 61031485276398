import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Snackbar,
  Typography
} from '@material-ui/core';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {primaryBlue, primaryGray, searchBarBorderColor} from 'src/components/sub-components/colors/colors';
import {Step1} from './steps/Step1';
import {Step2} from './steps/Step2';
import {TextBar} from 'src/components/sub-components/TextBar';
import {Step3} from './steps/Step3';
import {Step4} from './steps/Step4';
import {submit} from '../api-helper/apiHelper';
import {Alert} from '@material-ui/lab';

export function AddNewFirmWare(props) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [status, setStaus] = useState(true)
  const [message, setMessage] = useState("")

  const [step, setStep] = useState(0)
  const [productTypeError, setProdctTypeError] = useState(false)
  const [versionError, setVersionError] = useState(false)
  const [categoryError, setCategoryError] = useState(false)
  const [binError, setBinError] = useState(false)
  const [parameterError, setParaeterError] = useState({error: false, errorMsg: ""})
  const [idError, setIdError] = useState({error: false, errorMsg: ""})
  const [steps, setSteps] = useState([
    {
      text: "Basic Details",
      activate: true
    },
    {
      text: "Remote Configuration",
      activate: false
    },
    {
      text: "Add Technology Flow Chart",
      activate: false
    },
    {
      text: "Select Testing Device",
      activate: false
    }])
  const [addParameter, setAddParameter] = useState(false)
  const [mainType, setMainType] = useState("") // network , topic or message
  const [type, setType] = useState("")
  const [index, setIndex] = useState(-1)

  //parameter inputs
  const [parameter, setParaeter] = useState("")
  const [id, setId] = useState("")
  const [inputType, setInputType] = useState("")
  const [defaultVal, setDefaultVal] = useState("")
  const [format, setFormat] = useState("")
  const [join, setJoin] = useState([])

  //final states that should send to BE ***************
  const [selectedProductType, setSelectedProductType] = useState("")
  const [selectedVersion, setSelectedVersion] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedBin, setSelectedBin] = useState()
  const [binFileName, setBinFileName] = useState("")
  const [parameters, setParaeters] = useState([])
  const [existingFlowChart, setExistingFlowChart] = useState("")
  const [flowChart, setFlowChart] = useState()
  const [flowChartName, setFlowChartName] = useState("")
  const [deviceIds, setDeviceIds] = useState([])

  const [networkVisibility, setNetworkVisibility] = useState(false)
  const [topicVisibility, setTopicVisibility] = useState(false)
  const [messageVisibility, setMessageVisibility] = useState(false)

  const openNetworkVisibility = () => {
    setNetworkVisibility(!networkVisibility)
  }
  const openTopicVisibility = () => {
    setTopicVisibility(!topicVisibility)
  }
  const openMessageVisibility = () => {
    setMessageVisibility(!messageVisibility)
  }

  //read all input fields
  const readProductType = (productType) => {
    setSelectedProductType(productType)
    setProdctTypeError(false)
  }
  const readVersion = (version) => {
    setSelectedVersion(version)
    setVersionError(false)
  }
  const readCategory = (category) => {
    setSelectedCategory(category)
    setCategoryError(false)
  }
  const readBin = (file) => {
    setBinFileName(file[0].name)
    setSelectedBin(file[0])
    setBinError(false)
  }

  //
  const readParameters = (values) => {
    if (type === "add-new") {
      let error;
      if (values.parameter === "" || values.id === "") {
        error = true
        if (values.parameter === "") {
          setParaeterError({error: true, errorMsg: "Parameter Name is required"})
        }
        if (values.id === "") {
          setIdError({error: true, errorMsg: "Id is required"})
        }
        if (error === true) {
          return
        }
      }


      if (parameters.filter((obj) => obj.id === values.id).length > 0) {
        setIdError({error: true, errorMsg: "Id Already exist"})
        return
      }
      setParaeters(current => [...current, values])
    }
    if (type === "edit" && index !== -1) {
      if (parameters.filter((obj) => obj.id === values.id).length > 0) {
        setIdError({error: true, errorMsg: "Id Already exist"})
        return
      }
      const keys = Object.keys(values)
      keys.map((k) => {
        if (values[k] !== "") {
          parameters[index][k] = values[k]
        }
      })
      console.log(parameters)
      setParaeters(parameters)
      setType("")
    }
    setParaeter("")
    setId("")
    setInputType("")
    setDefaultVal("")
    setFormat("")
    setJoin([])
    setAddParameter(false)
  }
  const readExistingFlowChart = (fileName) => {
    setExistingFlowChart(fileName)
  }
  const readFlowChart = (file) => {
    setFlowChartName(file[0].name)
    setFlowChart(file[0])
  }
  const readDevices = (devices) => {
    console.log(devices)
    setDeviceIds(devices)
  }

  const openAddParameter = (mainType, type, i) => {
    setAddParameter(true)
    setMainType(mainType)
    setType(type)
    setIndex(i)
    if (type === "edit") {
      setInputType(parameters[i].inputType)
      setFormat(parameters[i].format)
      setJoin(parameters[i].join)
    }
  }
  const closeAddParameter = () => {
    setType("")
    setAddParameter(false)
    setParaeter("")
    setId("")
    setInputType("")
    setDefaultVal("")
    setFormat("")
    setJoin([])
    setParaeterError({error: false, errorMsg: ""})
    setIdError({error: false, errorMsg: ""})
  }

  const increaseStep = async () => {
    console.log(selectedProductType)
    let error;
    if (step === 0 && (selectedProductType === "" || selectedVersion === "" || selectedCategory === "" || binFileName === "")) {
      error = true
      if (selectedProductType === "") {
        setProdctTypeError(true)
      }
      if (selectedVersion === "") {
        setVersionError(true)
      }
      if (selectedCategory === "") {
        setCategoryError(true)
      }
      if (binFileName === "") {
        setBinError(true)
      }
      if (error === true) {
        return
      }
    }

    if (step === 3) {
      const result = await submit(
        selectedBin,
        flowChart,
        selectedProductType,
        selectedVersion,
        selectedCategory,
        parameters,
        existingFlowChart,
        deviceIds)
      console.log(result.message)
      if (result.message === "Success") {
        setStaus(true)
        setMessage(result.message)
        setOpen(true)
        props.refresh()
        setTimeout(() => {
          setOpen(false)
          closePopUp()
        }, 1000)
      } else {
        setStaus(false)
        setMessage(result.message)
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 6000)
      }
    } else {
      setStep(step + 1)
      steps.map((s, i) => {
        if (i <= step + 1) {
          s.activate = true
        } else {
          s.activate = false
        }
      })
      setSteps(steps)
    }
  }
  const decreaseStep = () => {
    if (step === 0) {
      closePopUp()
      return
    } else {
      setStep(step - 1)
      steps.map((s, i) => {
        if (i <= step - 1) {
          s.activate = true
        } else {
          s.activate = false
        }
      })
      setSteps(steps)
    }
  }

  const selectJoin = (e) => {
    setJoin(e.target.value)
  }
  const removeJoin = (value) => {
    setJoin(join.filter((d) => d !== value))
  }
  const removeDevice = (value) => {
    setDeviceIds(deviceIds.filter((d) => d !== value))
  }

  const closePopUp = () => {
    props.onClose()
    setSelectedProductType("")
    setSelectedVersion("")
    setSelectedCategory("")
    setSelectedBin(undefined)
    setBinFileName("")
    setParaeters([])
    setExistingFlowChart("")
    setFlowChart(undefined)
    setFlowChartName("")
    setDeviceIds([])
    setProdctTypeError(false)
    setVersionError(false)
    setCategoryError(false)
    setBinError(false)
  }
  useEffect(() => {
    setStep(0)
    steps.map((s, i) => {
      if (i === 0) {
        s.activate = true
      } else {
        s.activate = false
      }
    })
    setSteps(steps)
    setParaeter("")
    setId("")
    setInputType("")
    setDefaultVal("")
    setFormat("")
    setJoin([])
    setParaeters([])
  }, [props.open])

  return (
    <Dialog open={props.open}>
      <Grid container>
        <Grid item xs={10} md={10}>
          <DialogTitle>{type === "view" ? "View" : type === "edit" ? "Edit" : "Add"} {addParameter ? "Parameter" : "Firmware"}</DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{textAlign: "right"}}>
          <IconButton onClick={() => {
            addParameter ? closeAddParameter() : closePopUp()
          }}>
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>

      <Divider/>
      {
        addParameter ?
          // ***** This is add new parameter input filed set ***** //
          <>
            <DialogContent>
              <Grid container style={{textAlign: "center", marginTop: "20px"}}>
                <Grid item xs={6} md={6} style={{textAlign: "left", paddingBottom: "20px"}}>
                  <Typography className={classes.label}>Enter Parameter</Typography>
                  <TextBar
                    disabled={type === "view" ? true : false}
                    error={parameterError}
                    onChange={(e) => {
                      setParaeter(e.target.value)
                      setParaeterError({error: false, errorMsg: ""})
                    }
                    }
                    value={type === "edit" || type === "view" ? parameters[index].parameter : ""}/>
                </Grid>
                <Grid item xs={6} md={6} style={{textAlign: "left", paddingBottom: "20px", paddingLeft: "20px"}}>
                  <Typography className={classes.label}>Enter Id</Typography>
                  <TextBar
                    disabled={type === "view" ? true : false}
                    type={"number"}
                    error={idError}
                    onChange={(e) => {
                      setId(e.target.value)
                      setIdError({error: false, errorMsg: ""})
                    }}
                    onWheel={(e) => e.target.blur()}
                    value={type === "edit" || type === "view" ? parameters[index].id : ""}/>
                </Grid>
                <Grid item xs={6} md={6} style={{textAlign: "left", paddingBottom: "20px"}}>
                  <Typography className={classes.label}>Enter Input Type</Typography>
                  <FormControl style={{padding: "0px", width: "78%"}}>
                    <NativeSelect
                      disabled={type === "view" ? true : false}
                      id="demo-customized-select-native"
                      value={type === "view" ? parameters[index].inputType : inputType}
                      className={classes.input}
                      onChange={(e) => setInputType(e.target.value)}
                      input={<BootstrapInput/>}
                    >
                      <option value="" disabled hidden>Select input type</option>
                      <option value="text">Text</option>
                      <option value="json">Json</option>
                      <option value="Html">Html</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6} style={{textAlign: "left", paddingBottom: "20px", paddingLeft: "20px"}}>
                  <Typography className={classes.label}>Default</Typography>
                  <TextBar
                    disabled={type === "view" ? true : false}
                    error={{error: false}}
                    onChange={(e) => setDefaultVal(e.target.value)}
                    value={type === "edit" || type === "view" ? parameters[index].default : ""}/>
                </Grid>
                <Grid item xs={6} md={6} style={{textAlign: "left", paddingBottom: "20px"}}>
                  <Typography className={classes.label}>Enter Format</Typography>
                  <FormControl style={{padding: "0px", width: "78%"}}>
                    <NativeSelect
                      disabled={type === "view" ? true : false}
                      id="demo-customized-select-native"
                      value={type === "view" ? parameters[index].format : format}
                      className={classes.input}
                      onChange={(e) => setFormat(e.target.value)}
                      input={<BootstrapInput/>}
                    >
                      <option value="" disabled hidden>Select format type</option>
                      <option value="String">string</option>
                      <option value="int">int</option>
                      <option value="double">double</option>
                      <option value="float">float</option>
                      <option value="char">char</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6} style={{textAlign: "left", paddingBottom: "20px", paddingLeft: "20px"}}>
                  <Typography className={classes.label}>Join parameter</Typography>
                  <FormControl style={{minWidth: '78%'}}>
                    <Select
                      disabled={type === "view" ? true : false}
                      id="demo-customized-select-native"
                      value={type === "view" ? parameters[index].join : join}
                      className={classes.input}
                      onChange={selectJoin}
                      multiple
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      renderValue={(selected: string[]) =>
                        <div>
                          {selected.map((value, i) => (
                            <Chip key={i} label={value}
                                  className={classes.chip}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onDelete={() => removeJoin(value)}/>
                          ))}
                        </div>
                      }
                      input={<BootstrapInput/>}
                    >
                      {
                        type === "add-new" ?
                          parameters.filter((obj) => obj.mainType === mainType).map((p, j) => {
                            return (
                              <MenuItem value={p.id} key={j}>
                                <ListItemText primary={p.id} style={{color: primaryGray}}/>
                              </MenuItem>
                            )
                          })
                          : type === "edit" ?
                            parameters.filter((obj) => obj.mainType === mainType).filter((ob) => ob.id !== parameters[index].id).map((p, j) => {
                              return (
                                <MenuItem value={p.id} key={j}>
                                  <ListItemText primary={p.id} style={{color: primaryGray}}/>
                                </MenuItem>
                              )
                            })
                            : <></>
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            {
              type !== "view" &&
              <DialogActions style={{width: "100%"}} hidden>
                <Button variant='contained' className={classes.cancel} onClick={closeAddParameter}>
                  Cancel
                </Button>
                <Button variant='contained' className={classes.add} disabled={type === "view" ? true : false}
                        onClick={() => readParameters(
                          {
                            "mainType": mainType,
                            "parameter": parameter,
                            "id": id,
                            "inputType": inputType,
                            "default": defaultVal,
                            "format": format,
                            "join": join
                          }
                        )}
                >
                  {type === "edit" ? "Update" : "Save"}
                </Button>
              </DialogActions>
            }

          </>

          :
          // ***** This is add normal pop-up steps ***** //
          <>
            <DialogContent>
              <Grid container style={{textAlign: "center", marginTop: "20px"}}>
                {
                  steps.map((step, index) => (
                    <Hidden key={index}>
                      {index === 0 && <Grid item xs={1} md={1}></Grid>}
                      <Grid item xs={1} md={1}>
                        <Circle number={index + 1} active={step.activate} step={step}/>
                      </Grid>
                      {
                        index !== 3 &&
                        <Grid item xs={2} md={2} style={{width: "100px"}}>
                          <hr style={{marginTop: "20px", opacity: steps[index + 1].activate ? "1" : "0.12"}}
                              color={steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"}/>
                        </Grid>
                      }
                    </Hidden>
                  ))
                }
                {
                  steps.map((step, index) => (
                    <Grid item xs={3} md={3} key={index}>
                      <Typography style={{
                        fontSize: '12px',
                        paddingTop: "10px",
                        color: step.activate ? primaryBlue : primaryGray
                      }}>{step.text}</Typography>
                    </Grid>
                  ))
                }
                <Grid item xs={12} md={12}>
                  {step === 0 && <Step1
                    productTypeError={productTypeError}
                    versionError={versionError}
                    categoryError={categoryError}
                    binError={binError}

                    readVersion={(version) => readVersion(version)}
                    defaultVersion={selectedVersion}

                    readProductType={(productType) => readProductType(productType)}
                    defaultProductType={selectedProductType}

                    readCategory={(category) => readCategory(category)}
                    defaultCategory={selectedCategory}

                    readBin={(file) => readBin(file)}
                    binFileName={binFileName}
                  />}
                  {step === 1 && <Step2
                    openNetworkVisibility={() => openNetworkVisibility()}
                    openTopicVisibility={() => openTopicVisibility()}
                    openMessageVisibility={() => openMessageVisibility()}
                    networkVisibility={networkVisibility}
                    topicVisibility={topicVisibility}
                    messageVisibility={messageVisibility}
                    open={(mainType, type, i) => openAddParameter(mainType, type, i)} parameters={parameters}/>}
                  {step === 2 && <Step3
                    readExistingFlowChart={(fileName) => readExistingFlowChart(fileName)}
                    defaultFlowChart={existingFlowChart}

                    readFlowChart={(file) => readFlowChart(file)}
                    chartFilename={flowChartName}/>}
                  {step === 3 && <Step4
                    readDevices={(devices) => readDevices(devices)}
                    removeDevice={(device) => removeDevice(device)}
                    defaultDevices={deviceIds}/>}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{width: "100%"}}>
              <Button onClick={decreaseStep} variant='contained' className={classes.cancel}>
                {step === 1 || step === 2 || step === 3 ? "Back" : "Cancel"}
              </Button>
              <Button onClick={increaseStep} variant='contained' className={classes.add}>
                {step === 0 || step === 1 || step === 2 ? "Next" : "Submit"}
              </Button>
            </DialogActions>
          </>
      }
      <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
        <Alert
          severity={status === true ? "success" : "warning"}>

          <h3 style={{textAlign: "left"}}>{status === true ? "Success" : "Error"}</h3>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}

export const Circle = (props) => {
  return (
    <div>
      <div style={{
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
        textAlign: "center",
        paddingTop: "10px",
        boxShadow: "0px 4px 8px #0000001F"
      }}>
        <h5 style={{color: props.active ? primaryBlue : primaryGray}}>{props.number}</h5>
      </div>
    </div>
  )
}


const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    add: {
      backgroundColor: primaryBlue,
      color: "white",
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    cancel: {
      backgroundColor: "white",
      color: primaryGray,
      marginBottom: "10px",
      marginRight: "10px",
      width: "20%",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      }
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px"
    },
    input: {
      minWidth: "100%",
      maxWidth: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px"
    }
  }),
);

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#666D73">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
    </svg>
  )
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: "white",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Poppins',

        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  }),
)(InputBase);
