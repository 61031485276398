import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import * as React from 'react';
import {useState} from 'react';
import {AddNewFirmWare} from 'src/components/product-types/add-new-firmware/AddNewFirmWare';
import {SearchBar} from 'src/components/sub-components/SearchBar';
import {primaryBlue, seconderyTextColor, white} from 'src/components/sub-components/colors/colors';
import {IconDropDown} from 'src/components/sub-components/IconDropDown';
import {environment} from 'src/environments/environment';
import * as _ from 'underscore';
import {Alert} from '@material-ui/lab';
import {ConfigureIcon, ListIcon} from 'src/components/product-types/add-new-firmware/steps/Step2';

export function OTAManagerComponent(props) {
  const classes = useStyles()
  const userId = localStorage.getItem('USER_ID')
  const [checked, setChecked] = React.useState([true, false]);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [viewDevice, setViewDevice] = useState(false);
  const [upgradeDevice, setUpgradeDevice] = useState(false);
  const [devices, setDevices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [annexed, setAnnexed] = useState([]);
  const [constAnnexed, setConstAnnexed] = useState([])
  const scrollRefs = React.useRef(null);
  const [max, setMax] = useState(5);
  const [favoriteDevices, setFavouriteDevices] = useState([]);
  const [filterFavouriteDevices, setFilterFavouriteDevices] = useState(false);
  const [selectVersions, setselectVersions] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [open, setOpen] = useState(false)
  const [reqSuccess, setReqSuccess] = useState(true)
  //Filter sets
  const [clients, setClients] = useState([])
  const [device, setDevice] = useState([])
  const [productType, setProductType] = useState([])
  const [status, setStatus] = useState(['All', 'Updated', 'Pending', 'Failure'])
  const [availableVersions, setAvailableVersions] = useState([])
  const [availablePreviousVersions, setAvailablePreviousVersions] = useState([]);
  const [constAnnexedFiltered, setConstAnnexedFiltered] = useState([])
  const uniqueClientDeviceCombinations = new Set();
  React.useEffect(() => {
    //getting states to default values
    setDevices([]);
    getAllDevices();
  }, [])
  React.useEffect(() => {
    //getting states to default values
    getProjects();
  }, [])
  const getProjects = () => {
    setProjects([]);
    fetch(`${environment.host}/user/${userId}/project`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      }).then(response => response.json())
      .then(data => {
        if (data.content) {
          setProjects(data.content);
          setClients(['All', ..._.pluck(data.content, 'name').sort()])
        } else {
          setProjects([]);
        }
      })
      .catch((err) => {
        alert(err)
      });
    getFavourites(userId)
  }
  React.useEffect(() => {
    if (projects.length > 0 && devices.length > 0) {
      let deviceAnnex = [];
      let versionAnnex = [];
      let previousVersionAnnex = [];


      devices.map(device => (
        projects.map(project => {

          if (project.kits.includes(device.id)) {
            device['client'] = project.name;
            deviceAnnex.push(device)

            if (device.product) {
              if (device.product.currentProductVersion) {
                versionAnnex.push(device.product.currentProductVersion);
              }
              if (device.product.allProductVersionsOfDevice) {
                const versions = device.product.allProductVersionsOfDevice.split(',').map((num) => parseInt(num.trim(), 10));
                previousVersionAnnex.push(...versions);
              }
            }


          }
        })
      ));
      console.log(deviceAnnex);
      setAnnexed(deviceAnnex)
      setConstAnnexed(deviceAnnex)
      setDevice(['All', ..._.pluck(deviceAnnex, 'id').sort()])

      setAvailableVersions(['All', ...new Set(versionAnnex)].sort((a, b) => a - b));
      setAvailablePreviousVersions(['All', ...new Set(previousVersionAnnex)].sort((a, b) => a - b))

    }
  }, [projects, devices])
  const getAllDevices = () => {
    fetch(`${environment.host}/core/device`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      }).then(response => response.json())
      .then(data => {
        if (data.content) {
          setDevices(data.content);
        } else {
          setDevices([]);
        }
      })
      .catch((err) => {
        alert(err)
      });
  }
  React.useEffect(() => {
    const uId = localStorage.getItem("USER_ID")
    fetch(`${environment.host}/core/product/configDetails`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        console.log('data', data.content);
        let datas = data.content.productTypes
        setProductType(['All', ..._.pluck(data.content.productTypes[0], 'productType').sort()])
        //setAvailableVersions(['All', ...data.content["available versions"][0]])
      })
      .catch((err) => {
        setProductType([])
        //setAvailableVersions([])
      })
    getFavourites(uId)
  }, []);


  const [actionNames, setActionNames] = useState([]);
  var actions_name = [];
  React.useEffect(() => {
    fetch(`${environment.host}/core/device`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    })
      .then((response) => response.json())
      .then((data) => {
        actions_name.push("ALL");
        data.content.map((item) => {
          if (item.product !== undefined) {
            if (item.product.ActionBy !== undefined) {
              console.log(item.product.ActionBy);
              actions_name.push(item.product.ActionBy);
            }
          }
        });
      })
      .then(() => {
        const arr = [...new Set(actions_name)];
        setActionNames(arr);
      })
      .catch((err) => console.log(err));
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const closeAddNew = () => {
    setAddNewOpen(false)
  }
  const scrollSmoothHandler = (index) => () => {
    scrollRefs.current.scrollIntoViewIfNeeded();
    console.log(scrollRefs)
  };
  const getFavourites = (id) => {
    fetch(`${environment.host}/core/user/${id}/device/favourite`,
      {
        method: "GET",
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        setFavouriteDevices(data.content)
      })
      .catch((err) => {
        setFavouriteDevices([])
      })
  }
  const filterFavourite = () => {
    setFilterFavouriteDevices(!filterFavouriteDevices)
    if (filterFavouriteDevices === false) {
      setAnnexed(annexed.filter((obj) => favoriteDevices.includes(obj.id)))
      console.log('fil', favoriteDevices)
    }
    if (filterFavouriteDevices === true) {
      getAllDevices();
    }
  }
  const addOrRemoveFavourite = (favourite, id) => {
    if (favourite === false) {
      fetch(`${environment.host}/core/user/${userId}/device/${id}?action=add`,
        {
          method: "PUT",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify([id])
        })
        .then(response => response.json())
        .then(data => {
          getFavourites(userId)
          setReqSuccess(true)
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 6000)
        })
        .catch((err) => {
          getFavourites(userId)
          setReqSuccess(false)
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 6000)
        })
    }
    if (favourite === true) {
      fetch(`${environment.host}/core/user/${userId}/device/${id}?action=remove`,
        {
          method: "PUT",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify([id])
        })
        .then(response => response.json())
        .then(data => {
          getFavourites(userId)
          setReqSuccess(true)
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 6000)
        })
        .catch((err) => {
          getFavourites(userId)
          setReqSuccess(false)
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 6000)
        })
    }
  }
  const viewSelectedDevices = () => {
    if (constAnnexedFiltered.length > 0 || selectVersions.length === 0) {
      setAnnexed(constAnnexed);
      setConstAnnexedFiltered([])
      return;
    }
    const constAnnexedFiltered_ = constAnnexed.filter((el) => {
      return selectVersions.some((f) => {
        return f.id === el.id;
      });
    });
    setConstAnnexedFiltered(constAnnexedFiltered_)
    console.log('ann0', constAnnexedFiltered_)
    setAnnexed(constAnnexedFiltered_)
  }
  const upgradeDevices = () => {
    if (selectVersions.length > 0) {
      let finalArray = []
      finalArray = selectVersions.map(e => _.omit(e, 'id'));
      console.log(finalArray)
      // return console.log(selectVersions.forEach(function(item){ delete item['id'] }))
      // result.forEach(function(item){ delete item.b });
      fetch(`${environment.host}/core/product/version/update`,
        {
          method: "PUT",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(finalArray)
        })
        .then(response => response.json())
        .then(data => {
          console.log('data,', data.errorCode)
          setselectVersions([]);
          setConstAnnexedFiltered([]);
          setUpgradeDevice(false);
          setViewDevice(false);
          getProjects();
          getAllDevices();
          if (data.errorCode.length > 0) {
            console.log('data,', data.errorCode)
            setReqSuccess(false)
            setOpen(true)
            setErrorMessage(data.message)
            setTimeout(() => {
              setOpen(false)
              setErrorMessage('')
            }, 6000)
          } else {
            setReqSuccess(true)
            setOpen(true)
            setTimeout(() => {
              setOpen(false)
            }, 6000)
          }
        })
        .catch((err) => {
          console.log('err0,', err)
          setselectVersions([]);
          setConstAnnexedFiltered([]);
          getProjects();
          getAllDevices();
          setReqSuccess(false)
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
          }, 6000)
        })
    }
  }
  const selectVersion = (id, version, productId) => {
    let obj = {}
    console.log(id, version, productId)
    if (selectVersions.filter(e => (e['id'] === id) && (e['version'].versionNum === version)).length > 0) {
      setselectVersions(selectVersions.filter(e => e['id'] !== id))
      return;
    }
    if (selectVersions.filter(e => (e['id'] === id)).length > 0) {
      let a = selectVersions.filter(e => e['id'] !== id)
      obj = {
        "id": id,
        "productId": productId,
        "version": {
          "versionNum": version,
          "devices": [id]
        }
      }
      setselectVersions([...a, obj])
      return;
    }
    obj = {
      "id": id,
      "productId": productId,
      "version": {
        "versionNum": version,
        "devices": [id]
      }
    }
    setselectVersions(prevState => [...prevState, obj])
    console.log('selectVersions', selectVersions)
  }
  const clientNameFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed)
      return;
    }
    const selectedClient = constAnnexed.filter((obj) => obj.client === e.target.value)
    setAnnexed(selectedClient)
    setFilterFavouriteDevices(false)
  }
  const deviceFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed)
      return;
    }
    const selectedDevice = constAnnexed.filter((obj) => obj.id === e.target.value)
    setAnnexed(selectedDevice)
    setFilterFavouriteDevices(false)
  }
  const productTypeFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed)
      return;
    }
    const selectedProductType = constAnnexed.filter((obj) => obj.product && obj.product.productType === e.target.value)
    setAnnexed(selectedProductType)
    setFilterFavouriteDevices(false)
  }
  const statusFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed);
      return;
    }


    const selectedStatus = constAnnexed.filter((obj) => {
      if (e.target.value === 'Updated') {

        return obj.product && obj.product.currentVersionStatus === 'APPROVED';
      } else {
        return obj.product && obj.product.currentVersionStatus === e.target.value.toUpperCase();
      }
    });


    setAnnexed(selectedStatus);
    setPage(0);
    setFilterFavouriteDevices(false);
  };


  const versionFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed)
      return;
    }
    const selectedVersion = constAnnexed.filter((obj) => obj.product && obj.product.currentProductVersion === e.target.value)
    console.log(selectedVersion);
    setAnnexed(selectedVersion)
    setFilterFavouriteDevices(false)
  }

  const previousVersionsFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed);
      return;
    }

    const selectedVersion = constAnnexed.filter((obj) => {
      if (obj.product && obj.product.allProductVersionsOfDevice) {
        const versions = obj.product.allProductVersionsOfDevice.split(',').map((num) => num.trim());

        // Check if the versions array includes the target value as a number
        const targetValue = parseInt(e.target.value, 10);
        return versions.some(version => parseInt(version, 10) === targetValue);
      }
      return false;
    });

    console.log(selectedVersion);
    setAnnexed(selectedVersion);
    setFilterFavouriteDevices(false);
  };


  const availableVersionFilter = (e) => {
    if (e.target.value === 'All') {
      setAnnexed(constAnnexed)
      return;
    }
    const availableVersion = constAnnexed.filter((obj) => obj.product && obj.product.availableProductVersions.includes(e.target.value))
    setAnnexed(availableVersion)
    setFilterFavouriteDevices(false)
  }


  const ActionByFilter = (e) => {
    console.log(constAnnexed);

    if (e.target.value === "ALL") {
      setAnnexed(constAnnexed);
    } else {
      const actionByDevice = constAnnexed.filter(
        (obj) =>
          obj.product &&
          obj.product.ActionBy &&
          obj.product.ActionBy.includes(e.target.value)
      );
      setAnnexed(actionByDevice);
    }
  };


  return (
    <div className={`testing`}>
      {/* <div>{console.log('selectVersions', selectVersions)}</div> */}
      <Grid container className={classes.container}>
        <Grid item xs={6} md={3} style={{textAlign: "left", marginLeft: '9px'}}>
          <SearchBar onChange={(e) => setSearch(e.target.value)}/>
        </Grid>
        <Grid item md={9}></Grid>
        {}
        <Grid container style={{backgroundColor: "white", marginTop: "20px", marginLeft: "10px", alignItems: 'center'}}>
          <Grid item xs={5} md={5}
                style={{textAlign: "left", paddingLeft: '15px', paddingTop: "5px", alignItems: 'center'}}>
            {!viewDevice && <>
              <Checkbox
                checked={constAnnexedFiltered.length > 0}
                // indeterminate={selectVersions.length !== 0 && selectVersions.length !== devices.length}
                color="primary"
                // onChange={() => viewSelectedDevices()}
              />
              <Checkbox onClick={() => filterFavourite()} style={{padding: "0px"}}
                        checked={filterFavouriteDevices}
                        icon={<OutlinedStar color="#0000008A"/>}
                        checkedIcon={<ContainedStar/>}
              />
              <IconButton style={{padding: "0px"}} onClick={() => {
                setselectVersions([]);
                setUpgradeDevice(false);
                setViewDevice(false);
                setAnnexed(constAnnexed);
                setConstAnnexedFiltered([]);
              }}>
                <Refresh/>
              </IconButton>
              <IconButton style={{padding: "0px"}}>
                <MenuDots/>
              </IconButton>
            </>}
            {(selectVersions.length > 0) && <>
              {!viewDevice && <Button onClick={() => {
                setUpgradeDevice(!upgradeDevice), viewSelectedDevices()
              }} size="small" style={{
                border: upgradeDevice ? `1px solid #2A7CED` : `1px solid #F2F2F2`,
                color: upgradeDevice ? "#2A7CED" : "#3C3C3C",
                fontSize: "12px",
                textTransform: 'none',
                marginTop: '12px',
                marginBottom: '12px',
                marginLeft: '12px',
                backgroundColor: upgradeDevice ? '#fff' : '#f2f2f2',
                borderRadius: '54px',
                paddingInline: '9px',
                fontWeight: 'regular'
              }}>Upgrade Device</Button>}
              {!upgradeDevice && <Button onClick={() => {
                setViewDevice(!viewDevice), viewSelectedDevices()
              }} size="small" style={{
                border: viewDevice ? `1px solid #2A7CED` : `1px solid #F2F2F2`,
                color: viewDevice ? "#2A7CED" : "#3C3C3C",
                fontSize: "12px",
                textTransform: 'none',
                backgroundColor: viewDevice ? '#fff' : '#f2f2f2',
                margin: '12px',
                borderRadius: '54px',
                paddingInline: '9px',
                fontWeight: 'regular'
              }} variant="outlined">View Device</Button>}
            </>}
          </Grid>
          <Grid item xs={5} md={5}
                style={{textAlign: 'end', alignItems: 'flex-end', display: 'flex', justifyContent: 'end'}}>
            {(selectVersions.length > 0 && upgradeDevice) && <>
              <Button onClick={() => (setselectVersions([]), setConstAnnexedFiltered([]))} size="small" style={{
                color: "#0000008A",
                fontSize: "14px",
                marginRight: '9px',
                textTransform: 'none'
              }}>Cancel</Button>
              <Button onClick={() => upgradeDevices()} size="small"
                      style={{border: `none`, color: "#2A7CED", fontSize: "14px", textTransform: 'none'}}
                      variant="outlined">Update</Button>
            </>}
          </Grid>
          <Grid style={{textAlign: 'right', alignItems: 'center'}} item xs={2} md={2}>

            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={annexed !== undefined && annexed.filter(ele => ele.client.toString().toLowerCase().includes(search.trim().toLowerCase())).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{marginTop: "20px", marginLeft: "10px"}}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {!viewDevice && <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}> </Typography>
                    </div>
                    {/* <IconDropDown onChange={(e) => clientNameFilter(e)} data={clients} /> */}
                  </TableCell>}
                  {viewDevice && <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}>DATE</Typography>
                      <IconDropDown onChange={(e) => clientNameFilter(e)} data={[]}/>
                    </div>
                  </TableCell>}
                  <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>CLIENT NAME</Typography>
                      <IconDropDown onChange={(e) => clientNameFilter(e)} data={clients}/>
                    </div>
                  </TableCell>
                  <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>DEVICE NAME</Typography>
                      <IconDropDown onChange={(e) => deviceFilter(e)} data={device}/>
                    </div>
                  </TableCell>
                  {!viewDevice && <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>PRODUCT TYPE</Typography>
                      <IconDropDown onChange={(e) => productTypeFilter(e)} data={productType}/>
                    </div>
                  </TableCell>}
                  <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>STATUS</Typography>
                      <IconDropDown onChange={(e) => statusFilter(e)} data={status}/>
                    </div>
                  </TableCell>
                  <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>CURRENT VERSION</Typography>
                      <IconDropDown onChange={(e) => versionFilter(e)} data={availableVersions}/>
                    </div>
                  </TableCell>
                  {!upgradeDevice && <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>PREVIOUS VERSIONS</Typography>
                      <IconDropDown onChange={(e) => previousVersionsFilter(e)} data={availablePreviousVersions}/>
                    </div>
                  </TableCell>}
                  {viewDevice && <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>ACTION BY</Typography>
                      <IconDropDown onChange={(e) => (null)} data={[]}/>
                    </div>
                  </TableCell>}
                  {upgradeDevice && <TableCell style={{
                    textAlign: "center",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography display="inline" style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>UPDATE VERSIONS</Typography>
                      <IconDropDown onChange={(e) => availableVersionFilter(e)} data={availableVersions}/>
                    </div>
                  </TableCell>}
                  {true && <TableCell style={{
                    textAlign: "left",
                    borderBottomColor: "white",
                    borderTopColor: "white",
                    backgroundColor: "white"
                  }}>
                    <div style={{display: "inline-flex"}}>
                      <Typography style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      }}>ACTIONS</Typography>
                      <IconDropDown onChange={(e) => ActionByFilter(e)} data={actionNames}/>
                    </div>
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {annexed.length > 0 &&
                  annexed
                    .filter((ele) => ele.client.toString().toLowerCase().includes(search.trim().toLowerCase()))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((ele, i) => {
                      const clientDeviceCombination = `${ele.client}_${ele.id}`;

                      // Check if the combination of client name and device name is unique
                      if (!uniqueClientDeviceCombinations.has(clientDeviceCombination)) {
                        // Add the combination to the set
                        uniqueClientDeviceCombinations.add(clientDeviceCombination);
                        return (<TableRow key={ele.id}>
                          {!viewDevice && <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                            display: 'flex'
                          }}>
                            {/* <Checkbox color="primary"/> */}
                            <FormControlLabel
                              label=''
                              control={
                                <Checkbox
                                  checked={selectVersions.filter(e => (e['id'] === ele.id)).length > 0}
                                  color="primary"
                                  onChange={() => (selectVersions.filter(e => (e['id'] === ele.id)).length > 0 ? setselectVersions(selectVersions.filter(e => e['id'] !== ele.id)) : selectVersion(ele.id, ele.product.currentProductVersion, ele.product.productId))}
                                />}
                            />
                            <Checkbox
                              onClick={() => addOrRemoveFavourite(favoriteDevices !== undefined && favoriteDevices.includes(ele.id), ele.id)}
                              style={{padding: "0px"}}
                              checked={favoriteDevices !== undefined && favoriteDevices.includes(ele.id)}
                              icon={<OutlinedStar color="#00000029"/>}
                              checkedIcon={<ContainedStar/>}
                            />
                          </TableCell>}
                          {viewDevice && <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography
                              className={classes.tableText}>{ele.product && ele.product.Date && ele.product.Date.toString().slice(0, 10)}</Typography>
                          </TableCell>}
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            paddingTop: '18px',
                            paddingBottom: '18px',
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography className={classes.tableText}>{ele.client}</Typography>
                          </TableCell>
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography className={classes.tableText}>{ele.id}</Typography>
                          </TableCell>
                          {!viewDevice && <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography
                              className={classes.tableText}>{ele.product ? ele.product.productType : ''}</Typography>
                          </TableCell>}
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            {ele.product && ele.product.currentVersionStatus === "APPROVED" &&
                              <Chip className={classes.greenChip} label="Updated"/>}
                            {ele.product && ele.product.currentVersionStatus === "PENDING" &&
                              <Chip className={classes.yellowChip} label="Pending"/>}
                            {ele.product && ele.product.currentVersionStatus === "FAILURE" &&
                              <Chip className={classes.redChip} label="Failure"/>}
                          </TableCell>
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography
                              className={classes.tableText}>{ele.product ? parseFloat(ele.product.currentProductVersion) : ''}</Typography>
                          </TableCell>
                          {!upgradeDevice && <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography
                              className={classes.tableText}>{ele.product ? ele.product.allProductVersionsOfDevice && ele.product.allProductVersionsOfDevice : ''}</Typography>
                          </TableCell>}
                          {viewDevice && <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <Typography
                              className={classes.tableText}>{ele.product && ele.product.ActionBy && ele.product.ActionBy.toString()}</Typography>
                          </TableCell>}
                          {upgradeDevice && <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <div style={{display: 'inline-flex', maxWidth: '210px', overflow: 'auto', maxHeight: 48}}>
                              <div className='hiddenScroll'>
                                {ele.product && JSON.parse(ele.product.availableProductVersions).sort(function (a, b) {
                                  return a - b
                                }).map((ver, ind) => (
                                  <Chip clickable={false} variant="outlined" key={ver}
                                        onClick={() => ((ele.product.currentProductVersion.toString() !== ver.toString()) && selectVersion(ele.id, ver, ele.product.productId))}
                                        style={{margin: 3}}
                                        className={parseFloat(ver) === parseFloat(ele.product.currentProductVersion) ?
                                          classes.versionChip : selectVersions.filter(e => (e['id'] === ele.id) && (e['version'].versionNum === ver)).length > 0 ?
                                            classes.blueChip : classes.availableVersionChip}
                                        label={parseFloat(ver) % 1 !== 0 ? parseFloat(ver).toFixed(3) : ver}/>
                                ))}
                              </div>
                            </div>
                          </TableCell>}
                          <TableCell style={{
                            textAlign: "left",
                            borderBottomColor: "white",
                            borderTopColor: "white",
                            backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                          }}>
                            <IconButton style={{padding: "0px"}} onClick={() => null}>
                              <ConfigureIcon/>
                            </IconButton>
                            <IconButton style={{padding: "0px", paddingLeft: "10px"}} onClick={() => null}>
                              <ListIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>);

                      }
                      return null;
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AddNewFirmWare open={addNewOpen} onClose={() => closeAddNew()}/>
      <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{textAlign: "left"}}>{reqSuccess === true ? "Success" : "Error"}</h3>
          {reqSuccess ? "Successfully updated" : errorMessage.length > 0 ? errorMessage : "Something went wrong"}
        </Alert>
      </Snackbar>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      display: 'none'
    },
  },
  container: {
    paddingTop: "20px",
    paddingRight: "9px"
  },
  versionSelected: {
    backgroundColor: theme.palette.grey[50],
    border: `2px solid #2A7CED`,
    color: '#2A7CED',
    margin: '6'
  },
  version: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid #707170`,
    color: "#707170",
    margin: '6'
  },
  filter: {
    backgroundColor: white,
    color: seconderyTextColor,
    boxShadow: "none",
  },
  addFirmware: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    '&:hover': {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none"
    },
    [theme.breakpoints.down('md')]: {
      margin: "10px",
    }
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  versionCircleText: {
    fontSize: "10px",
  },
  versionText: {
    fontSize: "14px",
    color: "#2A7CED",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px"
  },
  versionChip: {
    backgroundColor: "#e6edf7",
    color: "#2a7ced",
    height: "20px",
    border: `1px solid #2A7CED`,
  },
  availableVersionChip: {
    backgroundColor: "#fff",
    color: "#707070",
    height: "20px",
  },
  yellowChip: {
    backgroundColor: "#FFB40A1F",
    color: "#FFB40A",
    height: "20px"
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px"
  },
  blueChip: {
    backgroundColor: "#2a7ced",
    color: "#fff",
    height: "20px"
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px"
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px"
  },
}));
const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px"
  }
})(Tooltip);
export const OutlinedStar = (props) => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={props.color}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/></svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill={props.fill || 'none'} stroke={props.color || "none"} ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={props.color}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path
        d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
  )
}
export const ContainedStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EFD494">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>
  )
}
export const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.75" height="11.75" viewBox="0 0 11.75 11.75">
      <g id="info_2_" data-name="info (2)" transform="translate(-1.625 -1.625)">
        <circle id="Ellipse_753" data-name="Ellipse 753" cx="5.5" cy="5.5" r="5.5" transform="translate(2 2)"
                fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        <line id="Line_570" data-name="Line 570" y1="3" transform="translate(7.5 6.444)" fill="none" stroke="#707070"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        <line id="Line_571" data-name="Line 571" transform="translate(7.5 4.75)" fill="none" stroke="#707070"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
      </g>
    </svg>
  )
}
export const Refresh = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0000008A">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
    </svg>
  )
}
export const MenuDots = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0000008A">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
    </svg>
  )
}
export const ChevronLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor">
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  )
}
export const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  )
}
