import * as React from 'react';

export function DragDrop(props) {


  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="77.221" height="63.839" viewBox="0 0 77.221 63.839"
         style={{marginLeft: "275px"}}>
      <g id="Group_1" data-name="Group 1" transform="translate(-0.384 -0.084)">
        <path id="Path_13332" data-name="Path 13332" d="M52.333,45.333,39,32,25.667,45.333" fill="none" stroke="#427ae5"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.846"/>
        <path id="Path_13333" data-name="Path 13333" d="M39,32V62" fill="none" stroke="#427ae5" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="3.846"/>
        <path id="Path_13334" data-name="Path 13334"
              d="M66.967,53.3A16.667,16.667,0,0,0,59,22H54.8A26.667,26.667,0,1,0,9,46.333" fill="none" stroke="#427ae5"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.846"/>
        <path id="Path_13335" data-name="Path 13335" d="M52.333,45.333,39,32,25.667,45.333" fill="none" stroke="#427ae5"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.846"/>
      </g>
    </svg>

  )
}
