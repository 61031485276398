import * as React from "react";
//import {useState} from 'react';
import {Grid,} from "@material-ui/core";
import {makeStyles, Theme,} from "@material-ui/core/styles";
import {TestSuccessCriteria} from "./test-success-criteria/testSuccessCriteria";
import {TestSummaryReport} from "./test-summary-report/testSummaryReport";
import {DataTable} from "./data-table/dataTable";

const useStyles = makeStyles((theme: Theme) => ({}));

export function TestDashboard(props) {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6}>
          {props.testSummaryData !== undefined &&
          props.mainTestCase !== undefined ? (
            <TestSuccessCriteria
              summary={props.testSummaryData}
              allData={props.allData}
              mainCase={props.mainTestCase}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{paddingRight: "20px"}}>
          {props.testSummaryData !== undefined &&
          props.mainTestCase !== undefined ? (
            <TestSummaryReport
              summary={props.testSummaryData}
              allData={props.allData}
              mainCase={props.mainTestCase}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} md={12} style={{margin: "20px"}}>
          {props.testSummaryData !== undefined &&
          props.allMainTestCases !== undefined ? (
            <DataTable
              allMainTestCases={props.allMainTestCases}
              testSummaryData={props.testSummaryData}
              loadingClose={props.loadingClose}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
