import {
  Button,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import {useState} from "react";
import {AddNewFirmWare} from "src/components/product-types/add-new-firmware/AddNewFirmWare";
import {SearchBar} from "src/components/sub-components/SearchBar";
import {primaryBlue, seconderyTextColor, white,} from "src/components/sub-components/colors/colors";
import {environment} from "src/environments/environment";
import * as _ from "underscore";
import {Alert} from "@material-ui/lab";
import {EditIcon, ListIcon, ViewIcon,} from "src/components/product-types/add-new-firmware/steps/Step2";
import {timeSince} from "../utils";
import FilterBar from "src/components/sub-components/FilterBar";
import ConfigureDevice from "src/components/sub-components/ConfigureDevice/ConfigureDevice";
import ViewConfiguration from "src/components/sub-components/ViewConfiguration";
import EditConfiguration from "src/components/sub-components/EditConfigureDevice/EditConfiguration";
import HistoryDevice from "src/components/sub-components/HistoryDevice/HistoryDevice";

export function FilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{padding: "4px"}}
      // class="feather feather-filter"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
    </svg>
  );
}

export function RemoteManagerComponent(props) {
  const classes = useStyles();
  const userId = localStorage.getItem("USER_ID");

  const [checked, setChecked] = React.useState([true, false]);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [viewDevice, setViewDevice] = useState(false);
  const [upgradeDevice, setUpgradeDevice] = useState(false);
  const [devices, setDevices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [annexed, setAnnexed] = useState([]);
  const [constAnnexed, setConstAnnexed] = useState([]);
  const scrollRefs = React.useRef(null);
  const [max, setMax] = useState(5);
  const [favoriteDevices, setFavouriteDevices] = useState([]);
  const [filterFavouriteDevices, setFilterFavouriteDevices] = useState(false);
  const [selectVersions, setselectVersions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [allDevices, setAllDevices] = useState([]);

  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);

  //Filter sets
  const [clients, setClients] = useState([]);
  const [device, setDevice] = useState([]);
  const [productType, setProductType] = useState([]);
  const [product, setProduct] = useState("ALL");
  const [update, setUpdate] = useState("ALL");
  const [availableVersions, setAvailableVersions] = useState([]);
  const [constAnnexedFiltered, setConstAnnexedFiltered] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [configureOpen, setConfigureOpen] = useState(false);

  //FilterOptions
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);

  React.useEffect(() => {
    //getting states to default values
    setDevices([]);
    getAllConfiguredDevices();
  }, []);

  React.useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    setProjects([]);
    fetch(`${environment.host}/user/${userId}/project`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.content) {
          setProjects(data.content);
          setClients(["All", ..._.pluck(data.content, "name").sort()]);
        } else {
          setProjects([]);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  React.useEffect(() => {
    if (projects.length > 0 && devices.length > 0) {
      let deviceAnnex = [];
      devices.map((device) =>
        projects.map((project) => {
          if (project.kits.includes(device.id)) {
            device["client"] = project.name;
            deviceAnnex.push(device);
          }
        })
      );
      setAnnexed(deviceAnnex);
      setConstAnnexed(deviceAnnex);
      setDevice(["All", ..._.pluck(deviceAnnex, "id").sort()]);
    }
  }, [projects, devices]);

  function getFilterOptions() {
    var products = [];
    var statuses = [];

    fetch(`${environment.host}/core/device/deviceParameterConfiguration`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var arr = [];
        arr = data.content;
        arr.map((item) => {
          products.push(item.product.productType);
          statuses.push(item.product.currentVersionStatus);
        });
      })
      .then(() => {
        products = products.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        statuses = statuses.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        setFilterProduct(products);
        setFilterStatus(statuses);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    getFilterOptions();
  }, []);

  const getAllConfiguredDevices = () => {
    fetch(`${environment.host}/core/device/deviceParameterConfiguration`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.content) {
          setAllDevices(data.content);
          setDevices(data.content);
        } else {
          setDevices([]);
          setAllDevices([]);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  React.useEffect(() => {
    const uId = localStorage.getItem("USER_ID");
    fetch(`${environment.host}/core/product/configDetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let datas = data.content.productTypes;
        setProductType([
          "All",
          ..._.pluck(data.content.productTypes[0], "productType").sort(),
        ]);
        setAvailableVersions(["All", ...data.content["available versions"][0]]);
      })
      .catch((err) => {
        setProductType([]);
        setAvailableVersions([]);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const closeAddNew = () => {
    setAddNewOpen(false);
  };

  const upgradeDevices = () => {
    if (selectVersions.length > 0) {
      let finalArray = [];
      finalArray = selectVersions.map((e) => _.omit(e, "id"));
      fetch(`${environment.host}/core/product/version/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
        },
        body: JSON.stringify(finalArray),
      })
        .then((response) => response.json())
        .then((data) => {
          setselectVersions([]);
          setConstAnnexedFiltered([]);
          setUpgradeDevice(false);
          setViewDevice(false);
          getProjects();
          getAllConfiguredDevices();
          if (data.errorCode.length > 0) {
            setReqSuccess(false);
            setOpen(true);
            setErrorMessage(data.message);
            setTimeout(() => {
              setOpen(false);
              setErrorMessage("");
            }, 6000);
          } else {
            setReqSuccess(true);
            setOpen(true);
            setTimeout(() => {
              setOpen(false);
            }, 6000);
          }
        })
        .catch((err) => {
          console.log("err0,", err);
          setselectVersions([]);
          setConstAnnexedFiltered([]);
          getProjects();
          getAllConfiguredDevices();
          setReqSuccess(false);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 6000);
        });
    }
  };

  const handleOpen = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  const handleConfigureOpen = () => {
    setConfigureOpen(true);
  };

  const handleConfigureClose = () => {
    setConfigureOpen(false);
  };

  const [reviewOpen, setReviewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");

  const handleEditOpen = (props) => {
    setEditOpen(true);
    setSelectedDevice(props);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleReviewOpen = (props) => {
    setReviewOpen(true);
    setSelectedDevice(props);
  };

  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleUpdateOpen = (props) => {
    setSelectedDevice(props);
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  function handleFilter(productType = "ALL", update = "ALL") {
    fetch(`${environment.host}/core/device/deviceParameterConfiguration`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (productType == "ALL" && update != "ALL") {
          const filterDevice = data.content.filter(
            (obj) =>
              obj.product &&
              obj.product.currentVersionStatus &&
              obj.product.currentVersionStatus.includes(update)
          );
          setDevices(filterDevice);
        }
        if (productType != "ALL" && update != "ALL") {
          const filterDevice = data.content.filter(
            (obj) =>
              obj.product &&
              obj.product.productType &&
              obj.product.currentVersionStatus &&
              obj.product.productType.includes(productType) &&
              obj.product.currentVersionStatus.includes(update)
          );
          setDevices(filterDevice);
        }
        if (productType != "ALL" && update == "ALL") {
          const filterDevice = data.content.filter(
            (obj) =>
              obj.product &&
              obj.product.productType &&
              obj.product.productType.includes(productType)
          );
          setDevices(filterDevice);
        }

        if (productType == "ALL" && update == "ALL") {
          getAllConfiguredDevices();
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue === "") {
      getAllConfiguredDevices();
    } else {
      setSearch(searchValue);

      if (!isNaN(searchValue) && Number.isInteger(Number(searchValue))) {
        // If searchValue is an integer, get the corresponding device by index
        const index = parseInt(searchValue, 10) - 1;

        if (index >= 0 && index < constAnnexed.length) {
          setDevices([constAnnexed[index]]);
        } else {
          setDevices([]);
        }
      } else {
        // If searchValue is not an integer, filter devices by name or productType
        const searchDevice = allDevices.filter((device) => {
          const nameMatch = device.name.toLowerCase().includes(searchValue);
          const productTypeMatch = device.product.productType.toLowerCase().includes(searchValue);

          return nameMatch || productTypeMatch;
        });
        setDevices(searchDevice);
      }
    }
  };


  return (
    <div className={`testing`}>
      {/* <div>{console.log('selectVersions', selectVersions)}</div> */}
      <Grid container className={classes.container} spacing={2}>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <SearchBar onChange={(e) => handleSearch(e)}/>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            color: "white",
            textAlign: "left",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: seconderyTextColor,
              textTransform: "none",
              border: "0.47999998927116394px solid searchBarBorderColor",
              opacity: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              fontSize: "14px",
              width: "105px",
              height: "40px",
              lineHeight: "20px",
            }}
            onClick={handleOpen}
          >
            <span
              style={{
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {FilterIcon()}
            </span>
            <span>Filter</span>
          </Button>
          <FilterBar
            open={filterOpen}
            productType={product}
            update={update}
            setProductType={setProduct}
            setUpdate={setUpdate}
            onClose={() => handleClose()}
            productOptions={filterProduct}
            updateOptions={filterStatus}
            handleFilter={(productType, update) =>
              handleFilter(productType, update)
            }
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{
            textAlign: "right",
            paddingRight: "3%",
          }}
        >
          <Button
            style={{
              backgroundColor: primaryBlue,
              color: white,
              textTransform: "none",
            }}
            onClick={handleConfigureOpen}
          >
            Configure Device
          </Button>
          <ConfigureDevice
            open={configureOpen}
            onClose={() => handleConfigureClose()}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{marginTop: "20px", marginLeft: "10px"}}
        >
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        ID
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        DEVICE NAME
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        UPDATED STATUS
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        PRODUCT TYPE
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        SERVER IP ADDRESS
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        LAST CONNECTED
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        CREATED
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "left",
                      borderBottomColor: "white",
                      borderTopColor: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{display: "inline-flex"}}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        ACTIONS
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {devices.length > 0 &&
                  devices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((ele, i) => {
                      const originalIndex = allDevices.findIndex(
                        (device) => device.id === ele.id
                      );
                      return (
                        <TableRow key={ele.id}>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {originalIndex + 1}
                            </Typography>
                            {/* <Typography className={classes.tableText}>{ele.product && ele.product.Date && ele.product.Date.toString().slice(0, 10)}</Typography> */}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              paddingTop: "18px",
                              paddingBottom: "18px",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.id}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            {ele.product &&
                              ele.product.currentVersionStatus ===
                              "APPROVED" && (
                                <Chip
                                  variant="outlined"
                                  className={classes.greenChip}
                                  label="Updated"
                                />
                              )}
                            {ele.product &&
                              ele.product.currentVersionStatus ===
                              "PENDING" && (
                                <Chip
                                  variant="outlined"
                                  className={classes.yellowChip}
                                  label="Pending"
                                />
                              )}
                            {ele.product &&
                              ele.product.currentVersionStatus ===
                              "FAILURE" && (
                                <Chip
                                  variant="outlined"
                                  className={classes.redChip}
                                  label="Failure"
                                />
                              )}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.product ? ele.product.productType : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.deviceParameterConfiguration.serverIpAddress ? ele.deviceParameterConfiguration.serverIpAddress : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.product &&
                                ele.product.Date &&
                                timeSince(ele.lastSeen)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography className={classes.tableText}>
                              {ele.deviceParameterConfiguration &&
                                ele.deviceParameterConfiguration.createdDate &&
                                timeSince(
                                  ele.deviceParameterConfiguration.createdDate
                                )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <div style={{display: "inline-flex"}}>
                              <IconButton
                                style={{padding: "0px"}}
                                onClick={() =>
                                  handleReviewOpen({
                                    device: ele,
                                  })
                                }
                              >
                                <ViewIcon/>
                              </IconButton>
                              {reviewOpen === true && (
                                <ViewConfiguration
                                  device={selectedDevice}
                                  open={reviewOpen}
                                  onClose={() => handleReviewClose()}
                                />
                              )}
                              <IconButton
                                style={{padding: "0px", paddingLeft: "10px"}}
                                onClick={() =>
                                  handleEditOpen({
                                    device: ele,
                                  })
                                }
                              >
                                <EditIcon/>
                              </IconButton>
                              {editOpen === true && (
                                <EditConfiguration
                                  device={selectedDevice}
                                  open={editOpen}
                                  onClose={() => handleEditClose()}
                                />
                              )}
                              <IconButton
                                style={{padding: "0px", paddingLeft: "10px"}}
                                onClick={() => {
                                  handleUpdateOpen({
                                    device: ele,
                                  });
                                }}
                              >
                                <ListIcon/>
                              </IconButton>
                              {updateOpen === true && (
                                <HistoryDevice
                                  device={selectedDevice}
                                  open={updateOpen}
                                  onClose={() => handleUpdateClose()}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <AddNewFirmWare open={addNewOpen} onClose={() => closeAddNew()}/>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{textAlign: "left"}}>
            {reqSuccess === true ? "Success" : "Error"}
          </h3>
          {reqSuccess
            ? "Successfully updated"
            : errorMessage.length > 0
              ? errorMessage
              : "Something went wrong"}
        </Alert>
      </Snackbar>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  container: {
    paddingTop: "20px",
    paddingRight: "9px",
  },
  versionSelected: {
    backgroundColor: theme.palette.grey[50],
    border: `2px solid #2A7CED`,
    color: "#2A7CED",
    margin: "6",
  },
  version: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid #707170`,
    color: "#707170",
    margin: "6",
  },
  filter: {
    backgroundColor: white,
    color: seconderyTextColor,
    boxShadow: "none",
  },
  addFirmware: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  versionCircleText: {
    fontSize: "10px",
  },
  versionText: {
    fontSize: "14px",
    color: "#2A7CED",
  },
  greenChip: {
    // backgroundColor: "#03C69F1F",
    border: "0px",
    color: "#03C69F",
    height: "20px",
  },

  versionChip: {
    // backgroundColor: "#e6edf7",
    border: "0px",
    color: "#2a7ced",
    height: "20px",
    // border: `1px solid #2A7CED`,
  },

  availableVersionChip: {
    // backgroundColor: "#fff",
    color: "#707070",
    height: "20px",
    border: "0px",
  },

  yellowChip: {
    // backgroundColor: "#FFB40A1F",
    color: "#FFB40A",
    height: "20px",
    border: "0px",
  },

  redChip: {
    // backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
    border: "0px",
  },

  blueChip: {
    backgroundColor: "#2a7ced",
    color: "#fff",
    height: "20px",
    border: "0px",
  },

  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px",
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px",
  },
}));

const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px",
  },
})(Tooltip);

export const OutlinedStar = (props) => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={props.color}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/></svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill={props.fill || 'none'} stroke={props.color || "none"} ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={props.color}
    >
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path
        d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/>
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
  );
};

export const ContainedStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#EFD494"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>
  );
};

export const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.75"
      height="11.75"
      viewBox="0 0 11.75 11.75"
    >
      <g id="info_2_" data-name="info (2)" transform="translate(-1.625 -1.625)">
        <circle
          id="Ellipse_753"
          data-name="Ellipse 753"
          cx="5.5"
          cy="5.5"
          r="5.5"
          transform="translate(2 2)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
        <line
          id="Line_570"
          data-name="Line 570"
          y1="3"
          transform="translate(7.5 6.444)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
        <line
          id="Line_571"
          data-name="Line 571"
          transform="translate(7.5 4.75)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
      </g>
    </svg>
  );
};

export const Refresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#0000008A"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
    </svg>
  );
};

export const MenuDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#0000008A"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
    </svg>
  );
};

export const ChevronLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  );
};

export const ChevronRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  );
};
