import {FormGroup} from '@angular/forms';

// export function isPackageNameExist(existingPackageName: string[]): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: boolean } | null => {
//     if (existingPackageName.indexOf(control.value) > -1) {
//       return {'isPackageNameExist': true};
//     }
//     return null;
//   };
// }
//
// export function isPackageTopUpExist(existingList: number[]): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: boolean } | null => {
//     if (existingList.indexOf(parseFloat(control.value)) > -1) {
//       return {'isPackageTopUpExist': true};
//     }
//     return null;
//   };
// }

// export function idvFromToValidator(field1, field2) {
//   return (group: FormGroup): { [key: string]: any; } => {
//     const idvFrom = group.controls[field1].value;
//     const idvTo = group.controls[field2].value;
//     if (parseFloat(idvFrom) >= parseFloat(idvTo)) {
//       return {
//         notgreater: true
//       };
//     }
//   };
// }

export function isPasswordMatches(field1, field2) {
  return (group: FormGroup): { [key: string]: any; } => {
    const fieldOne = group.controls[field1].value;
    const fieldTwo = group.controls[field2].value;
    if (fieldTwo !== fieldOne) {
      return {
        notMatch: true
      };
    }
  };
}
