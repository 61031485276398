import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {Alert} from "@material-ui/lab";
import React, {useEffect, useRef, useState} from "react";
import {CloseIcon} from "../CloseButton";
import {TextBar} from "../TextBar";
import {ExpandIcon} from "../../product-types/add-new-firmware/steps/Step2";
import {primaryBlue, primaryGray, textBarColor} from "../colors/colors";
import {environment} from "src/environments/environment";
import DeviceSettingUpdatePopup from "./DeviceSettingUpdatePopup";

const EditConfiguration = (props) => {
  const device = props.device.device.deviceParameterConfiguration;
  const {productType, versionNum, deviceCategory} =
    props.device.device.deviceParameterConfiguration;


  const sortedDetailsArray = props.device.device.deviceParameterConfiguration.remoteConfigurations
    .sort((a, b) => a.id - b.id);


  const [detailsArray, setDetailsArray] = useState(sortedDetailsArray);

  const [openIcon1, setOpenIcon1] = useState(false);
  const [openIcon2, setOpenIcon2] = useState(false);
  const [openIcon3, setOpenIcon3] = useState(false);

  const [parametersToEdit, setParametersToEdit] = useState([]);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [updateMsgOpen, setUpdateMsgOpen] = useState(false);
  const contentContainerRef = useRef(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (updateMsgOpen) {

      contentContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [updateMsgOpen]);

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: "20px",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: "#F4F5F7",
    },
    label: {
      fontSize: "14px",
      color: primaryGray,
      paddingBottom: "5px",
    },
    helperText: {
      color: "red",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 2,
      width: "100%",
    },
    textBarStyle: {
      width: "252px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    textBarMessageStyle: {
      width: "219px",
      backgroundColor: textBarColor,
      height: "44px",
    },
    labelMessage: {
      fontSize: "14px",
      color: primaryGray,
      paddingBottom: "5px",
      width: "121px",
    },
    select: {
      width: '252px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: textBarColor,
      height: "44px",
      padding: '4px',
      fontSize: '14px',
    },
    menuItem: {
      fontSize: '14px',
    },
  }));

  const classes = useStyles();

  const handleSaveClick = () => {
    const networkAndCommunicationParams = {};
    const topicFormatParams = {};
    const messageFormatParams = {};

    detailsArray.forEach((item) => {
      if (item.checkUpdate === true) {
        switch (item.parameterCategory) {
          case "Network & Communication":
            networkAndCommunicationParams[item.parameter] = item.defaultValue;
            break;
          case "Topic Format & Interval":
            topicFormatParams[item.parameter] = item.defaultValue;
            break;
          case "Message Format":
            messageFormatParams[item.parameter] = item.defaultValue;
            break;
          default:
            break;
        }
      }
    });


    const updateHistory = {
      actionBy: localStorage.getItem("USERNAME"),
      networkAndCommiunication: networkAndCommunicationParams,
      topicFormat: topicFormatParams,
      messageFormat: messageFormatParams,
    };

    const data = {
      productType,
      versionNum,
      deviceCategory,
      remoteConfigurations: detailsArray.map(({checkUpdate, ...item}) => ({
        ...item,
        joinParameter: [...item.joinParameter],
      })),
      updateHistory: [updateHistory],
    };


    fetch(
      `${environment.host}/core/device/deviceParameterConfiguration/${props.device.device.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`

        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          (Object.keys(networkAndCommunicationParams).length > 0 ||
            Object.keys(topicFormatParams).length > 0 ||
            Object.keys(messageFormatParams).length > 0) &&
          data.message === "Success"
        ) {
          setUpdateMsgOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });

  };

  const updateDetailsArray = (prevState, index, value) => {
    const parameterToUpdate = prevState[index].parameter;
    const updatedParamsToEdit = parametersToEdit.filter(
      (param) => param !== parameterToUpdate
    );

    setParametersToEdit(updatedParamsToEdit);

    return prevState.map((detail, i) =>
      i === index
        ? {...detail, defaultValue: value, checkUpdate: true}
        : detail
    );
  };

  const updateParamsToAdd = (detailsArray) => {
    return detailsArray
      .filter((item) => item.checkUpdate)
      .reduce((accumulator, item) => {
        const params = item.joinParameter
          .map((param) => {
            const matchingDetail = detailsArray.find((detail) => detail.id === param);
            return matchingDetail && !matchingDetail.checkUpdate ? matchingDetail.parameter : null;
          })
          .filter((param) => param !== null);
        return accumulator.concat(params);
      }, []);
  };

  const handleInputChange = (event, index) => {
    const {value} = event.target;
    setDetailsArray((prevState) => {
      const updatedDetailsArray = updateDetailsArray(prevState, index, value);
      const updatedParamsToAdd = updateParamsToAdd(updatedDetailsArray);
      setParametersToEdit((prevParams) => [
        ...new Set([...prevParams, ...updatedParamsToAdd]),
      ]);

      const parameterWithId8 = updatedDetailsArray.find((item) => item.id == 8);
      const parameterWithId7 = updatedDetailsArray.find((item) => item.id == 7);


      if (
        parameterWithId8 &&
        parameterWithId7 &&
        parameterWithId7.defaultValue !== 0
      ) {
        const isMultiple =
          parameterWithId8.defaultValue % parameterWithId7.defaultValue === 0;

        if (!isMultiple) {
          setShowValidationAlert(true);
        } else {
          setShowValidationAlert(false);
        }
      }
      return updatedDetailsArray;
    });
  };


  const handleSelectChange = (event, index) => {
    const {value} = event.target;
    setDetailsArray((prevState) => {
      const updatedDetailsArray = prevState.map((detail, i) => {
        if (i === index) {
          const updatedDefaultValues = [...detail.defaultValues];
          const oldValueIndex = updatedDefaultValues.indexOf(value);

          if (oldValueIndex !== -1) {
            updatedDefaultValues[oldValueIndex] = detail.defaultValue;
          }
          return {
            ...detail,
            defaultValue: value,
            defaultValues: updatedDefaultValues,
            checkUpdate: true,
          };
        } else {
          return detail;
        }
      });
      const updatedParamsToAdd = updateParamsToAdd(updatedDetailsArray);
      setParametersToEdit((prevParams) => [
        ...new Set([...prevParams, ...updatedParamsToAdd]),
      ]);
      return updatedDetailsArray;
    });
  };


  const keyOrder = ["productType", "versionNum", "deviceCategory", "deviceIds"];

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        style={{width: "766px", height: "591px"}}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: "0.4",
          },
        }}
        PaperProps={{
          // className: classes.paper,
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "1",
            boxShadow: "0px 4px 30px #00000008",
          }}
        >
          <Grid item xs={10} md={10}>
            <DialogTitle>Edit Configuration</DialogTitle>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{display: "flex", justifyContent: "center"}}
          >
            <IconButton onClick={props.onClose}>
              <CloseIcon/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
        <>
          <DialogContent>
            <Grid container className={classes.container}>
              {!updateMsgOpen &&
                keyOrder.map((key, id) => {
                  var label = "";
                  if (key === "productType") {
                    label = "Product Type";
                  } else if (key === "versionNum") {
                    label = "Version";
                  } else if (key === "deviceCategory") {
                    label = "Device Category";
                  } else if (key === "deviceIds") {
                    label = "Device IDs";
                  } else {
                    label = key;
                  }
                  if (device.hasOwnProperty(key)) {
                    return (
                      <Grid
                        key={id}
                        item
                        xs={6}
                        md={6}
                        style={{
                          textAlign: "left",
                          marginBottom: "22px",
                          paddingRight: "2%",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: primaryGray,
                          }}
                        >
                          {label}
                        </Typography>
                        <TextBar
                          disabled={true}
                          variant="outlined"
                          defaultValue={device[key]}
                          error={{error: false}}
                          style={classes.textBarStyle}
                        />
                      </Grid>
                    );
                  }
                })}
              {updateMsgOpen && (
                <div ref={contentContainerRef}>
                  <DeviceSettingUpdatePopup
                    id={props.device.device.id}
                    productType={productType}
                    detailsArray={detailsArray}
                    onClose={() => {
                      setUpdateMsgOpen(false)
                    }}
                  />
                </div>

              )}
              <Grid
                container
                className={classes.container}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} md={4}>
                  <FormLabel
                    style={{
                      color: primaryGray,
                      opacity: "1",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Network & Communication
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Divider className={classes.divider}/>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "ButtonShadow",
                      borderRadius: "80%",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "3px",
                    }}
                    onClick={() => setOpenIcon1(!openIcon1)}
                  >
                    <ExpandIcon rotation={openIcon1}/>
                  </IconButton>
                </Grid>
              </Grid>
              {/* /////////////////////////////////////////////////////////////////// */}

              {openIcon1 === true && (
                <Grid
                  container
                  className={classes.container}
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {detailsArray.map((item, id) => {
                    if (item.parameterCategory === "Network & Communication") {

                      let combinedValues = [];
                      if (item.defaultValues && item.defaultValues.length > 0) {
                        combinedValues = [item.defaultValue, ...item.defaultValues];
                      }

                      return (
                        <Grid
                          key={id}
                          item
                          xs={6}
                          md={6}
                          style={{
                            textAlign: "left",
                            marginBottom: "22px",
                            paddingRight: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: primaryGray,
                              fontSize: "14px",
                              lineHeight: "21px",
                              opacity: "1",
                              width: "89px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.parameter.charAt(0).toUpperCase() + item.parameter.slice(1)}
                          </Typography>
                          {item.id == 6 ? (
                            <div style={{display: "flex", alignItems: "center"}}>
                              <input
                                type={passwordVisible ? "text" : "password"}
                                value={item.defaultValue}
                                onChange={(event) => handleInputChange(event, id)}
                                style={{
                                  width: "228px",
                                  marginLeft: "20px",
                                  backgroundColor: textBarColor,
                                  height: "44px",
                                  border: 'none',
                                  outline: 'none',
                                }}
                              />
                              <IconButton
                                onClick={() => setPasswordVisible(!passwordVisible)}
                                style={{marginLeft: "10px"}}
                              >
                                {passwordVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                              </IconButton>
                            </div>
                          ) : item.defaultValues !== null && item.defaultValues.length > 0 ? (
                            <Select className={classes.select}
                                    value={item.defaultValue}
                                    onChange={(event) => handleSelectChange(event, id)}
                            >
                              {combinedValues.map((value, index) => (
                                <MenuItem className={classes.menuItem} key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextBar
                              style={classes.textBarStyle}
                              defaultValue={item.defaultValue}
                              error={{error: false}}
                              onChange={(event) => handleInputChange(event, id)}
                            />
                          )}
                        </Grid>
                      );
                    }
                  })}


                </Grid>
              )}

              <Grid
                container
                className={classes.container}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} md={4}>
                  <FormLabel
                    style={{
                      color: primaryGray,
                      opacity: "1",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Topic Format & Interval
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Divider className={classes.divider}/>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "ButtonShadow",
                      borderRadius: "80%",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "3px",
                    }}
                    onClick={() => setOpenIcon2(!openIcon2)}
                  >
                    <ExpandIcon rotation={openIcon2}/>
                  </IconButton>
                </Grid>
              </Grid>
              {/* //////////////////////////////////////////////////// */}

              {openIcon2 === true && (
                <Grid container className={classes.container}>
                  {detailsArray.map((item, id) => {
                    if (item.parameterCategory === "Topic Format & Interval") {
                      let combinedValues = [];
                      if (item.defaultValues && item.defaultValues.length > 0) {
                        combinedValues = [item.defaultValue, ...item.defaultValues];
                      }

                      return (
                        <Grid
                          key={id}
                          item
                          xs={6}
                          md={6}
                          style={{
                            textAlign: "left",
                            marginBottom: "22px",
                            paddingRight: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: primaryGray,
                              fontSize: "14px",
                              lineHeight: "21px",
                              opacity: "1",
                              width: "89px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.parameter.charAt(0).toUpperCase() + item.parameter.slice(1)}
                          </Typography>
                          {item.defaultValues !== null && item.defaultValues.length > 0 ? (
                            <Select className={classes.select}
                                    value={item.defaultValue}
                                    onChange={(event) => handleSelectChange(event, id)}
                            >
                              {combinedValues.map((value, index) => (
                                <MenuItem className={classes.menuItem} key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextBar
                              style={classes.textBarStyle}
                              defaultValue={item.defaultValue}
                              error={{error: false}}
                              onChange={(event) => handleInputChange(event, id)}
                            />
                          )}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              )}

              <Grid
                container
                className={classes.container}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} md={4}>
                  <FormLabel
                    style={{
                      color: primaryGray,
                      opacity: "1",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    Message Format
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={7}>
                  <Divider className={classes.divider}/>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "ButtonShadow",
                      borderRadius: "80%",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "3px",
                    }}
                    onClick={() => setOpenIcon3(!openIcon3)}
                  >
                    <ExpandIcon rotation={openIcon3}/>
                  </IconButton>
                </Grid>
              </Grid>
              {/* //////////////////////////////////////////////////// */}

              {openIcon3 === true && (
                <Grid container className={classes.container}>
                  {detailsArray.map((item, id) => {
                    if (item.parameterCategory === "Message Format") {
                      let combinedValues = [];
                      if (item.defaultValues && item.defaultValues.length > 0) {
                        combinedValues = [item.defaultValue, ...item.defaultValues];
                      }

                      return (
                        <Grid
                          key={id}
                          item
                          xs={6}
                          md={6}
                          style={{
                            textAlign: "left",
                            marginBottom: "22px",
                            paddingRight: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: primaryGray,
                              fontSize: "14px",
                              lineHeight: "21px",
                              opacity: "1",
                              width: "89px",
                              height: "44px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.parameter.charAt(0).toUpperCase() + item.parameter.slice(1)}
                          </Typography>
                          {item.defaultValues !== null && item.defaultValues.length > 0 ? (
                            <Select className={classes.select}
                                    value={item.defaultValue}
                                    onChange={(event) => handleSelectChange(event, id)}
                            >
                              {combinedValues.map((value, index) => (
                                <MenuItem className={classes.menuItem} key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            <TextBar
                              style={classes.textBarStyle}
                              defaultValue={item.defaultValue}
                              error={{error: false}}
                              onChange={(event) => handleInputChange(event, id)}
                            />
                          )}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              )}
              <DialogContent
                className={classes.container}
                style={{
                  width: "100%",
                  paddingBottom: "20px",
                  display: "flex",
                }}
              >
                <Grid item xs={12} md={12}>
                  <DialogActions
                    style={{
                      justifyContent: "end",
                      display: "flex  ",
                    }}
                  >
                    <Button
                      onClick={props.onClose}
                      style={{
                        marginRight: "10px",
                        width: "30%",
                        border: "none",
                        color: primaryGray,
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        backgroundColor: primaryBlue,
                        width: "30%",
                        color: "white",
                      }}
                      onClick={() => {
                        handleSaveClick();
                      }}
                      disabled={showValidationAlert}
                    >
                      Update
                    </Button>

                  </DialogActions>
                </Grid>
              </DialogContent>
            </Grid>
          </DialogContent>
        </>
        <div>
          {showValidationAlert && (
            <Snackbar
              key="validationAlert"
              open={true}
              autoHideDuration={6000}
            >
              <Alert severity="error">
                {detailsArray.find((item) => item.id == 8).parameter} is not a multiple
                of {detailsArray.find((item) => item.id == 7).parameter}
              </Alert>
            </Snackbar>
          )}

          {parametersToEdit.length > 0 && (
            <Snackbar
              key="parameterEditAlert"
              open={true}
              autoHideDuration={6000}
              onClose={() => setParametersToEdit([])}
            >
              <Alert severity="warning" onClose={() => setParametersToEdit([])}>
                You need to edit the following parameters: {parametersToEdit.join(",")}
              </Alert>
            </Snackbar>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default EditConfiguration;
