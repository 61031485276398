import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SideNavService} from '../../sidenav/sidenav.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  error;
  selectedMenu;

  constructor(protected router: Router, private sideNavService: SideNavService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.selectedMenu = this.router.url.split('/')[1];
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  closeMenu() {
    const view = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(view)) {
      this.sideNavService.hideSideNav();
    }

  }
}
