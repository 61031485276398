const INIT_STATE = {
  selectedDecoder: {},
  decoderList: []
};

const decoderListReducer = (state = INIT_STATE, action) => {

  switch (action.type) {
    case "DECODER_LIST": {
      return {
        ...state,
        decoderList: action.payload
      }
    }

    case "SELECTED_DECODER": {
      return {
        ...state,
        selectedDecoder: action.payload
      }
    }

    default:
      return state
  }
}

export default decoderListReducer
