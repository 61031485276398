import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../sidenav/sidenav.service';
import {AccountService} from '../account/account.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {FORM_TYPE} from '../models';

declare function toggleClass(id, className): any;

@Component({
  selector: 'app-assigned-device',
  templateUrl: './assigned-device.component.html',
  styleUrls: ['./assigned-device.component.css']
})
export class AssignedDeviceComponent implements OnInit {
  formDevice: FormGroup;
  deviceId = '';
  isViewButton = false;
  isEditToggle = false;
  userName = '';
  currentId = '';
  publishValue = '';
  passwordValue = '';
  devices: any[] = [];
  publishAcl: any[];
  subscribeAcl: any[];
  uniqueIds: string[] = [];
  filteredClients: any[] = [];
  valueObject = {};
  error;
  formType;
  isSubmitted = false;
  selectedDevice;
  searchTerm: string = '';
  filterDevices = [];
  currentUserName;
  currentClientId;
  formConnectivity;
  isEditSubmit = false;
  clientIdArray;
  isClientIdExists;

  constructor(private sideNavService: SideNavService, private accountService: AccountService,
              private toastar: ToastrService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.formDevice = this.formBuilder.group({
      username: [null, Validators.required],
      client_id: [null, Validators.required]
    })
    this.getAllVmqAcl();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  getAllVmqAcl() {
    this.spinner.show()
    this.accountService.getAllVmqAcl().subscribe((response: any) => {
      this.devices = response.content.map((client: any) => ({
        ...client,
        isToggleOn: false,
      }));
      this.clientIdArray = response.content.map((client: any) => client.client_id);
      console.log(this.clientIdArray)
      this.uniqueClients()
      this.spinner.hide()
    })
  }

  updateValue(data, id) {
    this.isEditSubmit = true;
    this.valueObject = {
      username: data.username,
      client_id: data.client_id,
    }
    console.log(data)
    if (this.checkClientId(data.client_id) || !this.formDevice.valid) {
      return
    } else {
      this.spinner.show()
      this.accountService.updatedVernemq(id, this.valueObject).subscribe((result) => {
        this.spinner.hide()
        this.toastar.success('Succesfully updated');
        this.getAllVmqAcl();
        toggleClass('editDevice', 'hide');


      }, error => {
        console.log('Error updating object details:', error);
        this.toastar.error(error.message)
      }, () => {
        this.spinner.hide()
      })
    }
  }

  uniqueClients() {
    this.devices.forEach(client => {
      if (!this.uniqueIds.includes(client.id)) {
        this.uniqueIds.push(client.id);
        this.filteredClients.push(client);
        // console.log(client.id)
      }
    });
    console.log(this.filteredClients)
    return this.filteredClients;
  }

  removeObjectFromArray(objectId) {
    const index = this.devices.findIndex(obj => obj.id === objectId);
    console.log(index)
    if (index !== -1) {
      this.devices.splice(index, 1);
    }
  }

  deleteClientDetails() {
    this.spinner.show()
    this.toggleHideById('deleteModal');
    this.accountService.deleteVmqAcl(this.selectedDevice.id).subscribe((response) => {
        this.removeObjectFromArray(this.selectedDevice.id);
        this.spinner.hide()
        this.toastar.success('Device successfully deleted')

      },
      error => {
        this.toggleHideById('deleteModal');
        console.error('Error removing object:', error);
        this.toastar.error(error.message)
      }
    );
  }


  currentDetails(device) {
    this.formDevice.reset();
    this.formDevice.patchValue(device);
    this.formType = FORM_TYPE.EDIT;
    this.currentId = device.id;
    this.error = null;
    this.isSubmitted = false;
    this.deviceId = device.client_id;
    this.userName = device.username;
    toggleClass('editDevice', 'hide');
  }

  getClientDetails(device) {
    console.log(device)
    this.currentId = device.id;
    this.currentUserName = device.username;
    this.currentClientId = device.client_id;
    this.error = null;
    this.subscribeAcl = device.subscribe_acl.object;
    this.publishAcl = device.publish_acl.object;
    toggleClass('dataDevice', 'hide')
  }

  toggleHideById(id) {
    document.getElementById(id).classList.toggle('hide');
  }

  showFilterDevice() {
    if (this.searchTerm === '') {
      return this.devices;
    } else {
      return this.filterDevices = this.devices.filter(device =>
        device.client_id.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        device.username.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    }
  }

  checkClientId(clientId) {
    this.isClientIdExists = this.clientIdArray.some((id) => id === clientId);
    return this.isClientIdExists
  }
}










