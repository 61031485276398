import * as React from 'react';
import {Card, Grid, Paper, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "10px",
    padding: "20px",
    borderRadius: "20px"
  },
  image: {
    width: "60px",
    height: "60px",
    backgroundColor: "rgba(218, 218, 218, 0.4)",
    padding: "10px",
    marginLeft: "0px",
  },
  container: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#F7F7F7",
    marginTop: "20px",
  },
  numberDiv: {
    marginTop: "10px",
    backgroundColor: "#C4C4C4",
    width: "50%",
    borderRadius: "10px",
    padding: "10px",
    fontSize: "18px"
  },
  button: {
    marginTop: "15px",
    width: "30px",
    height: "30px",
    backgroundColor: "rgba(196, 196, 196, 0.4)"
  }
}));

export function Communication(props) {

  const classes = useStyles()

  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant='h6'>COMMUNICATION ISSUES</Typography>
          </Grid>
          <Grid item xs={12} md={12} style={{marginTop: "20px"}}>
            <Grid container className={classes.container}>
              <Grid item xs={3} md={3}>
                <Paper elevation={0} className={classes.image}>
                  <svg width="100%" height="100%" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.36 5.64001C18.6184 6.8988 19.4753 8.50246 19.8223 10.2482C20.1693 11.994 19.9909 13.8034 19.3096 15.4478C18.6284 17.0921 17.4748 18.4976 15.9948 19.4864C14.5148 20.4752 12.7749 21.0029 10.995 21.0029C9.21509 21.0029 7.47515 20.4752 5.99517 19.4864C4.51519 18.4976 3.36164 17.0921 2.68036 15.4478C1.99909 13.8034 1.82069 11.994 2.16772 10.2482C2.51475 8.50246 3.37162 6.8988 4.63 5.64001"
                      stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11 1V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Paper>
              </Grid>
              <Grid item xs={5} md={5} style={{textAlign: "left"}}>
                <Typography style={{fontWeight: 400, fontSize: "18px", marginTop: "20px"}}>Offline</Typography>
              </Grid>
              <Grid item xs={3} md={3} style={{textAlign: "left"}}>
                <div className={classes.numberDiv} style={{textAlign: "center"}}>{props.comminucationData.offline}</div>
              </Grid>
              <Grid item xs={1} md={1} style={{textAlign: "left"}}>
                <a href={"/devices/" + props.coperateId + "/Offline/False"}>
                  <IconButton className={classes.button}>
                    <NavigateNextIcon/>
                  </IconButton>
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} style={{marginTop: "20px"}}>
            <Grid container className={classes.container}>
              <Grid item xs={3} md={3}>
                <Paper elevation={0} className={classes.image}>
                  <svg width="39" height="31" viewBox="0 0 39 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.5 0.125C26.8185 0.125 33.5425 2.68237 38.8213 6.9515L36.6739 9.60967C31.9811 5.81546 26.0054 3.54167 19.5 3.54167C14.223 3.54167 9.29615 5.03646 5.11756 7.628L19.5 25.4374L29.75 12.7462V18.1838L19.5 30.875L0.178772 6.95321C5.45752 2.68238 12.1781 0.125 19.5 0.125ZM36.5834 27.4583V30.875H33.1667V27.4583H36.5834ZM36.5834 12.0833V24.0417H33.1667V12.0833H36.5834Z"
                      fill="#09121F"/>
                  </svg>
                </Paper>
              </Grid>
              <Grid item xs={5} md={5} style={{textAlign: "left"}}>
                <Typography style={{fontWeight: 400, fontSize: "18px", marginTop: "20px"}}>Online</Typography>
              </Grid>
              <Grid item xs={3} md={3} style={{textAlign: "left"}}>
                <div className={classes.numberDiv} style={{textAlign: "center"}}>{props.comminucationData.online}</div>
              </Grid>
              <Grid item xs={1} md={1} style={{textAlign: "left"}}>
                <a href={"/devices/" + props.coperateId + "/Online/False"}>
                  <IconButton className={classes.button}>
                    <NavigateNextIcon/>
                  </IconButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

const NavigateNextIcon = () => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.875 11.75L7.125 6.5L1.875 1.25" stroke="#474545" strokeWidth="2.5" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  )
}
