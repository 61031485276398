import {
  Breadcrumbs,
  Button,
  createStyles,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  makeStyles,
  NativeSelect,
  Paper,
  Snackbar,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import * as React from "react";
import {useEffect, useState} from "react";
import {primaryBlue, searchBarBorderColor,} from "src/components/sub-components/colors/colors";
import {TextBar} from "src/components/sub-components/TextBar";
import {environment} from "src/environments/environment";
import {ExpandIcon} from "../add-new-firmware/steps/Step2";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

export function EditProduct(props) {
  const classes = useStyles();

  const [allVersionData, setAllVersionData] = useState([]);
  const [defaultProductName, setDefaultProductName] = useState("");
  const [defaultCategory, setDefaultCategory] = useState("");
  const [defaultVersion, setDefaultVersion] = useState("");
  const [defaultBin, setDefaultBin] = useState("");
  const [flowchart, setFlowchart] = useState("");
  const [allVersions, setAllVersions] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [devices, setDevices] = useState({
    id: "pending",
  });
  const [percentage, setPercentage] = useState(0);
  const [fraction, setFraction] = useState("");
  const [status, setStatus] = useState("approve");
  const [versionApproved, setVersionApproved] = useState(false);

  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState("");

  const [remoteConfigVisibility, setRemoteConfigVisibility] = useState(false);
  const [flowChartVisibility, setFlowChartVisibility] = useState(false);
  const [testedVisibility, setTestedVisibility] = useState(false);

  const [parameterVisibility, setParameterVisibility] = useState(-1);
  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);
  const [message, setMessage] = useState("");
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [successDevices, setSuccessDevices] = useState([]);

  const showPrameters = (i) => {
    setParameterVisibility(i);
  };

  const changeVersion = (e) => {
    setParameterVisibility(-1);
    setDefaultVersion(e);
    let parm;
    let bin;
    let flow;
    let devices;
    let approval;
    let numerator = 0;
    let denominator = 0;
    allVersionData
      .filter((obj) => obj.versionNum === e)
      .map((objc) => {
        parm = objc.remoteConfigurations;
        bin = objc.binURL;
        flow = objc.flowChartURL;
        devices = objc.deviceTestResults;
        approval = objc.status;

        Object.keys(objc.deviceTestResults).map((d) => {
          if (objc.deviceTestResults[d] === "SUCCESS") {
            numerator = numerator + 1;
          } else {
            setSuccessDevices(prevSuccessDevices => {
              const uniqueDevices = new Set(prevSuccessDevices);
              uniqueDevices.add(d);
              return [...uniqueDevices];
            });

            denominator = denominator + 1;
          }
        });
      });
    setVersionApproved(approval === "NOT_APPROVED" ? false : true);
    setParameters(parm);
    setDefaultBin(bin);
    setFlowchart(flow);
    setDevices(devices);

    setPercentage((numerator / (denominator + numerator)) * 100);
    setFraction(`${numerator}/${denominator + numerator}`);
  };

  const updateTextInputs = (index, key, value) => {
    setStatus("update");
    let newParm = [...parameters];
    newParm[index][key] = value;
    setParameters(newParm);
  };

  const updateDefaultValues = (index, newValue, oldValue) => {
    setStatus("update");
    let newParm = [...parameters];
    const oldValues = newParm[index]["defaultValues"];

    const updatedDefaultValues = oldValues.map((value) =>
      value === oldValue ? newValue : value
    );

    newParm[index]["defaultValues"] = updatedDefaultValues;
    setParameters(newParm);
  };

  const addDefaultValue = (index) => {
    setStatus("update");
    let newParm = [...parameters];
    const defaultValues = newParm[index]["defaultValues"];
    defaultValues.push('');
    newParm[index]["defaultValues"] = defaultValues;
    setParameters(newParm);
  };

  const removeDefaultValue = (index, valueToRemove) => {
    setStatus("update");
    let newParm = [...parameters];
    const defaultValues = newParm[index]["defaultValues"];
    const updatedDefaultValues = defaultValues.filter((value) => value !== valueToRemove);
    newParm[index]["defaultValues"] = updatedDefaultValues;
    setParameters(newParm);
  };


  const retestDevice = () => {
    //todo:after testing message successfully approved alert coming.that needs to be fixed
    setStatus("retest");
    const finalData = {
      versionNum: defaultVersion,
      status: "NOT_APPROVED",
      binURL: defaultBin,
      remoteConfigurations: parameters,
      flowChartURL: flowchart,
      devices: successDevices,
    };

    fetch(`${environment.host}/core/deviceTest/reTest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
      body: JSON.stringify(finalData)
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Success") {
          setReqSuccess(true);
          setMessage(data.content);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
            setStatus("approve");
          }, 6000);
        } else {
          setStatus("approve");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateOrApprove = () => {
    if (status === "update") {
      const finalData = {
        productType: defaultProductName,
        deviceCategory: defaultCategory,
        version: {
          versionNum: defaultVersion,
          status: "NOT_APPROVED",
          binURL: defaultBin,
          remoteConfigurations: parameters,
          flowChartURL: flowchart,
          devices: Object.keys(devices),
        },
      };
      fetch(`${environment.host}/core/product/${productId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
        body: JSON.stringify(finalData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Success") {
            setReqSuccess(true);
            setOpen(true);
            setTimeout(() => {
              setOpen(false);
              setStatus("approve");
            }, 6000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (status === "approve") {
      fetch(`${environment.host}/core/product/${productId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
        body: JSON.stringify({
          version: {
            versionNum: defaultVersion,
            status: "APPROVED",
            remoteConfigurations: parameters,
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Success") {
            setVersionApproved(true);
            if (data.message === "Success") {
              setReqSuccess(true);
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
              }, 6000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${environment.host}/core/product/${props.editProductId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const verObj = data.content.versions.filter(
          (ver) => ver.status === "NOT_APPROVED"
        );
        if (data.content !== undefined) {
          if (props.type === "edit") {
            setProductId(data.content.id);
            setDefaultProductName(data.content.productType);
            setDefaultCategory(data.content.deviceCategory);

            setDefaultVersion(verObj[0].versionNum);
            setDefaultBin(verObj[0].binURL);
            setFlowchart(verObj[0].flowChartURL);
            setParameters(verObj[0].remoteConfigurations);
            setAllVersionData(verObj);
            setDevices(verObj[0].deviceTestResults);
            setVersionApproved(
              verObj[0].status === "NOT_APPROVED" ? false : true
            );

            let numerator = 0;
            let denominator = 0;
            Object.keys(verObj[0].deviceTestResults).map((d) => {
              if (verObj[0].deviceTestResults[d] === "SUCCESS") {
                numerator = numerator + 1;
              } else {
                setSuccessDevices(prevSuccessDevices => {
                  const uniqueDevices = new Set(prevSuccessDevices);
                  uniqueDevices.add(d);
                  return [...uniqueDevices];
                });

                denominator = denominator + 1;
              }
            });
            setPercentage((numerator / (denominator + numerator)) * 100);
            setFraction(`${numerator}/${denominator + numerator}`);

            let versions = [];
            verObj.map((obj) => {
              versions.push(obj.versionNum);
            });
            setAllVersions(versions);

            setLoading(false);
          }
          if (props.type === "view") {
            setProductId(data.content.id);
            setDefaultProductName(data.content.productType);
            setDefaultCategory(data.content.deviceCategory);
            setDefaultVersion(data.content.versions[0].versionNum);
            setDefaultBin(data.content.versions[0].binURL);
            setFlowchart(data.content.versions[0].flowChartURL);
            setParameters(data.content.versions[0].remoteConfigurations);
            setAllVersionData(data.content.versions);
            setDevices(data.content.versions[0].deviceTestResults);
            setVersionApproved(
              data.content.versions[0].status === "NOT_APPROVED" ? false : true
            );

            let numerator = 0;
            let denominator = 0;
            Object.keys(data.content.versions[0].deviceTestResults).map((d) => {
              if (data.content.versions[0].deviceTestResults[d] === "SUCCESS") {
                numerator = numerator + 1;
              } else {
                denominator = denominator + 1;
              }
            });
            setPercentage((numerator / (denominator + numerator)) * 100);
            setFraction(`${numerator}/${denominator + numerator}`);

            let versions = [];
            data.content.versions.map((obj) => {
              versions.push(obj.versionNum);
            });
            setAllVersions(versions);

            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{textAlign: "left", padding: "20px", cursor: "pointer"}}
          onClick={props.onClose}
        >
          <Paper elevation={0} className={classes.breadcrumbs}>
            <Breadcrumbs separator={<Arrow/>}>
              <HomeIcon/>
              <Typography
                display="inline"
                style={{fontSize: "14px", color: "#003847"}}
              >
                Product Type
              </Typography>
              <Typography
                display="inline"
                style={{fontSize: "14px", color: "#003847"}}
              >
                {props.setEditProductName}
              </Typography>
            </Breadcrumbs>
          </Paper>
        </Grid>

        {loading === false ? (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: "left",
              padding: "20px",
              paddingLeft: "25px",
            }}
          >
            <Paper elevation={0}>
              {props.type === "edit" ? (
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.firmware}
                      style={{fontSize: "16px"}}
                    >
                      Edit Firmware
                    </Typography>
                    <Divider style={{width: "100%", marginBottom: "10px"}}/>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div style={{paddingBottom: "10px", paddingTop: "20px"}}>
                      <Typography
                        display="inline"
                        style={{margin: "20px", color: "#1C1C1C"}}
                      >
                        Product Type
                      </Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <TextBar
                        error={{error: false}}
                        defaultValue={defaultProductName}
                        disabled={true}
                      />
                    </div>
                    <br/>
                    <div style={{paddingBottom: "10px"}}>
                      <Typography
                        display="inline"
                        style={{margin: "20px", color: "#1C1C1C"}}
                      >
                        Device Category
                      </Typography>
                      <TextBar
                        error={{error: false}}
                        defaultValue={defaultCategory}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{paddingBottom: "10px", paddingTop: "20px"}}>
                      <Typography
                        display="inline"
                        style={{margin: "20px", color: "#1C1C1C"}}
                      >
                        Version
                      </Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControl>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={defaultVersion}
                          className={classes.input}
                          onChange={(e) => changeVersion(e.target.value)}
                          input={<BootstrapInput/>}
                        >
                          <option value="" disabled>
                            Select version number
                          </option>
                          {allVersions.map((v, i) => (
                            <option value={v} key={i}>
                              {v}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <br/>
                    <div style={{paddingBottom: "10px"}}>
                      <Typography
                        display="inline"
                        style={{margin: "20px", color: "#1C1C1C"}}
                      >
                        Bin File
                      </Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <TextBar
                        error={{error: false}}
                        defaultValue={defaultBin}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.firmware}
                      style={{fontSize: "16px"}}
                    >
                      View Firmware
                    </Typography>
                    <Divider style={{width: "100%", marginBottom: "10px"}}/>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Product Type
                    </Typography>
                    <br/>
                    <br/>
                    <Typography
                      display="inline"
                      style={{
                        margin: "20px",
                        color: "#8691A2",
                        marginTop: "20px",
                      }}
                    >
                      {defaultProductName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Device Category
                    </Typography>
                    <br/>
                    <br/>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#8691A2"}}
                    >
                      {defaultCategory}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Bin File
                    </Typography>
                    <br/>
                    <br/>
                    <Tooltip title={defaultBin}>
                      <Typography
                        display="inline"
                        style={{margin: "20px", color: "#8691A2"}}
                      >
                        {defaultBin !== null &&
                          defaultBin.substring(0, 10) + "..."}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      display="inline"
                      style={{margin: "20px", color: "#1C1C1C"}}
                    >
                      Version
                    </Typography>
                    <br/>
                    <br/>
                    <FormControl>
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={defaultVersion}
                        className={classes.input}
                        onChange={(e) => changeVersion(e.target.value)}
                        input={<BootstrapInput/>}
                      >
                        <option value="" disabled>
                          Select version number
                        </option>
                        {allVersions.map((v, i) => (
                          <option value={v} key={i}>
                            {v}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Paper elevation={1} style={{margin: "20px"}}>
                <Grid container style={{padding: "20px"}}>
                  <Grid item xs={1} md={1} style={{textAlign: "left"}}>
                    <div className={classes.iconDivRed}>
                      <RemoteConfig/>
                    </div>
                  </Grid>
                  <Grid item xs={10} md={3} style={{paddingLeft: "5px"}}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#324054",
                        paddingTop: "6px",
                      }}
                    >
                      Remote Configuration
                    </Typography>
                  </Grid>
                  <Grid item md={7}></Grid>
                  <Grid item xs={1} md={1}>
                    <IconButton
                      onClick={() =>
                        setRemoteConfigVisibility(!remoteConfigVisibility)
                      }
                    >
                      <div className={classes.subIconButtonDiv}>
                        <ExpandIcon rotation={remoteConfigVisibility}/>
                      </div>
                    </IconButton>
                  </Grid>

                  {remoteConfigVisibility === true && (
                    <Grid container>
                      <Divider style={{width: "100%", marginBottom: "20px"}}/>
                      {parameters.length === 0 && <h5>No configuration parameters added.</h5>}
                      {parameters.map((parameter, i) => (
                        <Hidden key={i}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              paddingLeft: "30px",
                              paddingBottom: "20px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                onClick={() => showPrameters(i)}
                                style={{cursor: "pointer"}}
                              >
                                <Typography style={{fontWeight: "bold"}}>
                                  {parameter.parameter}
                                </Typography>
                              </Grid>
                              {parameterVisibility === i ? (
                                <Grid
                                  item
                                  xs={6}
                                  md={4}
                                  style={{textAlign: "left"}}
                                ></Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={6}
                                  md={4}
                                  style={{textAlign: "left"}}
                                >
                                  <TextBar
                                    disabled={versionApproved}
                                    error={{error: false}}
                                    defaultValue={parameter.defaultValue}
                                    onChange={(e) =>
                                      updateTextInputs(i, "defaultValue", e.target.value)
                                    }
                                  />
                                </Grid>
                              )}
                              <Grid item md={4}></Grid>
                            </Grid>
                          </Grid>

                          {parameterVisibility === i &&
                            props.type === "edit" && (
                              <Grid
                                container
                                style={{
                                  backgroundColor: "#F9F9F9",
                                  padding: "20px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Id
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextBar
                                        disabled={versionApproved}
                                        error={{error: false}}
                                        defaultValue={parameter.id}
                                        onChange={(e) =>
                                          updateTextInputs(i, "id", e.target.value)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Input Type
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormControl>
                                        <NativeSelect
                                          disabled={versionApproved}
                                          id="demo-customized-select-native"
                                          value={parameters[i].inputType}
                                          style={{width: "100%"}}
                                          onChange={(e) =>
                                            updateTextInputs(
                                              i,
                                              "inputType",
                                              e.target.value
                                            )
                                          }
                                          input={<BootstrapInput/>}
                                        >
                                          <option value="" disabled>
                                            Select input type
                                          </option>
                                          <option value="text">Text</option>
                                          <option value="json">Json</option>
                                          <option value="Html">Html</option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Input Format
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <FormControl>
                                        <NativeSelect
                                          disabled={versionApproved}
                                          id="demo-customized-select-native"
                                          value={parameter.format}
                                          style={{width: "100%"}}
                                          onChange={(e) =>
                                            updateTextInputs(i, "format", e.target.value)
                                          }
                                          input={<BootstrapInput/>}
                                        >
                                          <option value="" disabled>
                                            Select format type
                                          </option>
                                          <option value="String">string</option>
                                          <option value="int">int</option>
                                          <option value="double">double</option>
                                          <option value="float">float</option>
                                          <option value="char">char</option>
                                        </NativeSelect>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        display="inline"
                                        style={{
                                          margin: "20px",
                                          color: "#63738C",
                                        }}
                                      >
                                        Default
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextBar
                                        disabled={versionApproved}
                                        error={{error: false}}
                                        defaultValue={parameter.defaultValue}
                                        onChange={(e) =>
                                          updateTextInputs(
                                            i,
                                            "defaultValue",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {parameter.defaultValues && (
                                  <Grid item xs={12} md={6} style={{marginTop: "20px", paddingLeft: "10px"}}>
                                    <Grid container>
                                      <Grid item xs={12} md={12}>
                                        <Typography
                                          display="inline"
                                          style={{
                                            margin: "20px",
                                            color: "#63738C",
                                          }}
                                        >
                                          Default Values
                                        </Typography>
                                        <IconButton
                                          style={{marginLeft: "10px"}}
                                          onClick={() => addDefaultValue(i)}
                                        >
                                          <AddCircleOutlineIcon style={{fontSize: "24px", color: "blue"}}/>
                                        </IconButton>
                                      </Grid>
                                      {parameter.defaultValues.map((value, index) => (
                                        <Grid item xs={12} md={12} key={index} style={{marginBottom: "10px"}}>
                                          <div style={{display: "flex", alignItems: "center"}}>
                                            <TextBar
                                              disabled={versionApproved}
                                              error={{error: false}}
                                              defaultValue={value}
                                              onChange={(e) => updateDefaultValues(i, e.target.value, value)}
                                            />
                                            <IconButton
                                              style={{marginLeft: "10px"}}
                                              onClick={() => removeDefaultValue(i, value)}
                                            >
                                              <RemoveCircleOutlineIcon style={{fontSize: "24px", color: "red"}}/>
                                            </IconButton>
                                          </div>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Grid>
                                )}


                              </Grid>
                            )}
                          {parameterVisibility === i &&
                            props.type === "view" && (
                              <Grid
                                container
                                style={{
                                  backgroundColor: "#F9F9F9",
                                  padding: "20px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Id
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameter.id}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Input Type
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameters[i].inputType}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Input Format
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameter.format}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#63738C",
                                    }}
                                  >
                                    Default
                                  </Typography>
                                  <br/>
                                  <br/>
                                  <Typography
                                    display="inline"
                                    style={{
                                      margin: "20px",
                                      color: "#8691A2",
                                    }}
                                  >
                                    {parameter.defaultValue}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                        </Hidden>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Paper elevation={1} style={{margin: "20px"}}>
                <Grid container style={{padding: "20px"}}>
                  <Grid item xs={1} md={1} style={{textAlign: "left"}}>
                    <div className={classes.iconDivGreen}>
                      <TechnologyFlow/>
                    </div>
                  </Grid>
                  <Grid item xs={10} md={3} style={{paddingLeft: "5px"}}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#324054",
                        paddingTop: "6px",
                      }}
                    >
                      Technology Flowchart
                    </Typography>
                  </Grid>
                  <Grid item md={7}></Grid>
                  <Grid item xs={1} md={1}>
                    <IconButton
                      onClick={() =>
                        setFlowChartVisibility(!flowChartVisibility)
                      }
                    >
                      <div className={classes.subIconButtonDiv}>
                        <ExpandIcon rotation={flowChartVisibility}/>
                      </div>
                    </IconButton>
                  </Grid>
                  {flowChartVisibility === true && (
                    <Grid container>
                      <Divider
                        style={{width: "100%", marginBottom: "20px"}}
                      />
                      <h5>
                        {flowchart === "" || flowchart === null
                          ? "Flow chart not found."
                          : flowchart}
                      </h5>
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Paper elevation={1} style={{margin: "20px"}}>
                <Grid container style={{padding: "20px"}}>
                  <Grid item xs={1} md={1} style={{textAlign: "left"}}>
                    <div className={classes.iconDivBlue}>
                      <TestedDevice/>
                    </div>
                  </Grid>
                  <Grid item xs={10} md={3} style={{paddingLeft: "5px"}}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#324054",
                        paddingTop: "6px",
                      }}
                    >
                      Tested Device
                    </Typography>
                  </Grid>
                  <Grid item md={7}></Grid>
                  <Grid item xs={1} md={1}>
                    <IconButton
                      onClick={() => setTestedVisibility(!testedVisibility)}
                    >
                      <div className={classes.subIconButtonDiv}>
                        <ExpandIcon rotation={testedVisibility}/>
                      </div>
                    </IconButton>
                  </Grid>
                  {testedVisibility === true && (
                    <Grid container>
                      <Divider
                        style={{width: "100%", marginBottom: "20px"}}
                      />
                      <Grid
                        item
                        xs={12}
                        md={6}
                        hidden={
                          props.type === "view" || versionApproved === true
                            ? true
                            : false
                        }
                      >
                        <Typography
                          style={{
                            color: "#1C1C1C",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          display="inline"
                        >
                          Do you want to retest the failed devices ?
                        </Typography>
                        &nbsp;&nbsp;&nbsp;
                        <div
                          style={{display: "inline", cursor: "pointer"}}
                          onClick={retestDevice}
                        >
                          <span
                            style={{
                              color: "#2A7CED",
                              fontSize: "14px",
                              textDecoration: isMouseOver
                                ? "underline"
                                : "none",
                            }}
                            onMouseEnter={() => setIsMouseOver(true)}
                            onMouseLeave={() => setIsMouseOver(false)}
                          >
                            Retest Here
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} style={{paddingTop: "30px"}}>
                        <Grid container>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            style={{textAlign: "center"}}
                          >
                            <Typography
                              style={{
                                color: "#1C1C1C",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                              }}
                            >
                              ID
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            style={{textAlign: "center"}}
                          >
                            <Typography
                              style={{
                                color: "#1C1C1C",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                              }}
                            >
                              DEVICE NAME
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            style={{textAlign: "center"}}
                          >
                            <Typography
                              style={{
                                color: "#1C1C1C",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "20px",
                              }}
                            >
                              TEST STATUS
                            </Typography>
                          </Grid>
                        </Grid>
                        {Object.keys(devices).map((device, j) => (
                          <Grid container key={j}>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  j % 2 === 0 || j / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#3C3C3C",
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                {"0" + (j + 1)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  j % 2 === 0 || j / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#3C3C3C",
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                {device}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                textAlign: "center",
                                backgroundColor:
                                  j % 2 === 0 || j / 2 === 0
                                    ? "#F9F9F9"
                                    : "white",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#3C3C3C",
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                {devices[device]}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Paper>

              {testedVisibility === true && props.type === "edit" && (
                <Paper elevation={1} style={{margin: "20px"}}>
                  <Grid container style={{padding: "20px"}}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{paddingTop: "20px", paddingBottom: "20px"}}
                    >
                      <Typography
                        display="inline"
                        style={{fontSize: "16px", fontWeight: "bold"}}
                      >
                        Test Passed Rate -{" "}
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#2A7CED",
                        }}
                      >
                        {percentage}%
                      </Typography>
                      &nbsp;&nbsp;&nbsp;
                      <Typography
                        display="inline"
                        style={{fontSize: "14px", color: "#707070"}}
                      >{`(${fraction} Devices passed the test)`}</Typography>
                    </Grid>
                    <Grid
                      hidden={versionApproved}
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "right",
                      }}
                    >
                      <Button
                        style={{color: "#707070"}}
                        onClick={props.onClose}
                      >
                        {status === "update" ? "CANCEL" : "REJECT"}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        disabled={versionApproved || (status !== "update" && percentage < 80)}
                        style={{
                          backgroundColor: primaryBlue,
                          color: "white",
                        }}
                        onClick={updateOrApprove}
                      >
                        {status === "update" ? "UPDATE" : "APPROVE"}
                      </Button>
                      <br/>
                      <Typography
                        style={{fontSize: "12px", color: "#EE3939"}}
                      >
                        Passed rate should be greater than 80% to approve the
                        version
                      </Typography>
                    </Grid>
                    <Grid
                      hidden={!versionApproved}
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        style={{fontSize: "12px", color: "#EE3939"}}
                      >
                        Version already approved !
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              )}
              {testedVisibility === true && props.type === "view" && (
                <Paper elevation={1} style={{margin: "20px"}}>
                  <Grid container style={{padding: "20px"}}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{fontSize: "16px", fontWeight: "bold"}}
                      >
                        Test Passed Rate -{" "}
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#2A7CED",
                        }}
                      >
                        {percentage}%
                      </Typography>
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        <Typography
                          display="inline"
                          style={{fontSize: "14px", color: "#707070"}}
                        >{`(${fraction} Devices passed the test, Approved By - `}</Typography>
                        <Typography
                          display="inline"
                          style={{
                            fontSize: "14px",
                            color: "#707070",
                            fontWeight: "bold",
                          }}
                        >{`John)`}</Typography>
                      </span>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Paper>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert severity={reqSuccess === true ? "success" : "warning"}>
          <h3 style={{textAlign: "left"}}>
            {reqSuccess === true ? "Success" : "Error"}
          </h3>
          {reqSuccess === true && status === "update" && "Successfully updated"}
          {reqSuccess === true &&
            status === "approve" &&
            "Successfully approved"}
          {reqSuccess === true && status === "retest" && message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbs: {
    backgroundColor: "white",
  },
  firmware: {
    color: primaryBlue,
    padding: "25px",
  },
  iconDivRed: {
    width: "30px",
    height: "30px",
    backgroundColor: "#EE393914",
    padding: "5px",
  },
  iconDivGreen: {
    width: "30px",
    height: "30px",
    backgroundColor: "#03C69F08",
    padding: "5px",
  },
  iconDivBlue: {
    width: "30px",
    height: "30px",
    backgroundColor: "#2A49AE14",
    padding: "5px",
  },
  subIconButtonDiv: {
    backgroundColor: "#F5F5F5",
    width: "25px",
    height: "25px",
    borderRadius: "100%",
    paddingTop: "0px",
  },
  input: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  inputs: {
    minWidth: "100%",
    maxWidth: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
}));

const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      style={{marginTop: "5px", marginLeft: "10px"}}
      viewBox="0 0 24 24"
      width="24px"
      fill="#707070"
    >
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/>
    </svg>
  );
};
const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#707070"
    >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
    </svg>
  );
};
const RemoteConfig = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="error_outline_black_24dp_1_"
        data-name="error_outline_black_24dp (1)"
        d="M11,15h2v2H11Zm0-8h2v6H11Zm.99-5A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        transform="translate(-2 -2)"
        fill="#ee3939"
      />
    </svg>
  );
};
const TechnologyFlow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="Group_4911" data-name="Group 4911" transform="translate(-3 -3)">
        <path
          id="Path_13300"
          data-name="Path 13300"
          d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V5H19Z"
          fill="#03c69f"
        />
        <rect
          id="Rectangle_2210"
          data-name="Rectangle 2210"
          width="2"
          height="5"
          transform="translate(7 12)"
          fill="#03c69f"
        />
        <rect
          id="Rectangle_2211"
          data-name="Rectangle 2211"
          width="2"
          height="10"
          transform="translate(15 7)"
          fill="#03c69f"
        />
        <rect
          id="Rectangle_2212"
          data-name="Rectangle 2212"
          width="2"
          height="3"
          transform="translate(11 14)"
          fill="#03c69f"
        />
        <rect
          id="Rectangle_2213"
          data-name="Rectangle 2213"
          width="2"
          height="2"
          transform="translate(11 10)"
          fill="#03c69f"
        />
      </g>
    </svg>
  );
};
const TestedDevice = () => {
  return (
    <svg
      id="devices_black_24dp_1_"
      data-name="devices_black_24dp (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_13301"
        data-name="Path 13301"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Path_13302"
        data-name="Path 13302"
        d="M4,6H22V4H4A2.006,2.006,0,0,0,2,6V17H0v3H14V17H4ZM23,8H17a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V9A1,1,0,0,0,23,8Zm-1,9H18V10h4Z"
        fill="#2a49ae"
      />
    </svg>
  );
};
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    },
  })
)(InputBase);
