import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, Grid, Typography} from "@material-ui/core"
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Pie} from 'react-chartjs-2';
import 'chartjs-plugin-labels';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "95%",
    margin: "20px",
    marginTop: "20px",
    padding: "20px",
    borderRadius: "20px"
  },
  text: {
    fontSize: "14px",
    color: "#5E5873",
    marginBottom: "10px"
  },
  text2: {
    fontSize: "14px",
    marginBottom: "10px",
    marginLeft: "20px",
    marginTop: "2px"
  },
}));

export function TestSuccessCriteria(props) {
  const classes = useStyles()
  const perentageArray = [{color: "#000000", val: "0-20"},
    {color: "#838383", val: "21-40"},
    {color: "#CAC9C9", val: "41-60"},
    {color: "#DADADA", val: "61-80"},
    {color: "#C2C9D1", val: "81-100"}
  ]

  const [data, setData] = useState({
    datasets: [
      {
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          '#000000',
          '#838383',
          '#CAC9C9',
          '#DADADA',
          '#C2C9D1',
        ],
        borderWidth: 0,
      },
    ],
  })

  useEffect(() => {
    const values = [
      parseFloat(props.summary.testSuccessRate["0-20"]),
      parseFloat(props.summary.testSuccessRate["21-40"]),
      parseFloat(props.summary.testSuccessRate["41-60"]),
      parseFloat(props.summary.testSuccessRate["61-80"]),
      parseFloat(props.summary.testSuccessRate["81-100"]),
    ]
    setData(
      {
        datasets: [
          {
            data: values,
            backgroundColor: [
              '#000000',
              '#838383',
              '#CAC9C9',
              '#DADADA',
              '#C2C9D1',
            ],
            borderWidth: 0,
          },
        ],
      }
    )
  }, [props.mainCase, props.summary])
  return (
    <div>
      <Card elevation={1} className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} style={{textAlign: "left"}}>
            <Typography variant='h6'>TEST SUCCESS CRITERIA</Typography>
          </Grid>
          <Grid item xs={12} md={9} style={{marginTop: "20px", marginBottom: "20px"}}>
            <Pie data={data} options={{
              maintainAspectRatio: true,
              responsive: true,
              tooltips: {enabled: false},
              plugins: {
                labels: {
                  render: 'percentage',
                  fontColor: 'white',
                  position: 'border',
                  fontSize: 14
                },
              }
            }}/>
          </Grid>
          <Grid item xs={12} md={3} style={{textAlign: "left", marginTop: "20px", marginBottom: "20px"}}>
            <Typography className={classes.text}>Test success rate</Typography>
            {
              perentageArray.map((obj, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item xs={1} md={1} style={{marginTop: "5px"}}>
                      <FiberManualRecordIcon color={obj.color}/>
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <Typography className={classes.text2}>{obj.val}</Typography>
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

const FiberManualRecordIcon = (props) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="7.02" cy="7.7841" rx="7.02" ry="7.48411" fill={props.color}/>
    </svg>
  )
}
