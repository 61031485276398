import * as React from 'react';
import {Button, Divider, Grid, IconButton, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {primaryBlue, white} from 'src/components/sub-components/colors/colors';

export function Step2(props) {
  const classes = useStyles()
  const parameters = ["Host", "Port", "Message Format", "Time intervel"]


  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={12} style={{textAlign: "left"}}>
        <Typography className={classes.clone}>
          <span style={{fontWeight: "bold"}}>Do you want to clone fom the system ? </span>
          <span style={{color: primaryBlue}}>Clone Here</span>
        </Typography>
      </Grid>

      <Grid item xs={5} md={5} style={{textAlign: "left", paddingTop: "20px"}}>
        <Typography style={{color: "#324054A6"}}>Network & Commiunication</Typography>
      </Grid>
      <Grid item xs={6} md={6} style={{textAlign: "left", paddingTop: "20px"}}>
        <Divider style={{marginTop: "12px", width: "100%"}}/>
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openNetworkVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIcon rotation={props.networkVisibility}/>
          </div>
        </IconButton>
      </Grid>

      {
        props.networkVisibility ?
          <Grid container>
            <Grid item xs={12} md={12} style={{textAlign: "right"}}>
              <Button
                className={classes.addButton}
                variant='outlined'
                onClick={() => props.open("network", "add-new", -1)}
                startIcon={<AddIcon/>}
              >ADD</Button>
            </Grid>
            <Grid hidden={props.parameters.filter((p) => p.mainType === "network").length > 0 ? false : true} item
                  xs={6} md={6}
                  style={{textAlign: "left", paddingTop: "25px", paddingLeft: "10px", paddingBottom: "20px"}}>
              <Typography className={classes.topic}>PARAMETERS</Typography>
            </Grid>
            <Grid hidden={props.parameters.filter((p) => p.mainType === "network").length > 0 ? false : true} item
                  xs={6} md={6} style={{textAlign: "center", paddingTop: "25px"}}>
              <Typography className={classes.topic}>ACTION</Typography>
            </Grid>
            {
              props.parameters.map((parameter, i) => {

                return parameter.mainType === "network" &&
                  <Grid container key={i}
                        style={{
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          textAlign: "left",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>
                    <Grid item xs={6} md={6}>
                      <Typography style={{paddingLeft: "10px"}}>{parameter.parameter}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                      <IconButton style={{padding: "0px"}} onClick={() => props.open("network", "view", i)}>
                        <ViewIcon/>
                      </IconButton>
                      <IconButton style={{marginLeft: "10px", padding: "0px"}}
                                  onClick={() => props.open("network", "edit", i)}>
                        <EditIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
              })
            }
          </Grid>
          : <></>
      }

      <Grid item xs={5} md={5} style={{textAlign: "left", paddingTop: "20px"}}>
        <Typography style={{color: "#324054A6"}}>Topic Format & Interval</Typography>
      </Grid>
      <Grid item xs={6} md={6} style={{textAlign: "left", paddingTop: "20px"}}>
        <Divider style={{marginTop: "12px", width: "100%"}}/>
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openTopicVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIcon rotation={props.topicVisibility}/>
          </div>
        </IconButton>
      </Grid>

      {
        props.topicVisibility ?
          <Grid container>
            <Grid item xs={12} md={12} style={{textAlign: "right"}}>
              <Button
                className={classes.addButton}
                variant='outlined'
                onClick={() => props.open("topic", "add-new", -1)}
                startIcon={<AddIcon/>}
              >ADD</Button>
            </Grid>
            <Grid hidden={props.parameters.filter((p) => p.mainType === "topic").length > 0 ? false : true} item xs={6}
                  md={6} style={{textAlign: "left", paddingTop: "25px", paddingLeft: "10px", paddingBottom: "20px"}}>
              <Typography className={classes.topic}>PARAMETERS</Typography>
            </Grid>
            <Grid hidden={props.parameters.filter((p) => p.mainType === "topic").length > 0 ? false : true} item xs={6}
                  md={6} style={{textAlign: "center", paddingTop: "25px"}}>
              <Typography className={classes.topic}>ACTION</Typography>
            </Grid>
            {
              props.parameters.map((parameter, i) => {

                return parameter.mainType === "topic" &&
                  <Grid container key={i}
                        style={{
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          textAlign: "left",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>
                    <Grid item xs={6} md={6}>
                      <Typography style={{paddingLeft: "10px"}}>{parameter.parameter}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                      <IconButton style={{padding: "0px"}} onClick={() => props.open("topic", "view", i)}>
                        <ViewIcon/>
                      </IconButton>
                      <IconButton style={{marginLeft: "10px", padding: "0px"}}
                                  onClick={() => props.open("topic", "edit", i)}>
                        <EditIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
              })
            }
          </Grid>
          : <></>
      }


      <Grid item xs={5} md={5} style={{textAlign: "left", paddingTop: "20px"}}>
        <Typography style={{color: "#324054A6"}}>Message Format</Typography>
      </Grid>
      <Grid item xs={6} md={6} style={{textAlign: "left", paddingTop: "20px"}}>
        <Divider style={{marginTop: "12px", width: "100%"}}/>
      </Grid>
      <Grid item xs={1} md={1} style={{textAlign: "left", paddingTop: "5px"}}>
        <IconButton onClick={() => props.openMessageVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIcon rotation={props.messageVisibility}/>
          </div>
        </IconButton>
      </Grid>

      {
        props.messageVisibility ?
          <Grid container>
            <Grid item xs={12} md={12} style={{textAlign: "right"}}>
              <Button
                className={classes.addButton}
                variant='outlined'
                onClick={() => props.open("message", "add-new", -1)}
                startIcon={<AddIcon/>}
              >ADD</Button>
            </Grid>
            <Grid hidden={props.parameters.filter((p) => p.mainType === "message").length > 0 ? false : true} item
                  xs={6} md={6}
                  style={{textAlign: "left", paddingTop: "25px", paddingLeft: "10px", paddingBottom: "20px"}}>
              <Typography className={classes.topic}>PARAMETERS</Typography>
            </Grid>
            <Grid hidden={props.parameters.filter((p) => p.mainType === "message").length > 0 ? false : true} item
                  xs={6} md={6} style={{textAlign: "center", paddingTop: "25px"}}>
              <Typography className={classes.topic}>ACTION</Typography>
            </Grid>
            {
              props.parameters.map((parameter, i) => {

                return parameter.mainType === "message" &&
                  <Grid container key={i}
                        style={{
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          textAlign: "left",
                          paddingTop: "10px",
                          paddingBottom: "10px"
                        }}>
                    <Grid item xs={6} md={6}>
                      <Typography style={{paddingLeft: "10px"}}>{parameter.parameter}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                      <IconButton style={{padding: "0px"}} onClick={() => props.open("message", "view", i)}>
                        <ViewIcon/>
                      </IconButton>
                      <IconButton style={{marginLeft: "10px", padding: "0px"}}
                                  onClick={() => props.open("message", "edit", i)}>
                        <EditIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
              })
            }
          </Grid>
          : <></>
      }

    </Grid>
  )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    container: {
      paddingTop: "20px"
    },
    clone: {
      fontSize: "14px"
    },
    subIconButtonDiv: {
      backgroundColor: "#F5F5F5",
      width: "25px",
      height: "25px",
      borderRadius: "100%",
      textAlign: "center"
    },
    topic: {
      fontWeight: "bold",
      fontSize: "14px"
    },
    addButton: {
      backgroundColor: white,
      color: primaryBlue,
      borderColor: primaryBlue
    }
  }),
);

export const ExpandIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{transform: props.rotation ? "rotate(180deg)" : "rotate(0deg)"}}
         height="24px" viewBox="0 0 24 24" width="24px" fill="#91949E">
      <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/>
    </svg>
  )
}

export const ViewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="15" viewBox="0 0 20.25 15">
      <g id="eye_1_" data-name="eye (1)" transform="translate(0.5 0.5)">
        <path id="Path_13136" data-name="Path 13136" d="M1,11s3.5-7,9.625-7,9.625,7,9.625,7-3.5,7-9.625,7S1,11,1,11Z"
              transform="translate(-1 -4)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.6"/>
        <circle id="Ellipse_366" data-name="Ellipse 366" cx="3" cy="3" r="3" transform="translate(6.661 4)" fill="none"
                stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6"/>
      </g>
    </svg>
  )
}

export const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15.121" viewBox="0 0 15 15.121">
      <g id="edit" transform="translate(0.5 0.5)">
        <path id="Path_1997" data-name="Path 1997"
              d="M8.5,4H3.444A1.444,1.444,0,0,0,2,5.444V15.556A1.444,1.444,0,0,0,3.444,17H13.556A1.444,1.444,0,0,0,15,15.556V10.5"
              transform="translate(-2 -2.879)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.6"/>
        <path id="Path_1998" data-name="Path 1998"
              d="M15.436,2.319A1.5,1.5,0,1,1,17.56,4.443l-6.727,6.727L8,11.879l.708-2.833Z"
              transform="translate(-4 -1.879)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.6"/>
      </g>
    </svg>
  )
}

export const AddIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2A7CED">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
    </svg>
  )
}

export const ConfigureIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
    </svg>
  )
}

export const ListIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round">
      <line x1="8" y1="6" x2="21" y2="6"></line>
      <line x1="8" y1="12" x2="21" y2="12"></line>
      <line x1="8" y1="18" x2="21" y2="18"></line>
      <line x1="3" y1="6" x2="3.01" y2="6"></line>
      <line x1="3" y1="12" x2="3.01" y2="12"></line>
      <line x1="3" y1="18" x2="3.01" y2="18"></line>
    </svg>
  )
}
