import {createStore} from "redux";
import reducer from "./reducers";

const store = createStore(
  reducer, {
    setting: {
      isChangePasswordVisible: false,
      isLoading: false,
      isLoadingCount: 0,
      confirmationDialog: {},
      timePicker: {},
      isLoaderCount: 0,
    },
  }
);

export default store;
