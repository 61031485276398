import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../sidenav/sidenav.service';
import {AccountService} from '../account/account.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ACCESS_TYPE, FORM_TYPE, KitModel, MetaData, PROCESS_TYPE, PROPERTY_TYPE, SECTIONS} from '../models';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';

declare function toggleClass(id, className): any;

declare function removeClass(id, className): any;

@Component({
  selector: 'app-kitmodelboard',
  templateUrl: './kitmodelboard.component.html',
  styleUrls: ['./kitmodelboard.component.css']
})
export class KitmodelboardComponent implements OnInit {

  isSubmitted: boolean;
  error;
  formType;
  propertyType = PROPERTY_TYPE;
  processTypes = PROCESS_TYPE;
  propertyCalMethod = ['SUM', 'MIN', 'MAX', 'AVE', 'PREDICT', 'DAILY_CUM', 'ANY'];
  sensorsOfKitModel: String[] = [];
  allPropertiesOfKitModel: String [];
  propertiesOfKitModel: String[] = [];
  kitModels: KitModel[] = [];
  typesOfKitModel = ['TRUCK', 'SERVER'];
  p = 1;
  showForm = false;
  selectedKitModel;
  metaData: MetaData;
  isDataFetched = false;
  isCloning = false;
  searchTerm = '';


  ACCESS_TYPE = ACCESS_TYPE;
  SECTION = SECTIONS;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private accountService: AccountService,
              private toaster: ToastrService, private sideNavService: SideNavService, private authService: AuthService) {
  }

  ngOnInit() {
    this.getKitModels();
    this.getMetaData();
  }

  public pageChange(event: number): void {
    this.p = event;
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  updateKitModel(kitModel) {
    this.isSubmitted = true;

    this.isSubmitted = false;
  }

  getKitModels() {
    this.spinner.show();
    this.accountService.getKitModels().subscribe(response => {
      this.kitModels = response.content;
      this.isDataFetched = true;
    }, error => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  getMetaData() {
    this.spinner.show();
    this.accountService.getMetaData().subscribe((data) => {
      this.metaData = data.content;
      console.log('metaData ###' + data);
    }, () => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  deleteKitModel() {
    this.spinner.show();
    this.accountService.deleteKitModel(this.selectedKitModel.id).subscribe(response => {
      this.toggleHideById('deleteModal');
      this.kitModels.splice(this.kitModels.indexOf(this.selectedKitModel), 1);
      this.spinner.hide();
      this.toaster.success('Kit Model Deleted Successfully', 'Success');
    }, error => {
      this.toggleHideById('deleteModal');
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  getSensors() {
    return Object.keys(this.propertyType).sort((a, b) => {
      return a < b ? -1 : 1;
    });
  }

  getPropertyCalMethod() {
    return this.propertyCalMethod;
  }

  getProcessTypes() {
    return Object.keys(this.processTypes).map(o => {
      return String(o);
    });
  }

  getWindowWidth() {
    return window.innerWidth;
  }

  editKitModel(kitModel) {
    this.isSubmitted = false;
    console.log(kitModel);
    this.formType = FORM_TYPE.EDIT;
    this.getKitModels();
    this.selectedKitModel = kitModel;
    this.showForm = true;
  }

  addKitModel() {
    this.formType = FORM_TYPE.ADD;
    this.showForm = true;
  }

  getSensorsNumbers() {
    const temp = [];
    this.sensorsOfKitModel.forEach(function (s, index) {
      temp.push({item_id: index, item_text: s + ' ' + (index + 1)});
    });
    return temp;
    // return Array.from({length: this.sensorsOfKitModel.length}, (v, k) => String(k));
  }

  // addSensorToKitModel(value: string) {
  //   this.sensorsOfKitModel.push(value);
  // }
  //
  // removeSensorFromKitModel(index: number) {
  //   this.sensorsOfKitModel.splice(index, 1);
  // }
  //
  // addPropertyToKitModel(index: any) {
  //   this.addOperationsFormGroup();
  //   this.propertiesOfKitModel.push(this.allPropertiesOfKitModel.splice(index, 1)[0]);
  // }
  //
  // removePropertyFromKitModel(index: number) {
  //   this.allPropertiesOfKitModel.push(this.propertiesOfKitModel.splice(index, 1)[0]);
  //   this.allPropertiesOfKitModel.sort((a, b) => {
  //     return a < b ? -1 : 1;
  //   });
  //   this.removeOperationsFormGroup(index);
  // }
  //
  // addOperationsFormGroup() {
  //   const operations = this.formKitModel.get('operations') as FormArray;
  //   operations.push(this.formBuilder.group({
  //     propertyNumber: [this.propertiesOfKitModel.length, Validators.required],
  //     sensorNumberList: [null, Validators.required],
  //     aggregation: [null, Validators.required],
  //     type: [null, Validators.required],
  //   }));
  // }
  //
  // removeOperationsFormGroup(index) {
  //   const operations = this.formKitModel.get('operations') as FormArray;
  //   operations.removeAt(index);
  // }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  getKitModelsFiltered() {
    return this.kitModels.filter(km => JSON.stringify(Object.values(km)).toLowerCase().includes(this.searchTerm.trim().toLowerCase()));
  }

  hideForm() {
    this.isCloning = false;
    this.showForm = false;
  }

  toggleHideById(id) {
    document.getElementById(id).classList.toggle('hide');
  }
}
