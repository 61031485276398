import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FORM_TYPE, KitModel, KitType, MetaData, PROCESS_TYPE, Sensor} from '../../models';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountService} from '../../account/account.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../authentication/auth.service';

@Component({
  selector: 'app-kit-model-form',
  templateUrl: './kit-model-form.component.html',
  styleUrls: ['./kit-model-form.component.css']
})
export class KitModelFormComponent implements OnInit {
  @Input() kitModel: KitModel;
  @Input() metaData: MetaData;
  @Input() formType;
  @Input() isCloning;
  @Output() getKitModelTrigger = new EventEmitter();
  @Output() hideFormTrigger = new EventEmitter();
  isSubmitted: boolean;
  error;
  dropdownSettings;
  formKitModel: FormGroup;
  typesOfKitModel: KitType[];
  processTypes = PROCESS_TYPE;
  sensorsOfKitModel: Sensor[];
  propertiesOfKitModel: string [];
  actuatorsOfKitModel = [];
  actionsOfKitModel = [];
  sensors = [];
  actuators;
  propertyCalMethod = ['SUM', 'MIN', 'MAX', 'AVE', 'PREDICT', 'DAILY_CUM', 'ANY'];

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private accountService: AccountService, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      closeDropDownOnSelection: false,
    };

    this.sensors = this.metaData.sensors.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });

    this.actuators = this.metaData.actuators.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    this.typesOfKitModel = this.metaData.kitTypes;

    this.formKitModel = this.formBuilder.group({
      // id: [null, Validators.required],
      name: [null, Validators.required],
      // noOfSensors: [null, [Validators.required, Validators.pattern('^[1-9]{1}[0-9]*$')]],
      // noOfProperties: [null, [Validators.required, Validators.pattern('^[1-9]{1}[0-9]*$')]],
      type: [null, Validators.required],
      operations: this.formBuilder.array([]),
      // xtraSensors: this.formBuilder.group({
      lbsEnabled: [null, Validators.required],
      gpsEnabled: [false],
      batteryEnabled: [false],
    });

    this.isSubmitted = false;
    if (this.formType === FORM_TYPE.ADD) {
      this.formKitModel.reset();
      this.formKitModel.patchValue({
        lbsEnabled: false,
        type: this.typesOfKitModel[0].code
      });
      this.sensorsOfKitModel = [];
      this.propertiesOfKitModel = [];
      this.actuatorsOfKitModel = [];
    } else {
      this.formKitModel.reset();
      this.sensorsOfKitModel = this.kitModel.sensors ? this.kitModel.sensors.map(val => {
        return this.sensors.filter(s => s.code === val, 1)[0];
      }) : [];
      this.propertiesOfKitModel = this.kitModel.properties ? this.kitModel.properties.map(val => {
        return this.sensors.filter(s => s.code === val, 1)[0].name;
      }) : [];
      this.actuatorsOfKitModel = this.kitModel.actuators ? this.kitModel.actuators.map(val => {
        return this.actuators.filter(a => a.code === val, 1)[0];
      }) : [];
      this.actionsOfKitModel = this.kitModel.actions ? this.kitModel.actions.map(val => {
        return this.actuators.filter(a => a.code === val, 1)[0];
      }) : [];
      this.kitModel.properties.forEach(p => {
        this.addOperationsFormGroup(p);
      });
      this.kitModel.operations = this.kitModel.operations.map((operation, index) => {
        operation['sensorNumberList'] = operation['sensorNumberList'].map(sensor => {
          return {
            item_id: sensor,
            item_text: (this.sensorsOfKitModel[sensor] ? this.sensorsOfKitModel[sensor].name : '') + ' ' + (sensor + 1)
          };
        });
        return operation;
      });
      this.formKitModel.patchValue(this.kitModel);
      console.log(this.formKitModel.value);
    }
  }

  updateKitModel(kitModel) {
    Object.keys(this.formKitModel.controls).forEach((key) => {
        if (typeof kitModel[key] === 'string') {
          kitModel[key] = kitModel[key].trim();
        }
      }
    );

    console.log(kitModel + '#######');
    this.isSubmitted = true;

    if (!this.formKitModel.valid) {
      console.log(this.formKitModel.errors);
      return;
    }

    if (!AuthService.hasNewUserAccess && !this.isSuperUser) {
      this.error = 'You have no permission';
      return;
    }

    const tempKitModel = JSON.parse(JSON.stringify(kitModel));

    tempKitModel.sensors = this.sensorsOfKitModel.map(sensor => sensor.code);
    tempKitModel.actions = this.actionsOfKitModel.map(action => action.code);
    tempKitModel.actuators = this.actuatorsOfKitModel.map(actuator => actuator.code);
    tempKitModel.properties = this.propertiesOfKitModel.map(property => {
      return this.sensors.filter(s => s.name === property, 1)[0].code;
    });
    tempKitModel.noOfSensors = this.sensorsOfKitModel.length;
    tempKitModel.noOfActuators = this.actuatorsOfKitModel.length;
    tempKitModel.noOfProperties = this.propertiesOfKitModel.length;
    tempKitModel.noOfActions = this.actionsOfKitModel.length;
    tempKitModel.operations = tempKitModel.operations.map(operation => {
      operation.sensorNumberList = operation.sensorNumberList.map(sensor => {
        return sensor.item_id;
      });
      return operation;
    });
    console.log(tempKitModel);

    this.spinner.show();
    this.error = null;
    if (this.formType === FORM_TYPE.ADD || this.isCloning) {
      this.accountService.createKitModel(tempKitModel).subscribe(response => {
        this.spinner.hide();
        this.toaster.success('Kit Model Created Successfully', 'Success');
        this.getKitModelTrigger.emit(true);
        this.hideForm();
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    } else {
      this.accountService.updateKitModel(this.kitModel.id, tempKitModel).subscribe(response => {
        this.spinner.hide();
        this.toaster.success('Kit Model Updated Successfully', 'Success');
        this.getKitModelTrigger.emit(true);
        this.hideForm();
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }
    this.isSubmitted = false;
  }

  addPropertyToKitModel(index: any) {
    this.addOperationsFormGroup(this.getProperties()[index].name);
    this.propertiesOfKitModel.push(this.getProperties()[index].name);
  }

  removePropertyFromKitModel(index: number) {
    this.propertiesOfKitModel.splice(index, 1);
    this.removeOperationsFormGroup(index);
  }

  addActionToKitModel(index) {
    this.actionsOfKitModel.push(this.actuatorsOfKitModel[index]);
  }

  removeActionFromKitModel(index) {
    this.actionsOfKitModel.splice(index, 1);
  }

  addSensorToKitModel(index) {
    this.sensorsOfKitModel.push(this.sensors[index]);
  }

  removeSensorFromKitModel(index) {
    const sensorName = this.sensorsOfKitModel[index].name;
    while (this.propertiesOfKitModel.includes(sensorName)) {
      this.removePropertyFromKitModel(this.propertiesOfKitModel.indexOf(sensorName));
    }
    this.sensorsOfKitModel.splice(index, 1);
  }

  addActuatorToKitModel(index) {
    this.actuatorsOfKitModel.push(this.actuators[index]);
  }

  removeActuatorFromKitModel(index: number) {
    this.actuatorsOfKitModel.splice(index, 1);
  }

  addOperationsFormGroup(property) {
    const operations = this.formKitModel.get('operations') as FormArray;
    const sensorX = this.getSensorsSelected()[this.propertiesOfKitModel.length];
    operations.push(this.formBuilder.group({
      propertyNumber: [this.getPropertyNumber(property), Validators.required],
      sensorNumberList: [sensorX, Validators.required],
      aggregation: [this.getPropertyCalMethod()[0], Validators.required],
      type: [this.processTypes[this.getProcessTypes()[0]], Validators.required],
    }));
  }

  removeOperationsFormGroup(index) {
    const operations = this.formKitModel.get('operations') as FormArray;
    operations.removeAt(index);
  }

  getProperties() {
    const properties = [];
    this.sensorsOfKitModel.forEach((val) => {
        if (!properties.includes(val)) {
          if ((val.code === 'MEA' || val.code === 'MEA0' || val.code === 'MEA1' || val.code === 'MEA2' || val.code === 'MEA3' || val.code === 'MEA4')
            && !properties.includes(this.getSensorsByCode('M'))) {
            properties.push(this.getSensorsByCode('M'));
            properties.push(this.getSensorsByCode('CN'));
          } else if ((val.code === 'PHA') && !properties.includes(this.getSensorsByCode('PH'))) {
            properties.push(this.getSensorsByCode('PH'));
          } else {
            properties.push(val);
          }
        }
      }
    );
    return properties;
  }

  getActions() {
    const actions = [];
    this.actuatorsOfKitModel.forEach((val) => {
        if (!actions.includes(val)) {
          actions.push(val);
        }
      }
    );
    return actions;
  }

  getSensorsSelected() {
    const temp = [];
    this.sensorsOfKitModel.forEach(function (s, index) {
      temp.push({item_id: index, item_text: s.name + ' ' + (index + 1)});
    });
    return temp;
    // return Array.from({length: this.sensorsOfKitModel.length}, (v, k) => String(k));
  }

  getPropertyCalMethod() {
    return this.propertyCalMethod;
  }

  getPropertyNumber(property) {
    console.log(property);
    console.log(property.includes('Battery Level'));
    return property.includes('Battery Level') ? -11 : (property.includes('Battery') ? -10 : this.propertiesOfKitModel.length);
  }

  getProcessTypes() {
    return Object.keys(this.processTypes).map(o => {
      return String(o);
    });
  }

  getWindowWidth() {
    return window.innerWidth;
  }

  hideForm() {
    this.hideFormTrigger.emit(true);
  }

  isSuperUser() {
    return AuthService.isSuperAdmin();
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  getSensorsByCode(code: string) {
    return this.sensors.filter(s => s.code === code, 1)[0];
  }

  isValidOperations() {
    const formValue = this.formKitModel.value;

    for (let i = 0; i < formValue.operations.length; i++) {
      if (!formValue.operations[i].sensorNumberList) {
        return false;
      }
    }
    return true;
  }
}
