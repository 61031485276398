import * as React from "react";
import {useEffect, useState} from "react";
import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  topic: {
    color: "#606060",
    borderBottomColor: "white",
    backgroundColor: "white",
    height: "20px",
  },
  topicText: {
    fontSize: "14px",
    marginTop: "20px",
  },
  content: {
    borderColor: "white",
  },
  contentText: {
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export function DataTable(props) {
  const classes = useStyles();

  const [tableObjArray, setTableObjArray] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [deviceData, setDeviceData] = useState([]);

  const filterTable = (testCase, i) => {
    setSelected(i);
    let dataArray = [];
    setDeviceData([]);
    props.testSummaryData.deviceTestResult.map((device) => {
      const deviceId = Object.keys(device)[0];
      const dataObj = device[deviceId][testCase];
      if (dataObj !== undefined) {
        dataObj["0"] = deviceId;
      }
      dataArray.push(dataObj);
      return dataArray;
    });

    if (dataArray[0] !== undefined) {
      var tableHeaders = Object.keys(dataArray[0]).sort();
      tableHeaders.push("StartTime");
      tableHeaders.push("EndTime");
      setTableHeadData(tableHeaders);
      setTableObjArray(dataArray);
      dataArray.map((device) => {
        const tempResult = [];
        tableHeaders.forEach((subhead) => {
          var deviceTemp = null;
          if (subhead !== "0") {
            deviceTemp = device[subhead];
            if (deviceTemp !== null && Array.isArray(deviceTemp)) {
              tempResult.push(...deviceTemp);
            }
          }
        });
        setDeviceData((prevDeviceData) => [...prevDeviceData, ...tempResult]);
      });
    }
  };

  useEffect(() => {
    let dataArray = [];
    setDeviceData([]);
    props.testSummaryData.deviceTestResult.map((device) => {
      const deviceId = Object.keys(device)[0];
      const dataObj = device[deviceId][props.allMainTestCases[0]];
      if (dataObj !== undefined) {
        dataObj["0"] = deviceId;
      }
      dataArray.push(dataObj);
      return dataArray;
    });
    if (dataArray[0] !== undefined) {
      var tableHeaders = Object.keys(dataArray[0]).sort();
      tableHeaders.push("StartTime");
      tableHeaders.push("EndTime");

      dataArray.map((device) => {
        const tempResult = [];
        tableHeaders.forEach((subhead) => {
          var deviceTemp = null;
          if (subhead !== "0") {
            deviceTemp = device[subhead];
            if (deviceTemp !== null && Array.isArray(deviceTemp)) {
              tempResult.push(...deviceTemp);
            }
          }
        });
        setDeviceData((prevDeviceData) => [...prevDeviceData, ...tempResult]);
      });
      setTableHeadData(tableHeaders);
      setTableObjArray(dataArray);
    }
    props.loadingClose();
  }, [props.testSummaryData]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12} style={{textAlign: "left"}}>
          {props.allMainTestCases.map((button, index) => {
            return (
              <Chip
                key={index}
                label={button}
                style={{
                  borderRadius: "4px",
                  margin: "10px",
                  backgroundColor:
                    selected === index
                      ? "rgba(9, 30, 66, 0.2)"
                      : "rgba(9, 30, 66, 0.04)",
                  color: "#606060",
                }}
                onClick={() => filterTable(button, index)}
              />
            );
          })}
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {tableHeadData.map((topic, i) => {
                return (
                  <TableCell align="left" className={classes.topic} key={i}>
                    <Typography className={classes.topicText}>
                      {topic == "0" ? "Device Id" : topic}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceData.map((rowData, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor:
                    index % 2 === 0 || index / 2 === 0 ? "#ECEDEF" : "white",
                }}
              >
                {tableHeadData.map((topic, i) => {
                  if (topic === "0") {
                    return (
                      <TableCell
                        align="left"
                        className={classes.content}
                        key={i}
                      >
                        <Typography className={classes.contentText}>
                          {rowData.deviceId}
                        </Typography>
                      </TableCell>
                    );
                  }

                  if (topic === rowData.subTestTitle) {
                    return (
                      <TableCell
                        align="left"
                        className={classes.content}
                        key={i}
                        style={{
                          color:
                            rowData.testResult === "SUCCESS"
                              ? "#22C674"
                              : rowData.testResult === "FAILURE"
                                ? "#FA5246"
                                : "black",
                        }}
                      >
                        <Typography className={classes.contentText}>
                          {rowData.testResult}
                        </Typography>
                      </TableCell>
                    );
                  }

                  if (topic === "StartTime") {
                    return (
                      <TableCell
                        align="left"
                        className={classes.content}
                        key={i}
                      >
                        <Typography className={classes.contentText}>
                          {rowData.subTestCaseStartTime}
                        </Typography>
                      </TableCell>
                    );
                  }
                  if (topic === "EndTime") {
                    return (
                      <TableCell
                        align="left"
                        className={classes.content}
                        key={i}
                      >
                        <Typography className={classes.contentText}>
                          {rowData.subTestCaseEndTime}
                        </Typography>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell align="left" className={classes.content} key={i}>
                      <Typography className={classes.contentText}>
                        NOT_APPLICABLE
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
